import styled from "styled-components";
import FileUpload from "components/UI/FileUpload";
import { axiosAsocijator } from "common/api/axios/asocijator_axios";
import { useEffect, useState } from "react";
import CharacterFileEditItem from "./CharacterFileEditItem";
import SearchResultLiteList from "components/common/SearchResultLiteList";
import {
  MESSAGE_FILE_FIELD_NAME,
  MESSAGE_UP_URL,
} from "./CharacterFileEdit.const";
import {
  removeCharacterFileListGet,
  uploadCharacterFileListGet,
} from "./CharacterFileEdit.logic";
import { ReactRefI } from "type/common/ref.type";
import { CharacterFile } from "common/character/dto/CharacterFile";

type CharacterFileEditProps = {
  fileEditRef: ReactRefI<any[]>;
  fileRemoveRef: ReactRefI<any[]>;
  onCharacterFileUpdate?: Function;
};

const CharacterFileEdit = ({
  fileEditRef,
  fileRemoveRef,
  onCharacterFileUpdate,
}: CharacterFileEditProps) => {
  const [fileList, setFileList] = useState<CharacterFile[]>(
    fileEditRef.current
  );
  const [fileRemoveList, setFileRemoveList] = useState<CharacterFile[]>([]);
  useEffect(() => {
    fileEditRef.current = fileList;
  }, [fileList]);
  useEffect(() => {
    fileRemoveRef.current = fileRemoveList;
  }, [fileRemoveList]);
  useEffect(() => {
    // return () => {
    //   console.log("test 1");
    // };
    if (onCharacterFileUpdate) {
      onCharacterFileUpdate();
    }
  }, [fileEditRef.current, fileRemoveRef.current, fileList, fileRemoveList]);

  const fileUploadHandle = (ev: any) => {
    const fileListNext: CharacterFile[] = uploadCharacterFileListGet(
      ev,
      fileList
    );
    setFileList(fileListNext);
  };

  const fileNameDefault: string = "";

  const deleteHandle = (ind: number) => {
    const fileListNext: CharacterFile[] = fileList.filter(
      (characterFile: CharacterFile, i: number) => i !== ind
    );
    const fileRemoveListNext: CharacterFile[] = removeCharacterFileListGet(
      fileRemoveList,
      fileList[ind]
    );
    setFileRemoveList(fileRemoveListNext);
    setFileList(fileListNext);
  };

  return (
    <>
      {fileList.length > 0 && (
        <DivList>
          <SearchResultLiteList<CharacterFile>
            renderSingle={(characterFile: CharacterFile, ind: number) => (
              <DivCharacterFileItem key={`character_file_${ind}`}>
                <CharacterFileEditItem
                  characterFile={characterFile}
                  deleteHandle={deleteHandle}
                  ind={ind}
                />
              </DivCharacterFileItem>
            )}
            results={fileList}
          />
        </DivList>
      )}
      <div>
        <FileUpload
          axiosInstance={axiosAsocijator}
          fileFieldName={MESSAGE_FILE_FIELD_NAME}
          fileUploadHandle={fileUploadHandle}
          uploadUrl={MESSAGE_UP_URL}
          fileNameDefault={fileNameDefault}
          multiple={true}
          upLabel="file"
        />
      </div>
    </>
  );
};

const DivCharacterFileItem = styled.div`
  margin-top: 0.75rem;
`;

const DivList = styled.div`
  margin-top: 0rem;
  margin-bottom: 1rem;
`;

export default CharacterFileEdit;
