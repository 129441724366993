import { useForm } from "react-hook-form";
import {
  charCopyFormDefaultValuesGet,
  charCopySubmit,
} from "./common/CharCopy.logic";
import CharCopyForm from "components/common/character/copy/CharCopyForm";
import { useRef, useState } from "react";
import {
  CHARACTER_COPY_DESTINATION_TYPE,
  CHARACTER_COPY_TYPE,
} from "constant/character/character_copy.constant";
import useTopicWork from "common/topic/provider/TopicWork";
import { useCharacterCopy } from "hooks/asocijator/character/useCharacterCopy";
import { QUERY_KEYS } from "constant/react_query";
import CharCopyDestination from "./CharCopyDestination";
import { DivMarginTop } from "components/common/style/margin/DivMarginTop";
import { charCopyDestinationInitial } from "./CharCopyDestination/CharCopyDestination.logic";
import { CharCopySubmit } from "./common/CharCopy.type";
import { CopyDestinationT } from "./CharCopyDestination/CharCopyDestination.type";
import { CHARACTER_IMPORT_TYPE } from "constant/character/character_import.constant";

const CharCopy = () => {
  const { topicWork } = useTopicWork();
  const { characterCopyMutate } = useCharacterCopy({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_CHARACTER_SEARCH],
    successHandle: (topicId: any) => {
      console.log("copy success", topicId);
      setCopyDestination((currentVal: CopyDestinationT) => ({
        ...currentVal,
        show: true,
        topicId,
      }));
    },
  });
  const { handleSubmit, control } = useForm({
    defaultValues: charCopyFormDefaultValuesGet(),
  });
  const characterCopyTypeRef = useRef<string>(
    CHARACTER_COPY_TYPE.FIRST_NOT_CORRECT.value as string
  );
  const characterCopyDestinationTypeRef = useRef<string>(
    CHARACTER_COPY_DESTINATION_TYPE.TOPIC_NEW.value as string
  );
  const characterImportTypeRef = useRef<string>(
    CHARACTER_IMPORT_TYPE.INSERT.value as string
  );
  const topicDestinationRef = useRef<any>(null);
  const [copyDestination, setCopyDestination] = useState<CopyDestinationT>(
    charCopyDestinationInitial()
  );

  const onSubmit = async (data: any) => {
    console.log("CharCopy onSubmit data", data);
    const submitHandle: CharCopySubmit = {
      topicSourceId: topicWork?.id ?? "",
      copyType: characterCopyTypeRef.current,
      copyDestinationType: characterCopyDestinationTypeRef.current,
      importType: characterImportTypeRef.current,
      characterCopyMutate,
      setCopyDestination,
      topicDestination: topicDestinationRef.current,
      topicNewName: data.topicNewName,
    };
    charCopySubmit(submitHandle);
  };

  return (
    <>
      <div>Character Copy</div>
      <div>
        <CharCopyForm
          handleSubmit={handleSubmit}
          control={control}
          onSubmit={onSubmit}
          characterCopyTypeRef={characterCopyTypeRef}
          characterCopyDestinationTypeRef={characterCopyDestinationTypeRef}
          characterImportTypeRef={characterImportTypeRef}
          topicDestinationRef={topicDestinationRef}
        />
      </div>
      {copyDestination.show && (
        <DivMarginTop mt={1}>
          <CharCopyDestination
            topicId={copyDestination.topicId}
            topicName={copyDestination.topicName}
          />
        </DivMarginTop>
      )}
    </>
  );
};

export default CharCopy;
