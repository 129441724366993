import { TopicResult } from "common/topic/dto/TopicResult";
import { TOPIC_PER_PAGE_MAX } from "constant/topic/topic.const";

export const excludeIdsFilter = (
  topics: TopicResult[],
  excludeIds?: string[]
): TopicResult[] => {
  let result = [...topics];
  if (excludeIds && excludeIds.length > 0) {
    result = result.filter(
      (topic: TopicResult) => !excludeIds.includes(topic.id)
    );
  }

  return result;
};

export const searchSelectResults = (
  topics: TopicResult[],
  excludeIds?: string[]
): TopicResult[] => {
  let result = [...topics];
  if (excludeIds) {
    result = excludeIdsFilter(result, excludeIds);
  }

  return result;
};

export const showSearchSelect = (topicSelection: TopicResult): boolean => {
  let result: boolean = false;
  if (topicSelection.id !== "") {
    result = true;
  }

  return result;
};

export const searchSelectInitial = (topicId?: string): any => {
  const result: any = {
    limit: TOPIC_PER_PAGE_MAX,
    skip: 0,
  };
  if (typeof topicId !== "undefined") {
    result.topicId = topicId;
  }

  return result;
};
