import { CharacterResult } from "common/character/dto/CharacterResult";
import { CHARACTER_PER_PAGE_MAX } from "constant/character";

export const excludeIdsFilter = (
  characters: CharacterResult[],
  excludeIds?: string[]
): CharacterResult[] => {
  let result = [...characters];
  if (excludeIds && excludeIds.length > 0) {
    result = result.filter(
      (character: CharacterResult) => !excludeIds.includes(character.id)
    );
  }

  return result;
};

export const searchSelectResults = (
  characters: CharacterResult[],
  excludeIds?: string[]
): CharacterResult[] => {
  let result = [...characters];
  if (excludeIds) {
    result = excludeIdsFilter(result, excludeIds);
  }

  return result;
};

export const showSearchSelect = (
  characterSelection: CharacterResult
): boolean => {
  let result: boolean = false;
  if (characterSelection.id !== "") {
    result = true;
  }

  return result;
};

export const searchSelectInitial = (topicId?: string): any => {
  const result: any = {
    limit: CHARACTER_PER_PAGE_MAX,
    skip: 0,
  };
  if (typeof topicId !== "undefined") {
    result.topicId = topicId;
  }

  return result;
};
