import { useQuery } from "@tanstack/react-query";
import {
  TopicAnswerPlayCharacterParams,
  topicAnswerPlayCharacter,
} from "common/api/asocijator/topic/asocijator_topic_answer_play";
import { QUERY_KEYS } from "constant/react_query";

export const useTopicAnswerPlayCharacter = (
  playCharParams: TopicAnswerPlayCharacterParams
) => {
  const { data: character } = useQuery(
    [
      QUERY_KEYS.ASOCIJATOR_TOPIC_ANSWER_PLAY_CHARACTER,
      playCharParams.topicId,
      playCharParams.characterId,
    ],
    async () => {
      const result = await topicAnswerPlayCharacter(playCharParams);
      return result;
    },
    { staleTime: Infinity }
  );
  const result =
    typeof character !== "undefined" && character !== "" ? character : null;
  return result;
};
