import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { NavElement } from "type/navigation/main_navigation.type";

type NavItemSmallScreenProps = {
  navItem: NavElement;
  clickHandle?: Function;
};

const NavItemSmallScreen = ({
  navItem,
  clickHandle,
}: NavItemSmallScreenProps) => {
  const navClickHandle = () => {
    if (clickHandle) {
      clickHandle();
    }
  };

  return (
    <DivMenuItem>
      <NavLink to={navItem.path} onClick={navClickHandle}>
        {navItem.title}
      </NavLink>
    </DivMenuItem>
  );
};

const DivMenuItem = styled.div`
  font-size: 1.2rem;
  text-align: right;
  margin-top: 0.5rem;
`;

export default NavItemSmallScreen;
