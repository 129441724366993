import React from "react";
import styled from "styled-components";

export type StringListItemProps = {
  result: string;
  delimiter?: string;
  ind: number;
};

function StringListItem({ result, delimiter, ind }: StringListItemProps) {
  return (
    <>
      {`${delimiter && ind > 0 ? delimiter : ""}`}
      <Div>{result}</Div>
    </>
  );
}

const Div = styled.div`
  margin-top: 1rem;
`;

export default StringListItem;
