import { Button } from "@mui/material";
import React, { createContext, useCallback, useContext, useState } from "react";
import styled from "styled-components";

type DialogContextValue = {
  navOpen: Function;
  navClose: Function;
};

export type AddNavOptions = {
  title?: string;
  navContent?: React.ReactNode;
  navCloseHandle?: Function;
};

const AddNavigation = createContext<DialogContextValue>({
  navOpen: () => null,
  navClose: () => null,
});

type AddNavigationProviderProps = { children: React.ReactNode };

export function AddNavigationProvider({
  children,
}: AddNavigationProviderProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AddNavOptions>({
    title: "",
    navContent: null,
    navCloseHandle: () => null,
  });

  const activate = useCallback((optionsData: AddNavOptions) => {
    console.log("activate", optionsData);
    if (optionsData.navContent) {
      setOptions(optionsData);
    }
    openNavigation();
  }, []);

  const openNavigation = () => {
    setOpen(true);
  };

  const closeNavigation = () => {
    setOpen(false);
    if (options.navCloseHandle) {
      options.navCloseHandle();
    }
  };

  return (
    <AddNavigation.Provider
      value={{ navOpen: activate, navClose: closeNavigation }}
    >
      <DivChildren>{children}</DivChildren>
      {open && (
        <Div>
          {options.title && <div>{options.title}</div>}
          <Button onClick={closeNavigation}>Cancel</Button>
          {options.navContent}
        </Div>
      )}
    </AddNavigation.Provider>
  );
}

export default function useAddNavigation() {
  return useContext(AddNavigation);
}

const DivChildren = styled.div`
  position: relative;
  z-index: 1;
`;

const Div = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 5rem;
  z-index: 2;
  padding: 0.5rem;
  // background-color: #ccc;
  background-image: linear-gradient(to right, #ccc, #fff);
`;
