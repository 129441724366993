import { Divider } from "@mui/material";
import styled from "styled-components";

export const DivField = styled.div`
  margin-top: 1rem;
`;

export const AreaDivider = styled(Divider)`
  margin-top: 2rem;
`;
