import { NAV_ELEMENTS } from "constant/navigation";
import { NavElement } from "type/navigation/main_navigation.type";

export const GENERAL_NAV_ELEMENTS: NavElement[] = [
  NAV_ELEMENTS.USER_PROFILE,
  NAV_ELEMENTS.UPUTSTVO,
];

export const NEW_ITEM_NAV_ELEMENTS: NavElement[] = [
  NAV_ELEMENTS.TOPIC_NEW,
  NAV_ELEMENTS.USER_NEW,
];
export const LIST_MAIN_NAV_ELEMENTS: NavElement[] = [
  NAV_ELEMENTS.TOPIC_LIST,
  NAV_ELEMENTS.USER_LIST,
];

export const MAIN_NAV_DRAWER_WIDTH = 240;
