import SearchResultLiteList from "components/common/SearchResultLiteList";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactRefI } from "type/common/ref.type";
import UpdateString from "./UpdateString/UpdateString";
import UpdateStringField from "./UpdateString/UpdateStringField";
import {
  addToStringList,
  removeFromStringList,
  sameListsCheck,
  updateInStringList,
} from "./UpdateStringList.logic";

export type UpdateStringListProps = {
  initialValue: string[];
  resultRef: ReactRefI<string[]>;
  label?: string;
  appendToStart?: boolean;
  stringField?: Function;
  disableSingleEdit?: boolean;
  id?: string;
  onUpdate?: Function;
};

function UpdateStringList({
  initialValue,
  resultRef,
  label,
  appendToStart,
  stringField,
  disableSingleEdit,
  id,
  onUpdate,
}: UpdateStringListProps) {
  const [textList, setTextList] = useState<string[]>([...initialValue]);
  const [updateListCheck, setUpdateListCheck] = useState<boolean>(false);
  useEffect(() => {
    if (!updateListCheck && !sameListsCheck(textList, initialValue)) {
      setUpdateListCheck(true);
    }
  }, [textList]);
  useEffect(() => {
    console.log("useEffect");
    resultRef.current = textList;
    if (updateListCheck && onUpdate) {
      onUpdate();
    }
  }, [textList]);
  const stringFieldRef: ReactRefI<string> = useRef<string>("");

  const fieldNewConfirm = (fieldValue: string) => {
    console.log("fieldNewConfirm", fieldValue);
    setTextList((prevList: string[]) =>
      addToStringList(prevList, fieldValue, appendToStart)
    );
  };

  const removeTextHandle = (ind: number) => {
    if (ind >= 0 && ind < textList.length) {
      const newList = removeFromStringList(textList, ind);
      setTextList(newList);
    }
  };

  const updateTextHandle = (ind: number, text: string) => {
    if (ind >= 0 && ind < textList.length) {
      const newList = updateInStringList(textList, ind, text);
      setTextList(newList);
    }
  };

  return (
    <>
      {label && <div>{label}</div>}
      <DivNewText>
        {!stringField && (
          <UpdateStringField
            fieldRef={stringFieldRef}
            emptyAfterConfirm={true}
            confirmOnEnter
            fieldConfirmHandle={fieldNewConfirm}
            id={id ?? undefined}
          />
        )}
        {stringField && stringField(fieldNewConfirm)}
      </DivNewText>
      <DivList>
        <SearchResultLiteList
          results={textList}
          renderSingle={(item: string, ind: number) => (
            <UpdateString
              initialVal={item}
              ind={ind}
              removeTextHandle={removeTextHandle}
              updateTextHandle={!disableSingleEdit ? updateTextHandle : null}
            />
          )}
        />
      </DivList>
    </>
  );
}

const DivList = styled.div`
  margin-top: 1rem;
  &&& > div {
    margin-top: 1rem;
  }
`;

const DivNewText = styled.div`
  margin-top: 0.75rem;
`;

export default UpdateStringList;
