import { arrStrToListLinkSimple } from "common/util/array/array_common";
import { ListLinkSimple } from "type/common/array/ListLinkSimple.type";
import { CharCheckListLink } from "./PronounceInput.type";

export const CHAR_CHECK_KEYS: string[] = ["a", "e", "i", "o", "u"];

const CHAR_CHECK_KEY_GROUP: any = {
  a: ["a", "á", "ǎ", "à", "ā"],
  e: ["e", "é", "ě", "è", "ē"],
  i: ["i", "í", "ǐ", "ì", "ī"],
  o: ["o", "ó", "ǒ", "ò", "ō"],
  u: ["u", "ú", "ǔ", "ù", "ü"],
};

export const CHAR_CHECK_LIST_LINK: CharCheckListLink = charCheckListLinkGet();

function charCheckListLinkGet(): CharCheckListLink {
  const res: CharCheckListLink = {};
  Object.keys(CHAR_CHECK_KEY_GROUP).forEach((key: string) => {
    res[key] = arrStrToListLinkSimple(CHAR_CHECK_KEY_GROUP[key]);
  });

  return res;
}
