import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import styled from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";
import { DivMain } from "./style/layout_common.style";
import { useAuthShowContent } from "hooks/auth/useAuthShowContent";
import MainNavigationSmallScreen from "components/navigation/MainNavigation/MainNavigationSmallScreen";

export default function AnswerPlayView() {
  const { showContent, redirectPath } = useAuthShowContent();
  const navigate = useNavigate();
  useEffect(() => {
    if (redirectPath !== "") {
      navigate(redirectPath);
    }
  }, [redirectPath]);

  return (
    (showContent && (
      <DivMain overflowHidden>
        <MainNavigationSmallScreen showNav />
        <Toaster />
        <Div>
          <Outlet />
        </Div>
      </DivMain>
    )) ||
    null
  );
}

const Div = styled.div`
  display: flex;
  // position: absolute;
  margin-left: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  width: 100%;
  // width: calc(100% - 2rem);
  // height: 100vh;
  flex-direction: column;
  color: #764141;
  overflow: auto;
`;
