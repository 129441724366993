import { TopicResult } from "./dto/TopicResult";

export const topicFormDefaultValuesGet = (topic?: TopicResult) => {
  if (topic) {
    return {
      name: topic.name,
      description: topic.description,
    };
  } else {
    return {
      name: "",
      description: "",
    };
  }
};
