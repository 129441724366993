import React, { useEffect, useState } from "react";

const LogoutPage = () => {
  return (
    <>
      <div>Logout</div>
    </>
  );
};

export default LogoutPage;
