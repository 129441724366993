import TopicChooseNav from "components/common/topic/TopicChooseNav";
import { chooseNavTopicGet } from "./CharCopyDestination.logic";
import { CopyDestinationBase } from "./CharCopyDestination.type";
import { DivMarginLeft } from "components/common/style/margin/DivMarginLeft";
import styled from "styled-components";

type CopyDestinationProps = CopyDestinationBase;

const CharCopyDestination = ({ topicId, topicName }: CopyDestinationProps) => {
  return (
    <Div>
      Copy Destination: {topicName}
      <DivMarginLeft ml={1}>
        <TopicChooseNav
          topic={chooseNavTopicGet(topicId, topicName)}
          navigateTo="/character/list"
        />
      </DivMarginLeft>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  align-items: center;
`;

export default CharCopyDestination;
