import { PlayAnswer } from "./dto/PlayAnswer";

export const answerFormDefaultValuesGet = (playAnswer?: PlayAnswer) => {
  if (playAnswer) {
    return {
      pronounce: playAnswer.pronounce,
      meaning: playAnswer.meaning,
    };
  } else {
    return {
      pronounce: "",
      meaning: "",
    };
  }
};
