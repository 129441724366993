import { obDefaultValGet } from "common/common";
import { sortFieldsGet } from "common/search/search_common";
import { SEARCH_NAMES } from "constant/search/search";

export const characterSearchFieldsGet = (searchFieldOb: any = null) => {
  let result = null;
  if (searchFieldOb === null) {
    result = {
      [SEARCH_NAMES.SEARCH_TEXT]: "",
    };
  } else {
    result = {
      [SEARCH_NAMES.SEARCH_TEXT]: obDefaultValGet(
        searchFieldOb,
        SEARCH_NAMES.SEARCH_TEXT,
        ""
      ),
    };
  }

  const sortFields = sortFieldsGet(searchFieldOb);
  result = { ...result, ...sortFields };

  return result;
};
