import React, { useContext } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useUserAdd } from "hooks/asocijator/user/useUserAdd";
import { QUERY_KEYS } from "constant/react_query";
import UserForm from "components/common/user/UserForm/UserForm";
import { useNavigate } from "react-router-dom";
import { userFormDefaultValuesGet } from "common/user/user_form_common";

const defaultValues = userFormDefaultValuesGet();

const UserNew = () => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues,
  });
  const navigate = useNavigate();
  const { userAddMutate } = useUserAdd({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_USER_SEARCH],
    successHandle: (id: any) => {
      console.log("add success", id);
      navigate(`/user/${id}`);
    },
  });

  const onSubmit = async (data: any) => {
    const { un, email, pw, uType, firstName, lastName } = data;

    userAddMutate({
      un,
      email,
      pw,
      uType,
      firstName,
      lastName,
    });
    toast.success("New user added");
  };

  return (
    <>
      <div>User Add</div>
      <DivUser>
        <UserForm
          control={control}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          reset={reset}
          defaultValues={defaultValues}
        />
      </DivUser>
    </>
  );
};

const DivUser = styled.div`
  display: flex;
  flex-direction: column;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // column-gap: 2rem;
  // row-gap: 2rem;
  // margin-top: 2rem;
`;

export default UserNew;
