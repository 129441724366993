import { useEffect, useRef, useState } from "react";
import useAnswerPlayChar from "common/answer/provider/AnswerPlayChar/AnswerPlayChar";
import RelateCharactersShow from "./RelateCharactersShow";
import AnswerPlayStatistic from "./AnswerPlayStatistic";
import PlayCharacter from "./PlayCharacter";
import { AnswerPlayKeyDownAction } from "./TopicAnswerPlay.type";
import { ANSWER_PLAY_KEY_DOWN_ACTION } from "./TopicAnswerPlay.const";
import { keyDownAct, keyDownActionGet } from "./TopicAnswerPlay.logic";
import PlayCharNav from "./PlayCharNav";
import styled from "styled-components";

export type TopicAnswerPlayProps = {
  topicId: string;
};

const TopicAnswerPlay = ({ topicId }: TopicAnswerPlayProps) => {
  const { playChar, playCharId, nextChar, charAnswerYesAdd } =
    useAnswerPlayChar();
  const yesMoveCheckRef = useRef<boolean>(true);
  const [answerShow, setAnswerShow] = useState<boolean>(true);

  const [keyDownAction, setKeyDownAction] = useState<AnswerPlayKeyDownAction>(
    ANSWER_PLAY_KEY_DOWN_ACTION.NONE
  );
  useEffect(() => {
    document.addEventListener("keydown", (ev: any) => {
      const action: AnswerPlayKeyDownAction = keyDownActionGet(ev);
      if (action !== ANSWER_PLAY_KEY_DOWN_ACTION.NONE) {
        setKeyDownAction(action);
      }
    });
  }, []);

  useEffect(() => {
    keyDownAct(
      keyDownAction,
      setKeyDownAction,
      playChar,
      answerShow,
      setAnswerShow,
      nextChar,
      yesAnswerHandle
    );
  }, [playChar, keyDownAction]);

  const yesAnswerHandle = () => {
    if (playChar !== null) {
      charAnswerYesAdd();
    }
    if (yesMoveCheckRef.current) {
      nextChar();
    } else {
      if (!answerShow) {
        setAnswerShow(true);
      }
    }
  };

  return (
    <Div>
      <AnswerPlayStatistic topicId={topicId} />
      <PlayCharacter
        yesMoveCheckRef={yesMoveCheckRef}
        answerShow={answerShow}
        setAnswerShow={setAnswerShow}
      />

      {
        playCharId
        // && <RelateCharactersShow relCharAnswerShow={answerShow} />
      }
      <PlayCharNav />
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 96%;
  margin-left: 2%;
`;

export default TopicAnswerPlay;
