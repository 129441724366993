import styled from "styled-components";
import { CharacterFile } from "common/character/dto/CharacterFile";
import DownloadIcon from "@mui/icons-material/Download";
import { fileNameGet } from "common/util/file/file_common";
import { downloadOpenCharacterFile } from "common/character/file/character_file";
import { sizeInfoGet } from "common/character/character_common";
import { FILE_DOWNLOAD_OPEN_TYPES } from "constant/file/file_constants";
import CharacterFileClickActLink from "../../../CharacterFileClickActLink";
import { showCharacterFileName } from "./CharacterFileReadNav.logic";

type CharacterFileReadNavProps = {
  characterFile: CharacterFile;
  disableLinkDownload?: boolean;
};

const CharacterFileReadNav = ({
  characterFile,
  disableLinkDownload,
}: CharacterFileReadNavProps) => {
  const downloadClickHandle = async () => {
    const filePath: string = characterFile.path as string;
    await downloadOpenCharacterFile(
      filePath,
      FILE_DOWNLOAD_OPEN_TYPES.DOWNLOAD
    );
  };

  const sizeInfo: string = sizeInfoGet(characterFile.size ?? null);
  const fileName: string = fileNameGet(characterFile.path);

  return showCharacterFileName(characterFile) ? (
    <DivNav>
      {!disableLinkDownload && (
        <CharacterFileClickActLink characterFilePath={characterFile.path}>
          {fileName}
        </CharacterFileClickActLink>
      )}
      {disableLinkDownload && fileName}
      {sizeInfo && <SpanSize>{sizeInfo}</SpanSize>}
      {!disableLinkDownload && (
        <DownloadNav>
          <DownloadIconNav onClick={downloadClickHandle} />
        </DownloadNav>
      )}
    </DivNav>
  ) : null;
};

const DownloadIconNav = styled(DownloadIcon)`
  margin-right: 1rem;
  cursor: pointer;
`;

const SpanSize = styled.span`
  margin-left: 0.3rem;
  font-size: 0.9rem;
`;

const DivNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const DownloadNav = styled.span`
  margin-left: 1rem;
`;

export default CharacterFileReadNav;
