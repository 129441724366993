import styled from "styled-components";

type FallbackProps = {
  error: any;
  resetErrorBoundary: any;
};

export default function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div>
      <p>Greska:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Pokusaj ponovo</button>
    </div>
  );
}

const Div = styled.div`
  display: flex;
  margin-top: 4rem;
  flex-direction: column;
  color: #764141;
`;
