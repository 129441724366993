import { TopicStatisticType } from "type/common/topic/topic_statistic.type";
import { axiosAsocijator } from "../../axios/asocijator_axios";

export type TopicStatisticParams = {
  topicId: string;
  statisticType: TopicStatisticType;
};

export const topicStatistic = async (
  statisticParams: TopicStatisticParams
): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosAsocijator.post(
      `topic/statistic`,
      statisticParams ?? {}
    );
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
