import { useRef } from "react";
import { CharacterRelate } from "type/common/character/character.type";
import { ReactRefI } from "type/common/ref.type";

export type CharacterRelateRefs = {
  relateMeaningRef: ReactRefI<string[]>;
  relatePronounceRef: ReactRefI<string[]>;
  relateShapeRef: ReactRefI<string[]>;
};

export const useCharacterRelateRefs = (
  characterRelate?: CharacterRelate
): CharacterRelateRefs => {
  const relateMeaningRef: ReactRefI<string[]> = useRef<string[]>([]);
  const relatePronounceRef: ReactRefI<string[]> = useRef<string[]>([]);
  const relateShapeRef: ReactRefI<string[]> = useRef<string[]>([]);
  if (characterRelate) {
    relateMeaningRef.current = characterRelate.meaning as string[];
    relatePronounceRef.current = characterRelate.pronounce as string[];
    relateShapeRef.current = characterRelate.shape as string[];
  }

  return { relateMeaningRef, relatePronounceRef, relateShapeRef };
};
