import styled from "styled-components";
import { TopicResult } from "common/topic/dto/TopicResult";
import DeleteIcon from "@mui/icons-material/Close";

type TopicLiteItemProps = {
  topic: TopicResult;
  clickHandle?: Function;
  deleteClickHandle?: Function;
  selectedClass?: string;
};

const TopicLiteItem = ({
  topic,
  clickHandle,
  deleteClickHandle,
  selectedClass,
}: TopicLiteItemProps) => {
  return (
    <Div>
      {!clickHandle && <div>{topic.name}</div>}
      {clickHandle && (
        <DivTopic
          onClick={() => clickHandle(topic)}
          className={
            typeof selectedClass === "undefined" || selectedClass === ""
              ? ""
              : selectedClass
          }
        >
          {topic.name}
        </DivTopic>
      )}
      {deleteClickHandle && (
        <DivDelete>
          <DeleteIcon
            onClick={() => deleteClickHandle(topic.id)}
            fontSize="small"
          />
        </DivDelete>
      )}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-top: 1rem;
  line-height: 1.25rem;
`;

const DivTopic = styled.div`
  cursor: pointer;
`;

const DivDelete = styled.div`
  margin-left: 1rem;
  cursor: pointer;
`;

export default TopicLiteItem;
