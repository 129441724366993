import React, { createContext, useContext, useState } from "react";
import { LIST_MODES } from "./ListMode.const";

export type ListModeContextValue = {
  listMode: string;
  setListMode: Function;
  selIds: string[];
  setSelIds: Function;
  isSelected: Function;
  listModeIsRegular: Function;
  listModeIsSelect: Function;
};

const ListMode = createContext<ListModeContextValue>({
  listMode: LIST_MODES.REGULAR,
  setListMode: () => null,
  selIds: [],
  setSelIds: () => null,
  isSelected: () => null,
  listModeIsRegular: () => null,
  listModeIsSelect: () => null,
});

type ListModeProviderProps = { children: React.ReactNode };

export function ListModeProvider({ children }: ListModeProviderProps) {
  const [listMode, setListMode] = useState<string>(LIST_MODES.REGULAR);
  const [selIds, setSelIds] = useState<string[]>([]);

  const isSelected = (id: string): boolean => selIds.includes(id);

  const listModeIsRegular = (): boolean => {
    return listMode === LIST_MODES.REGULAR;
  };

  const listModeIsSelect = (): boolean => {
    return listMode === LIST_MODES.SELECT;
  };

  return (
    <ListMode.Provider
      value={{
        listMode,
        setListMode,
        selIds,
        setSelIds,
        isSelected,
        listModeIsRegular,
        listModeIsSelect,
      }}
    >
      {children}
    </ListMode.Provider>
  );
}

export default function useListMode() {
  return useContext(ListMode);
}
