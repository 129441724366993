import { Button } from "@mui/material";
import styled from "styled-components";
import { useCharacterSearch } from "hooks/asocijator/character/useCharacterSearch";
import * as xlsx from "xlsx";
import {
  excelDataGet,
  topicCharacterExportSearchParams,
} from "./CharExportNav.logic";
import {
  EXPORT_EXCEL_FILE_TYPE,
  EXPORT_EXCEL_FILE_NAME,
  CharExportFieldsTypes,
} from "./CharExportNav.const";

type CharExportNavProps = {
  topicId: string;
};

const CharExportNav = ({ topicId }: CharExportNavProps) => {
  const characterListResult = useCharacterSearch(
    topicCharacterExportSearchParams(topicId)
  );

  const exportHandle = (
    charExportFieldsType: CharExportFieldsTypes = CharExportFieldsTypes.all
  ) => {
    const data = excelDataGet(characterListResult, charExportFieldsType);

    const ws = xlsx.utils.aoa_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: EXPORT_EXCEL_FILE_TYPE,
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = EXPORT_EXCEL_FILE_NAME;

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        // eslint-disable-next-line no-restricted-globals
        removeEventListener("click", clickHandler);
      }, 150);
    };

    a.addEventListener("click", clickHandler, false);

    a.click();
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={() => exportHandle(CharExportFieldsTypes.all)}
      >
        Export
      </Button>
      <ButtonExamplesExport
        variant="outlined"
        onClick={() => exportHandle(CharExportFieldsTypes.examples)}
      >
        Examples Export
      </ButtonExamplesExport>
    </div>
  );
};

const ButtonExamplesExport = styled(Button)`
  &&& {
    margin-left: 1rem;
  }
`;

export default CharExportNav;
