import { TopicResult } from "common/topic/dto/TopicResult";
import SearchResultList, {
  PageNavigation,
} from "components/common/SearchResultList";
import TopicItem from "./TopicItem";
import { useEffect, useState } from "react";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { TopicSearchParams } from "common/api/asocijator/topic/asocijator_topic";
import { TOPIC_PER_PAGE_MAX } from "constant/topic/topic.const";
import { useTopicSearch } from "hooks/asocijator/topic/useTopicSearch";
import { topicSearchFieldsGet } from "common/topic/topic_search";
import TopicSearchFilters from "components/common/topic/TopicSearchFilters";

const TopicList = () => {
  const [searchParams, setSearchParams] = useState<TopicSearchParams>({
    limit: TOPIC_PER_PAGE_MAX,
    skip: 0,
  });
  const topicListResult = useTopicSearch(searchParams);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([
      QUERY_KEYS.ASOCIJATOR_TOPIC_SEARCH,
    ]);
  }, [searchParams]);

  let results: TopicResult[] = topicListResult ? topicListResult["items"] : [];
  const resTotal: number = topicListResult ? topicListResult["resTotal"] : 0;
  const pageNavigationHandle = (pageNum: number) => {
    const skip = pageNum * TOPIC_PER_PAGE_MAX;
    setSearchParams({ ...searchParams, skip });
  };
  const pageNavigation: PageNavigation = {
    resTotal,
    limit: TOPIC_PER_PAGE_MAX,
    skip: searchParams.skip ?? 0,
    pageNavigationHandle,
  };
  const searchHandle = (formData: any) => {
    const searchVals = topicSearchFieldsGet(formData);

    setSearchParams({
      ...searchParams,
      ...searchVals,
      skip: 0,
    });
  };

  return (
    <div>
      <TopicSearchFilters searchHandle={searchHandle} />
      <SearchResultList<TopicResult>
        renderSingle={(topic: TopicResult) => (
          <TopicItem topic={topic} key={`topic_${topic.id}`} />
        )}
        results={results}
        pageNavigation={pageNavigation}
      />
    </div>
  );
};

export default TopicList;
