import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import InputField from "components/UI/InputField";

type UserPasswordFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  reset: Function;
};

const UserPasswordForm = ({
  onSubmit,
  handleSubmit,
  reset,
  control,
}: UserPasswordFormProps) => {
  return (
    <div>
      <form>
        <DivField>
          <InputField
            name="pw"
            control={control}
            label="password"
            defaultValue=""
            type="password"
          />
        </DivField>
        <DivField>
          <InputField
            name="pwRe"
            control={control}
            label="retype password"
            defaultValue=""
            type="password"
          />
        </DivField>

        <DivNav>
          <Button onClick={handleSubmit(onSubmit)} type="submit">
            Submit
          </Button>
          <Button onClick={() => reset()} variant={"outlined"}>
            Reset
          </Button>
        </DivNav>
      </form>
    </div>
  );
};

const DivField = styled.div`
  margin-top: 1rem;
`;

const DivNav = styled.div`
  margin-top: 2rem;
`;

export default UserPasswordForm;
