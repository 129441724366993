import { intervalCheck } from "common/common";

export async function elIdsCheck(
  ids: string[],
  waitMsMax: number = 2 * 1000
): Promise<boolean> {
  const result: boolean = await intervalCheck(
    () => elIdsCurrentCheck(ids),
    waitMsMax
  );

  return result;
}

function elIdsCurrentCheck(ids: string[]): boolean {
  let result: boolean = true;
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const elRes = document.getElementById(id);
    if (elRes === null) {
      result = false;
      break;
    }
  }

  return result;
}
