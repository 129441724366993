import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { CharacterResult } from "common/character/dto/CharacterResult";
import { NavLink } from "react-router-dom";
import { useCharacterDelete } from "hooks/asocijator/character/useCharacterDelete";
import { QUERY_KEYS } from "constant/react_query";
import { characterLinkSingle } from "common/character/character_link_common";
import useConfirm from "common/modal/ConfirmDialog";
import { Button } from "@mui/material";
import {
  CHARACTER_DELETE_CONFIRM_TEXT,
  CHARACTER_DELETE_CONFIRM_TITLE,
} from "./CharacterSingleNav.const";

type CharacterSingleNavProps = {
  character: CharacterResult;
  hideEditNav?: boolean;
  hideReadNav?: boolean;
};

const CharacterSingleNav = ({
  character,
  hideEditNav,
  hideReadNav,
}: CharacterSingleNavProps) => {
  const { characterDeleteMutate } = useCharacterDelete({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_CHARACTER_SEARCH],
  });

  const actionConfirm = useConfirm();

  const deleteHandle = async () => {
    const check = await actionConfirm({
      title: CHARACTER_DELETE_CONFIRM_TITLE,
      message: CHARACTER_DELETE_CONFIRM_TEXT,
    });
    if (check) {
      characterDeleteMutate(character.id);
    }
  };

  return (
    <DivNav>
      <DeleteIcon onClick={deleteHandle} />
      {!hideEditNav && (
        <NavLink to={characterLinkSingle(character.id, true)}>
          <EditIcon />
        </NavLink>
      )}
      {!hideReadNav && (
        <NavLink to={characterLinkSingle(character.id)}>
          <ReadMoreIcon />
        </NavLink>
      )}
    </DivNav>
  );
};

const DivNav = styled.div`
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  svg {
    margin-left: 1rem;
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
  &&& > svg:first-of-type {
    margin-left: 0;
  }
`;

export default CharacterSingleNav;
