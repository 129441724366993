import { useQuery } from "@tanstack/react-query";
import { topicGet } from "common/api/asocijator/topic/asocijator_topic";
import { QUERY_KEYS } from "constant/react_query";

export const useTopicGet = (id: string) => {
  const { data: topic } = useQuery(
    [QUERY_KEYS.ASOCIJATOR_TOPIC_GET, id],
    async () => {
      const result = await topicGet(id);
      return result;
    },
    { staleTime: Infinity }
  );
  const result = typeof topic !== "undefined" && topic !== "" ? topic : null;
  return result;
};
