import { Button } from "@mui/material";
import { answerFormDefaultValuesGet } from "common/answer/answer_form_common";
import useAnswerPlayChar from "common/answer/provider/AnswerPlayChar";
import CheckboxField from "components/UI/CheckboxField";
import CharAnswerForm from "components/common/answer/CharAnswerForm";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import styled from "styled-components";
import { ReactRefI } from "type/common/ref.type";
import {
  answerCheck,
  answerFormInitialShowNavText,
} from "./AnswerPlayNav.logic";

export type AnswerPlayNavProps = {
  yesMoveCheckRef: ReactRefI<boolean>;
};

const AnswerPlayNav = ({ yesMoveCheckRef }: AnswerPlayNavProps) => {
  const { nextChar, nextAnswerNoAdd, charAnswerYesAdd, playChar } =
    useAnswerPlayChar();
  const [answerFormShow, setAnswerFormShow] = useState<boolean>(true);
  const [answerFormInitialShow, setAnswerFormInitialShow] =
    useState<boolean>(false);

  useEffect(() => {
    setAnswerFormShow(false);
  }, [playChar]);

  useEffect(() => {
    if (!answerFormShow) {
      setAnswerFormShow(true);
    }
  }, [answerFormShow]);

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: answerFormDefaultValuesGet(),
  });

  const onSubmit = async (data: any) => {
    const { pronounce, meaning } = data;

    answerCheckHandle(pronounce, meaning);
  };

  const answerCheckHandle = (pronounce: string, meaning: string) => {
    const ch: boolean = answerCheck(playChar, pronounce, meaning);
    if (ch) {
      toast.success("Correct");
      nextChar();
    } else {
      toast.error("Incorrect");
    }
  };

  const yesClickHandle = () => {
    charAnswerYesAdd();
    if (yesMoveCheckRef.current) {
      nextChar();
    }
  };

  const answerFormInitialClickHandle = () => {
    setAnswerFormInitialShow(!answerFormInitialShow);
  };

  return (
    <>
      <DivAnswerFormInitialNav onClick={answerFormInitialClickHandle}>
        {answerFormInitialShowNavText(answerFormInitialShow)}
      </DivAnswerFormInitialNav>
      {answerFormInitialShow && answerFormShow && (
        <CharAnswerForm
          control={control}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          setValue={setValue}
          defaultValues={answerFormDefaultValuesGet()}
        />
      )}
      <DivYes>
        <Button onClick={yesClickHandle} variant="outlined">
          YES
        </Button>
      </DivYes>
      <DivNext>
        <Button onClick={() => nextAnswerNoAdd()}>NEXT</Button>
      </DivNext>
      <CheckboxField label="Next on yes" valueRef={yesMoveCheckRef} />
    </>
  );
};

const DivAnswerFormInitialNav = styled.div`
  margin-bottom: 0.5rem;
  cursor: pointer;
  width: 100%;
  text-align: left;
`;

const DivYes = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

const DivNext = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
`;

export default AnswerPlayNav;
