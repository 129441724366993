import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import MainNavigation from "components/navigation/MainNavigation";
import styled from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import { DivMain } from "./style/layout_common.style";
import { useAuthShowContent } from "hooks/auth/useAuthShowContent";

export default function MainView() {
  const { showContent, redirectPath } = useAuthShowContent();
  const navigate = useNavigate();
  useEffect(() => {
    if (redirectPath !== "") {
      navigate(redirectPath);
    }
  }, [redirectPath]);

  return (
    (showContent && (
      <DivMain>
        <MainNavigation />
        <Toaster />
        <Div>
          <Outlet />
        </Div>
      </DivMain>
    )) ||
    null
  );
}

const Div = styled.div`
  display: flex;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: calc(100% - 4rem);
  flex-direction: column;
  color: #764141;
  overflow: auto;

  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    margin-left: calc(240px + 2%);
    margin-top: 4rem;
    width: calc(100% - 240px - 4%);
  }
`;
