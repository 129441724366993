import { Button } from "@mui/material";
import { CharacterImportSetsResult } from "common/character/dto/character_import.type";
import { useCharacterImport } from "hooks/asocijator/character/useCharacterImport";
import { QUERY_KEYS } from "constant/react_query";
import toast from "react-hot-toast";
import { CharacterImportType } from "type/common/character/character_import.type";

type CharImportNavProps = {
  topicId: string;
  importType: CharacterImportType;
  importResultHandle: Function;
  charImportSetsResult: CharacterImportSetsResult;
};

const CharImportNav = ({
  topicId,
  importType,
  importResultHandle,
  charImportSetsResult,
}: CharImportNavProps) => {
  const { characterImportMutate } = useCharacterImport({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_CHARACTER_SEARCH],
    successHandle: (importResult: any) => {
      toast.success("Characters imported");
      importResultHandle(importResult);
    },
  });

  const importOkDisable: boolean = !charImportSetsResult.correct;

  const importClickHandle = () => {
    if (topicId) {
      characterImportMutate({
        topicId,
        importType,
        characters: charImportSetsResult.forImport,
      });
    }
  };

  return (
    <Button
      variant="outlined"
      onClick={importClickHandle}
      disabled={importOkDisable}
    >
      OK
    </Button>
  );
};

export default CharImportNav;
