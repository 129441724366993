import { axiosAsocijator } from "common/api/axios/asocijator_axios";
import FileUpload from "components/UI/FileUpload";
import {
  CHARACTER_IMPORT_FILE_FIELD_NAME,
  CHARACTER_IMPORT_UP_URL,
} from "./CharImportFileUpload.const";

export type CharImportFileUploadProps = {
  fileUploadHandle: Function;
  topicId: string;
  importType: string;
};

const CharImportFileUpload = ({
  fileUploadHandle,
  topicId,
  importType,
}: CharImportFileUploadProps) => {
  return (
    <FileUpload
      axiosInstance={axiosAsocijator}
      fileFieldName={CHARACTER_IMPORT_FILE_FIELD_NAME}
      fileUploadHandle={fileUploadHandle}
      uploadUrl={CHARACTER_IMPORT_UP_URL}
      multiple={false}
      addFormData={{
        topicId,
        importType,
      }}
    />
  );
};

export default CharImportFileUpload;
