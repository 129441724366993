import axios, { CreateAxiosDefaults } from "axios";

export type AUTHORIZATION_DATA = {
  token: string;
  refresh: string;
};

export function axiosInstanceCreate(
  baseURL: string,
  options?: CreateAxiosDefaults<any>
) {
  const axiosInstance = axios.create({ baseURL, ...options });
  return axiosInstance;
}

export function authorizationObDefault(): AUTHORIZATION_DATA {
  return {
    token: "",
    refresh: "",
  };
}
