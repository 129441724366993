import { SelectFieldOption } from "components/UI/SelectField/SelectField.type";

export const CHARACTER_COPY_TYPE: { [name: string]: SelectFieldOption } = {
  ALL: { value: "all", label: "all" },
  NOT_CORRECT: { value: "not_correct", label: "not correct" },
  FIRST_NOT_CORRECT: { value: "first_not_correct", label: "first not correct" },
};

export const CHARACTER_COPY_TYPES = Object.values(CHARACTER_COPY_TYPE).map(
  (option: SelectFieldOption) => option.value
);

export const CHARACTER_COPY_TYPE_OPTIONS: SelectFieldOption[] = [
  CHARACTER_COPY_TYPE.ALL,
  CHARACTER_COPY_TYPE.NOT_CORRECT,
  CHARACTER_COPY_TYPE.FIRST_NOT_CORRECT,
];

export const CHARACTER_COPY_DESTINATION_TYPE: {
  [name: string]: SelectFieldOption;
} = {
  TOPIC_NEW: { value: "topic_new", label: "new topic" },
  TOPIC_UPDATE: { value: "topic_update", label: "update topic" },
};

export const CHARACTER_COPY_DESTINATION_TYPES = Object.values(
  CHARACTER_COPY_DESTINATION_TYPE
).map((option: SelectFieldOption) => option.value);

export const CHARACTER_COPY_DESTINATION_TYPE_OPTIONS: SelectFieldOption[] = [
  CHARACTER_COPY_DESTINATION_TYPE.TOPIC_NEW,
  CHARACTER_COPY_DESTINATION_TYPE.TOPIC_UPDATE,
];
