import styled from "styled-components";
import Button from "@mui/material/Button";
import { defaultValueGet } from "common/parameters_common";
import InputField from "components/UI/InputField";
import { ReactRefI } from "type/common/ref.type";
import { CharacterRelateRefs } from "hooks/asocijator/character/useCharacterRelateRefs";
import { AreaDivider, DivField } from "./CharacterForm.style";
import CharacterFormAdd from "./CharacterFormAdd";

type CharacterFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  reset: Function;
  defaultValues?: any;
  pronounceAddRef: ReactRefI<string[]>;
  examplesRef: ReactRefI<string[]>;
  fileEditRef: ReactRefI<string[]>;
  fileRemoveRef: ReactRefI<string[]>;
  characterRelateRefs: CharacterRelateRefs;
};

const CharacterForm = ({
  onSubmit,
  handleSubmit,
  reset,
  defaultValues,
  control,
  pronounceAddRef,
  examplesRef,
  fileEditRef,
  fileRemoveRef,
  characterRelateRefs,
}: CharacterFormProps) => {
  const letterDefault = defaultValueGet(defaultValues, "letter", "");
  const meaningDefault = defaultValueGet(defaultValues, "meaning", "");
  const pronounceDefault = defaultValueGet(defaultValues, "pronounce", "");

  const examplesUpdateHandle = () => {
    console.log("examplesUpdateHandle");
    handleSubmit(onSubmit)();
  };

  const onCharacterFileUpdate = () => {
    if (letterDefault !== "") {
      console.log("onCharacterFileUpdate");
      handleSubmit(onSubmit)();
    }
  };

  return (
    <DivForm>
      <form>
        <DivField>
          <InputField
            name="letter"
            control={control}
            label="letter"
            defaultValue={letterDefault}
          />
        </DivField>
        <DivField>
          <InputField
            name="pronounce"
            control={control}
            label="pronounce"
            defaultValue={pronounceDefault}
          />
        </DivField>
        <DivField>
          <InputField
            name="meaning"
            control={control}
            label="meaning"
            defaultValue={meaningDefault}
          />
        </DivField>

        {/* <AreaDivider /> */}

        <CharacterFormAdd
          defaultValues={defaultValues}
          examplesRef={examplesRef}
          fileEditRef={fileEditRef}
          fileRemoveRef={fileRemoveRef}
          pronounceAddRef={pronounceAddRef}
          characterRelateRefs={characterRelateRefs}
          onExamplesUpdate={examplesUpdateHandle}
          onCharacterFileUpdate={onCharacterFileUpdate}
        />

        <AreaDivider />

        <DivNav>
          <Button onClick={handleSubmit(onSubmit)} type="submit">
            Submit
          </Button>
          <Button onClick={() => handleSubmit(onSubmit)()} variant={"outlined"}>
            Reset
          </Button>
        </DivNav>
      </form>
    </DivForm>
  );
};

const DivForm = styled.div`
  margin-top: 0.75rem;
`;

const DivNav = styled.div`
  margin-top: 2rem;
`;

export default CharacterForm;
