import InputField from "components/UI/InputField";
import { Control, FieldValues } from "react-hook-form";

type SearchTextProps = {
  control: Control<FieldValues, any>;
  fieldName: string;
  defaultValue: string;
  label?: string;
};

const SearchText = ({
  control,
  fieldName,
  defaultValue,
  label,
}: SearchTextProps) => {
  return (
    <InputField
      name={fieldName}
      control={control}
      label={label ?? ""}
      defaultValue={defaultValue}
    />
  );
};

export default SearchText;
