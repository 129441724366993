import { TopicAnswerPlayCharacterParams } from "common/api/asocijator/topic/asocijator_topic_answer_play";
import { AnswerPlayKeyDownAction } from "./TopicAnswerPlay.type";
import { ANSWER_PLAY_KEY_DOWN_ACTION } from "./TopicAnswerPlay.const";

export function keyDownActionGet(ev: any): AnswerPlayKeyDownAction {
  let res: AnswerPlayKeyDownAction = ANSWER_PLAY_KEY_DOWN_ACTION.NONE;
  const charValue: string = ev.key;

  if (ev.ctrlKey) {
    if (charValue === "ArrowRight") {
      res = ANSWER_PLAY_KEY_DOWN_ACTION.NEXT;
    } else if (charValue === "ArrowDown") {
      res = ANSWER_PLAY_KEY_DOWN_ACTION.ANSWER_SHOW;
    } else if (charValue === "ArrowUp") {
      res = ANSWER_PLAY_KEY_DOWN_ACTION.ANSWER_HIDE;
    } else if (charValue === "y") {
      res = ANSWER_PLAY_KEY_DOWN_ACTION.ANSWER_YES;
    }
  }
  return res;
}

export function nextPlayCharParamsGet(
  playChar: any,
  playCharParams: TopicAnswerPlayCharacterParams,
  topicId: string
): TopicAnswerPlayCharacterParams {
  const nextPlayCharParams: TopicAnswerPlayCharacterParams = playChar
    ? {
        ...playCharParams,
        characterId: playChar.id,
      }
    : {
        topicId,
      };

  return nextPlayCharParams;
}

export function keyDownAct(
  keyDownAction: AnswerPlayKeyDownAction,
  setKeyDownAction: Function,
  playChar: any,
  answerShow: boolean,
  setAnswerShow: Function,
  nextHandle: Function,
  yesHandle: Function
) {
  if (playChar !== null && keyDownAction !== ANSWER_PLAY_KEY_DOWN_ACTION.NONE) {
    if (keyDownAction === ANSWER_PLAY_KEY_DOWN_ACTION.NEXT) {
      nextHandle();
    } else if (keyDownAction === ANSWER_PLAY_KEY_DOWN_ACTION.ANSWER_SHOW) {
      if (!answerShow) {
        setAnswerShow(true);
      }
    } else if (keyDownAction === ANSWER_PLAY_KEY_DOWN_ACTION.ANSWER_HIDE) {
      setAnswerShow(false);
    } else if (keyDownAction === ANSWER_PLAY_KEY_DOWN_ACTION.ANSWER_YES) {
      yesHandle();
    }
    setKeyDownAction(ANSWER_PLAY_KEY_DOWN_ACTION.NONE);
  }
}
