import { ObKeyValString } from "type/common/common.type";

export const TOPIC_PER_PAGE_MAX = 5;

export const TOPIC_STATISTIC_TYPE: ObKeyValString = {
  ANSWER_PLAY: "answer_play",
  NONE: "",
};

export const TOPIC_STATISTIC_TYPES = Object.values(TOPIC_STATISTIC_TYPE);
