export function addToStringList(
  prevList: string[],
  text: string,
  appendToStart?: boolean
): string[] {
  let newList = [...prevList];
  if (!newList.includes(text)) {
    if (!appendToStart) {
      newList.push(text);
    } else {
      newList = [text, ...newList];
    }
  }
  return newList;
}

export function removeFromStringList(
  prevList: string[],
  ind: number
): string[] {
  const newList = [...prevList];
  if (ind >= 0 && ind < newList.length) {
    newList.splice(ind, 1);
  }
  return newList;
}

export function updateInStringList(
  prevList: string[],
  ind: number,
  text: string
): string[] {
  const newList = [...prevList];
  if (!newList.includes(text)) {
    if (ind >= 0 && ind < newList.length) {
      newList[ind] = text;
    }
  }

  return newList;
}

export function sameListsCheck(
  firstList: string[],
  secondList: string[]
): boolean {
  let ch: boolean = true;
  if (firstList.length !== secondList.length) {
    ch = false;
  } else {
    for (let i = 0; i < firstList.length; i++) {
      if (firstList[i] !== secondList[i]) {
        ch = false;
        break;
      }
    }
  }

  return ch;
}
