import { useMutation } from "@tanstack/react-query";
import { characterImport } from "common/api/asocijator/character/character_edit_api";
import { CharacterImportParams } from "common/api/asocijator/character/type/character_api.type";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseCharacterImportParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useCharacterImport = (hookParams?: UseCharacterImportParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: characterImportMutate } = useMutation({
    mutationFn: async (characterImportParams: CharacterImportParams) =>
      await characterImport(characterImportParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { characterImportMutate };
};
