import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { QUERY_KEYS } from "constant/react_query";
import TopicForm from "components/common/topic/TopicForm/TopicForm";
import { TopicResult } from "common/topic/dto/TopicResult";
import TopicSingleNav from "components/common/topic/TopicSingleNav";
import { useTopicUpdate } from "hooks/asocijator/topic/useTopicUpdate";
import { topicFormDefaultValuesGet } from "common/topic/topic_form_common";

type TopicEditProps = {
  topic: TopicResult;
};

const TopicEdit = ({ topic }: TopicEditProps) => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: topicFormDefaultValuesGet(topic),
  });
  const { topicUpdateMutate } = useTopicUpdate({
    invalidateKeysArray: [
      [QUERY_KEYS.ASOCIJATOR_TOPIC_SEARCH],
      [QUERY_KEYS.ASOCIJATOR_TOPIC_GET],
    ],
  });

  const onSubmit = async (data: any) => {
    console.log(data);

    const { name, description } = data;
    topicUpdateMutate({
      id: topic.id,
      updatePayload: {
        name,
        description,
      },
    });
    toast.success("Topic updated");
  };

  return (
    <DivTopic>
      <TopicForm
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        reset={reset}
        defaultValues={topicFormDefaultValuesGet(topic)}
      />
      <DivNav>
        <TopicSingleNav topic={topic} hideEditNav={true} />
      </DivNav>
    </DivTopic>
  );
};

const DivTopic = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivNav = styled.div`
  margin-top: 1rem;
`;

export default TopicEdit;
