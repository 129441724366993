import React, { useEffect } from "react";
import styled from "styled-components";
import TopicSingleNav from "components/common/topic/TopicSingleNav/TopicSingleNav";
import { TopicResult } from "common/topic/dto/TopicResult";

type TopicReadProps = {
  topic: TopicResult;
};

const TopicRead = ({ topic }: TopicReadProps) => {
  return (
    <div>
      <div>{topic.name}</div>
      <div>{topic.description}</div>
      <DivNav>
        <TopicSingleNav topic={topic} hideReadNav={true} />
      </DivNav>
    </div>
  );
};

const DivNav = styled.div`
  margin-top: 1.5rem;
`;

export default TopicRead;
