import { downloadOpenCharacterFile } from "common/character/file/character_file";
import { FILE_DOWNLOAD_OPEN_TYPES } from "constant/file/file_constants";
import styled from "styled-components";

type CharacterFileClickActLinkProps = {
  characterFilePath: string;
  children?: React.ReactNode;
};

const CharacterFileClickActLink = ({
  characterFilePath,
  children,
}: CharacterFileClickActLinkProps) => {
  const navClickHandle = async () => {
    await downloadOpenCharacterFile(
      characterFilePath,
      FILE_DOWNLOAD_OPEN_TYPES.OPEN
    );
  };

  const navLabelContent = children ?? <>open</>;

  return <Span onClick={navClickHandle}>{navLabelContent}</Span>;
};

const Span = styled.span`
  margin-left: 0rem;
  cursor: pointer;
`;

export default CharacterFileClickActLink;
