import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DEFAULT_UP_LABEL } from "./FileUpload.const";

type InputFileUploadProps = {
  rfFUp: any;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  multiple?: boolean;
  upLabel?: string;
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileUpload({
  rfFUp,
  onChange,
  multiple,
  upLabel,
}: InputFileUploadProps) {
  // const rfFUp = React.createRef<HTMLInputElement>();

  const selectFileHandle = () => {
    console.log("selectFileHandle", rfFUp.current?.files);
  };

  return (
    <Button
      component="label"
      variant="contained"
      startIcon={<CloudUploadIcon />}
    >
      {upLabel ?? DEFAULT_UP_LABEL}
      <VisuallyHiddenInput
        type="file"
        onChange={onChange}
        ref={rfFUp}
        multiple={multiple}
      />
    </Button>
  );
}
