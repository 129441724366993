import { useMutation } from "@tanstack/react-query";
import { userPasswordUpdate } from "common/api/asocijator/asocijator_user";

export const useUserPasswordUpdate = () => {
  const { mutate: userPasswordUpdateMutate } = useMutation({
    mutationFn: async (params: any) =>
      await userPasswordUpdate(params.id, params.pw),
    onSuccess: () => {
      console.log("pw updated");
    },
  });

  return { userPasswordUpdateMutate };
};
