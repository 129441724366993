import React from "react";
import { Button, Divider, List, ListItem, ListItemButton } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import AddIcon from "@mui/icons-material/Add";
import useTopicWork from "common/topic/provider/TopicWork";
import MainNavItem from "../MainNavItem";
import { NAV_ELEMENTS } from "constant/navigation";
import { topicWorkCharacterListNav } from "./TopicWorkNav.logic";
import useConfirm from "common/modal/ConfirmDialog";
import { useTopicAnswersReset } from "hooks/asocijator/answer/useAnswerAdd copy";
import toast from "react-hot-toast";

export default function TopicWorkNav() {
  const { topicWork, topicWorkEmpty } = useTopicWork();
  const { topicAnswersResetMutate } = useTopicAnswersReset();
  const actionConfirm = useConfirm();

  const topicExitHandle = () => {
    topicWorkEmpty();
  };

  const answersResetHandle = async () => {
    if (topicWork) {
      const check = await actionConfirm({
        title: "Answers reset",
        message:
          "Are you sure you want to reset answers for all characters in this topic?",
      });
      if (check) {
        topicAnswersResetMutate({ topicId: topicWork.id });
        toast.success("Answers for topic reset");
      }
    } else {
      toast.error("No topic selected for answers reset.");
    }
  };

  return (
    topicWork && (
      <>
        <List>
          <MainNavItem navElement={topicWorkCharacterListNav(topicWork.name)} />
          <MainNavItem
            navElement={NAV_ELEMENTS.CHARACTER_NEW}
            navImage={<AddIcon />}
          />
          <MainNavItem
            navElement={NAV_ELEMENTS.CHARACTER_LIST}
            navImage={<ArticleIcon />}
          />
          <MainNavItem navElement={NAV_ELEMENTS.CHARACTER_IMPORT_EXPORT} />
          <MainNavItem navElement={NAV_ELEMENTS.ANSWER_PLAY} />
          <ListItem key="answersReset" disablePadding>
            <ListItemButton>
              <Button onClick={answersResetHandle}>ANSWERS RESET</Button>
            </ListItemButton>
          </ListItem>
          <ListItem key="topicExit" disablePadding>
            <ListItemButton>
              <Button onClick={topicExitHandle} variant="outlined">
                TOPIC EXIT
              </Button>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </>
    )
  );
}
