import { USER_TYPES } from "constant/user";
import { UserResult } from "./dto/UserResult";

export const userFormDefaultValuesGet = (user?: UserResult) => {
  if (user) {
    return {
      un: user.un,
      email: user.email,
      uType: user.uType,
      firstName: user.firstName,
      lastName: user.lastName,
    };
  } else {
    const defaultValues = {
      un: "",
      email: "",
      uType: USER_TYPES.STANDARD.value,
      firstName: "",
      lastName: "",
    };
    return defaultValues;
  }
};
