import DivPosOpacity from "components/common/position/DivPosOpacity";
import { TOPIC_STATISTIC_TYPE } from "constant/topic/topic.const";
import { useTopicStatistic } from "hooks/asocijator/topic/statistic/useTopicStatistic";
import styled from "styled-components";
import { percentageFromTotal } from "./AnswerPlayStatistic.logic";
import { DivMarginTop } from "components/common/style/margin/DivMarginTop";

export type AnswerPlayStatisticProps = {
  topicId: string;
};

const AnswerPlayStatistic = ({ topicId }: AnswerPlayStatisticProps) => {
  const topicStatistic = useTopicStatistic({
    topicId,
    statisticType: TOPIC_STATISTIC_TYPE.ANSWER_PLAY,
  });

  return (
    <AnswerPlayStatisticDiv>
      {topicStatistic && (
        <Div>
          <Div>
            {topicStatistic.answerCorrectCount}/{topicStatistic.topicCharCount}{" "}
            -
            {percentageFromTotal(
              topicStatistic.answerCorrectCount,
              topicStatistic.topicCharCount
            )}
            %
          </Div>
          <DivMarginTop mt={1}>
            First try:
            {percentageFromTotal(
              topicStatistic.goFirstTryCorrectCount,
              topicStatistic.topicCharCount
            )}
            %
          </DivMarginTop>
        </Div>
      )}
    </AnswerPlayStatisticDiv>
  );
};

const AnswerPlayStatisticDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  margin-left: 1rem;
`;

const Div = styled.div`
  font-size: 2rem;
`;

export default AnswerPlayStatistic;
