export function fileNameGet(path: string): string {
  let fileName = path;
  const ind: number = fileName.lastIndexOf("/");
  if (ind >= 0 && ind <= fileName.length - 2) {
    fileName = fileName.substring(ind + 1);
  }
  return fileName;
}

export function fileExtensionGet(path: string): string {
  let fileExtension = "";
  const ind: number = path.lastIndexOf(".");
  if (ind >= 0 && ind <= path.length - 2) {
    fileExtension = path.substring(ind + 1);
  }
  return fileExtension;
}
