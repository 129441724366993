import { useQuery } from "@tanstack/react-query";
import {
  TopicStatisticParams,
  topicStatistic,
} from "common/api/asocijator/topic/asocijator_topic_statistic";
import { QUERY_KEYS } from "constant/react_query";

export const useTopicStatistic = (statisticParams: TopicStatisticParams) => {
  const { data: statistic } = useQuery(
    [QUERY_KEYS.ASOCIJATOR_TOPIC_STATISTIC, statisticParams],
    async () => {
      const result = await topicStatistic(statisticParams);
      return result;
    },
    { staleTime: Infinity }
  );
  const result =
    typeof statistic !== "undefined" && statistic !== "" ? statistic : null;
  return result;
};
