import React, { useCallback, useState } from "react";
import styled from "styled-components";
import NavMenuSmallScreen from "./NavMenuSmallScreen";

type MainNavigationSmallScreenProps = {
  showNav?: boolean;
};

const MainNavigationSmallScreen = ({
  showNav,
}: MainNavigationSmallScreenProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const clickHandle = useCallback(() => setShowMenu(false), []);

  const menuShowClickHandle = () => {
    setShowMenu((currentValue: boolean) => {
      return !currentValue;
    });
  };

  const buttonMenu = (
    <NavMenuSmallScreen
      menuShowClickHandle={menuShowClickHandle}
      navItemClickHandle={clickHandle}
      showMenu={showMenu}
    />
  );

  const mainNavSmallScreen = !showNav ? (
    <DivSmallNav>{buttonMenu}</DivSmallNav>
  ) : (
    <DivNav>{buttonMenu}</DivNav>
  );

  return mainNavSmallScreen;
};

const DivSmallNav = styled.div.attrs({ className: "screen-small" })`
  align-self: end;
`;

const DivNav = styled(DivSmallNav)`
  display: inherit;
`;

export default MainNavigationSmallScreen;
