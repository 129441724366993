import { axiosAsocijator } from "../axios/asocijator_axios";

export type AnswerAddParams = {
  characterId: string;
  correct: string;
};

export type TopicAnswersResetParams = {
  topicId: string;
};

export const answerAdd = async (
  answerData: AnswerAddParams
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosAsocijator.post("answer", answerData);
  } catch (error) {}
  if (response && response.status === 201) {
    result = response.data;
  }

  return result;
};

export const topicAnswersReset = async (
  answerData: TopicAnswersResetParams
): Promise<boolean> => {
  let result: boolean = false;
  let response: any;
  try {
    response = await axiosAsocijator.put(
      "answer/topic-answer-reset",
      answerData
    );
  } catch (error) {}
  if (response && response.status === 204) {
    result = true;
  }

  return result;
};
