import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "hooks/hook_util/debounce/useDebounce";
import { TOPIC_PER_PAGE_MAX } from "constant/topic/topic.const";
import { TopicSearchParams } from "common/api/asocijator/topic/asocijator_topic";
import { useTopicSearch } from "hooks/asocijator/topic/useTopicSearch";
import { SEARCH_NAMES } from "constant/search/search";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { TopicResult } from "common/topic/dto/TopicResult";
import {
  searchSelectInitial,
  searchSelectResults,
  showSearchSelect,
} from "./TopicSearchSelect.logic";
import TopicSelection from "./TopicSelection";
import { ReactRefI } from "type/common/ref.type";

type TopicSearchSelectProps = {
  excludeIds?: string[];
  onValueSelect?: Function;
  label?: string;
  topicSelection?: TopicResult;
  topicSelectionDeleteHandle?: Function;
  topicId?: string;
};

const getOptionLabel = (option: TopicResult) => option.name;

const TopicSearchSelect = ({
  excludeIds,
  onValueSelect,
  label,
  topicSelection,
  topicSelectionDeleteHandle,
  topicId,
}: TopicSearchSelectProps) => {
  const [value, setValue] = useState<TopicResult | null>(null);
  const searchOptionsTimeRef: ReactRefI<null | number> = useRef<null | number>(
    null
  );
  const [searchParams, setSearchParams] = useState<TopicSearchParams>(
    searchSelectInitial(topicId)
  );
  const topicListResult = useTopicSearch(searchParams);
  const handleTopicSearch = useDebounce((searchText: string) => {
    setSearchParams({
      ...searchParams,
      [SEARCH_NAMES.SEARCH_TEXT]: searchText,
      skip: 0,
    });
  }, 500);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    const currentTime = new Date().getTime();

    if (
      searchOptionsTimeRef.current === null ||
      searchOptionsTimeRef.current < currentTime
    ) {
      invalidateQuery.invalidateQueriesByKeys([
        QUERY_KEYS.ASOCIJATOR_TOPIC_SEARCH,
      ]);
      searchOptionsTimeRef.current = currentTime;
    }
  }, [searchParams, invalidateQuery]);

  const results: TopicResult[] = topicListResult
    ? searchSelectResults(topicListResult["items"], excludeIds)
    : [];

  const changeHandle = (event: any, newValue: TopicResult | null) => {
    if (onValueSelect) {
      onValueSelect(newValue);
      setValue(null);
    }
    // if (onLetterSelect && newValue) {
    //   onLetterSelect(newValue.letter);
    //   setValue(null);
    // }
  };

  const textChangeHandle = (event: any) => {
    handleTopicSearch(event.target.value);
  };

  return (
    <DivNav>
      {topicSelection &&
        topicSelectionDeleteHandle &&
        showSearchSelect(topicSelection) && (
          <DivSelection>
            <TopicSelection
              topicSelection={topicSelection}
              topicSelectionDeleteHandle={topicSelectionDeleteHandle}
            />
          </DivSelection>
        )}
      <Autocomplete
        getOptionLabel={getOptionLabel}
        value={value}
        id="combo-box-demo"
        options={results}
        blurOnSelect
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? "Topic"}
            onChange={textChangeHandle}
          />
        )}
        onChange={changeHandle}
      />
    </DivNav>
  );
};

const DivNav = styled.div`
  display: flex;
  margin-top: 0rem;
`;

const DivSelection = styled.div`
  margin-right: 1rem;
`;

export default TopicSearchSelect;
