import { useMutation } from "@tanstack/react-query";
import { topicDelete } from "common/api/asocijator/topic/asocijator_topic";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseTopicDeleteParams = {
  invalidateKeys?: string[];
};

export const useTopicDelete = (hookParams?: UseTopicDeleteParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: topicDeleteMutate } = useMutation({
    mutationFn: async (id: string) => await topicDelete(id),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
    },
  });

  return { topicDeleteMutate };
};
