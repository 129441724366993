import React, { useRef } from "react";
import styled from "styled-components";
import UpdateStringField from "../UpdateStringField";

export type UpdateStringEditProps = {
  initialVal: string;
  fieldEditConfirm: (text: string) => any;
  id?: string;
};

function UpdateStringEdit({
  initialVal,
  fieldEditConfirm,
  id,
}: UpdateStringEditProps) {
  const stringFieldRef = useRef<string>(initialVal);

  // const fieldEditConfirm = (text: string) => {
  //   console.log("fieldEditConfirm, text", text);
  // };

  return (
    <div>
      <UpdateStringField
        fieldRef={stringFieldRef}
        fieldConfirmHandle={fieldEditConfirm}
        initialVal={initialVal}
        id={id ?? undefined}
      />
    </div>
  );
}

const Div = styled.div`
  div:first-of-type {
    // margin-top: 0rem;
  }
`;

export default UpdateStringEdit;
