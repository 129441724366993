import { CharacterFile } from "common/character/dto/CharacterFile";
import { retrieveCharacterFileSrc } from "common/character/file/character_file";
import { sizeInfoGet } from "common/character/character_common";
import { fileNameGet } from "common/util/file/file_common";

export async function characterImageSrc(filePath: string) {
  const imageSrc = await retrieveCharacterFileSrc(filePath);

  return imageSrc;
}

export function imageTitleGet(characterFile: CharacterFile): string {
  const sizeInfo: string = sizeInfoGet(characterFile.size ?? null);
  const imageName: string = fileNameGet(characterFile.path);
  let title: string = imageName;
  if (sizeInfo !== "") {
    title += " " + sizeInfo;
  }

  return title;
}
