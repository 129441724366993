import { axiosAuth } from "../axios/auth_axios";

export type BearerResponse = {
  token: string;
  refresh: string;
};

export async function authBearer(appName: string): Promise<BearerResponse> {
  let res = {
    token: "",
    refresh: "",
  };
  try {
    const bearerResponse = await axiosAuth.post(
      "bearer/bearer",
      { appName },
      { withCredentials: true }
    );
    res.token = bearerResponse.data.token;
    res.refresh = bearerResponse.data.refresh;
  } catch (err) {
    throw err;
  }

  return res;
}
