import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { NavigateFunction, useParams, useSearchParams } from "react-router-dom";
import { useCharacterGet } from "hooks/asocijator/character/useCharacterGet";
import CharacterEdit from "./CharacterEdit";
import CharacterRead from "./CharacterRead";
import { NavMode } from "type/navigation/nav_mode.type";
import { NAV_MODE } from "constant/navigation";
import { useNavigate } from "react-router-dom";
import { characterKeyPressAct } from "./Character.logic";

const CharacterComponent = () => {
  const { characterId } = useParams();
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState<NavMode>(NAV_MODE.DEFAULT);
  const character = useCharacterGet(characterId as string);
  const navigate = useNavigate();
  const characterKeyPressRegister = useCallback(() => {
    window.addEventListener("keypress", (ev: any) =>
      characterKeyPressAct(ev, navigate)
    );
  }, [navigate]);
  useEffect(() => {
    const paramsMode: NavMode = searchParams.get("mode") ?? "";
    if (paramsMode !== mode) {
      setMode(paramsMode);
    }
  }, [searchParams, character]);
  useEffect(() => {
    characterKeyPressRegister();
    return () =>
      window.removeEventListener("keypress", characterKeyPressRegister);
  }, []);

  if (!character) {
    return null;
  }

  return (
    <>
      {mode !== NAV_MODE.EDIT && <CharacterRead character={character} />}
      {mode === NAV_MODE.EDIT && <CharacterEdit character={character} />}
    </>
  );
};

export default CharacterComponent;
