import { STRING_LIST_TYPE } from "./StringList.const";
import { StringListType } from "./StringList.type";

export function stringListTypeGet(listType?: StringListType): StringListType {
  const result = listType ?? STRING_LIST_TYPE.SAME_LINE;
  return result;
}

export function showLabel(
  resultsTotal: number,
  label?: string,
  showLabelForEmptyList?: boolean
): boolean {
  let show: boolean = false;

  if (label !== "" && (resultsTotal > 0 || showLabelForEmptyList)) {
    show = true;
  }

  return show;
}

export function resultsTotal(results: string[]): number {
  const total = results?.length ?? 0;
  return total;
}
