import { CHAR_CHECK_LIST_LINK } from "./PronounceInput.const";

export function charFromGroupNext(
  charGroupBase: string,
  charCurrent: string
): string {
  let res: string = "";
  if (
    charGroupBase in CHAR_CHECK_LIST_LINK &&
    charCurrent in CHAR_CHECK_LIST_LINK[charGroupBase]
  ) {
    res = CHAR_CHECK_LIST_LINK[charGroupBase][charCurrent];
  }

  return res;
}
