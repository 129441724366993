import { useQueryClient } from "@tanstack/react-query";

export const useInvalidateQueryByKeys = () => {
  const queryClient = useQueryClient();
  const invalidateQueriesByKeys = (queryKeys: string[]) => {
    queryClient.invalidateQueries({
      queryKey: queryKeys,
    });
  };

  const invalidateQueriesArraysByKeys = (queryArrayKeys: string[][]) => {
    queryArrayKeys.forEach((queryKeys: string[]) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys,
      });
    });
  };

  return { invalidateQueriesByKeys, invalidateQueriesArraysByKeys };
};
