import React, { useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { QUERY_KEYS } from "constant/react_query";
import CharacterForm from "components/common/character/CharacterForm/CharacterForm";
import { CharacterResult } from "common/character/dto/CharacterResult";
import CharacterSingleNav from "components/common/character/CharacterSingleNav";
import { useCharacterUpdate } from "hooks/asocijator/character/useCharacterUpdate";
import { characterFormDefaultValuesGet } from "common/character/character_form_common";
import { ReactRefI } from "type/common/ref.type";
import {
  CharacterRelateRefs,
  useCharacterRelateRefs,
} from "hooks/asocijator/character/useCharacterRelateRefs";
import {
  characterCheckUpdate,
  characterUpdatePayloadGet,
} from "./CharacterEdit.logic";

type CharacterEditProps = {
  character: CharacterResult;
  onUpdate?: Function;
};

const CharacterEdit = ({ character, onUpdate }: CharacterEditProps) => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: characterFormDefaultValuesGet(character),
  });
  const { characterUpdateMutate } = useCharacterUpdate({
    invalidateKeysArray: [
      [QUERY_KEYS.ASOCIJATOR_CHARACTER_SEARCH],
      [QUERY_KEYS.ASOCIJATOR_CHARACTER_GET],
    ],
  });
  const pronounceAddRef: ReactRefI<string[]> = useRef<string[]>([]);
  const examplesRef: ReactRefI<string[]> = useRef<string[]>([]);
  const fileEditRef = useRef<string[]>(character.file ?? []);
  const fileRemoveRef = useRef<string[]>([]);

  const characterRelateRefs: CharacterRelateRefs = useCharacterRelateRefs(
    character.relate
  );

  const onSubmit = async (data: any) => {
    const updatePayload = characterUpdatePayloadGet(
      data,
      pronounceAddRef,
      examplesRef,
      characterRelateRefs,
      fileEditRef,
      fileRemoveRef
    );

    if (characterCheckUpdate(character, updatePayload)) {
      characterUpdateMutate({
        id: character.id,
        updatePayload,
      });
      toast.success("Character updated");

      if (onUpdate) {
        onUpdate();
      }
    }
  };

  return (
    <DivCharacter>
      <CharacterForm
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        reset={reset}
        defaultValues={characterFormDefaultValuesGet(character)}
        pronounceAddRef={pronounceAddRef}
        examplesRef={examplesRef}
        fileEditRef={fileEditRef}
        fileRemoveRef={fileRemoveRef}
        characterRelateRefs={characterRelateRefs}
      />
      <DivNav>
        <CharacterSingleNav character={character} hideEditNav={true} />
      </DivNav>
    </DivCharacter>
  );
};

const DivCharacter = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivNav = styled.div`
  margin-top: 1rem;
`;

export default CharacterEdit;
