import { useMutation } from "@tanstack/react-query";
import { characterUpdate } from "common/api/asocijator/character/character_edit_api";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseCharacterUpdateParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
};

type CharacterUpdate = {
  id: string;
  updatePayload: any;
};

export const useCharacterUpdate = (hookParams?: UseCharacterUpdateParams) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: characterUpdateMutate } = useMutation({
    mutationFn: async (params: CharacterUpdate) =>
      await characterUpdate(params.id, params.updatePayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }
    },
  });

  return { characterUpdateMutate };
};
