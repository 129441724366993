import { useQuery } from "@tanstack/react-query";
import { characterSearch } from "common/api/asocijator/character/character_api";
import { CharacterSearchParams } from "common/api/asocijator/character/type/character_api.type";
import { QUERY_KEYS } from "constant/react_query";

export const useCharacterSearch = (searchParams?: CharacterSearchParams) => {
  const { data: character } = useQuery(
    [QUERY_KEYS.ASOCIJATOR_CHARACTER_SEARCH, searchParams],
    async () => {
      const result = await characterSearch(searchParams);
      return result;
    },
    { staleTime: Infinity }
  );
  const result =
    typeof character !== "undefined" && character !== "" ? character : null;
  return result;
};
