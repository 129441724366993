import StringList from "components/common/list/StringList";
import styled from "styled-components";

type CharParseMessageProps = {
  messages: string[];
};

const CharParseMessage = ({ messages }: CharParseMessageProps) => {
  return (
    messages && (
      <Div>
        <StringList results={messages} />
      </Div>
    )
  );
};

const Div = styled.div`
  margin-top: 1rem;
`;

export default CharParseMessage;
