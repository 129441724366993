import { useAuthCurrent } from "./auth_current";
import { useEffect, useState } from "react";

export const useAuthShowContent = () => {
  const authCurrent = useAuthCurrent();
  const [showContent, setShowContent] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>("");

  useEffect(() => {
    if (authCurrent) {
      if (authCurrent.uId !== "") {
        setShowContent(true);
      } else {
        setShowContent(false);
        setRedirectPath("/login");
      }
    } else {
      setShowContent(false);
    }
  }, [authCurrent]);

  return { showContent, redirectPath };
};
