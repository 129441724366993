export function percentageFromTotal(
  currentCount: number,
  totalCount: number,
  decimalPositions: number = 2
): number {
  let result: number = (currentCount / totalCount) * 100;
  result = Math.round(result * 100) / 100;

  return result;
}
