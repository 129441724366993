import React, { createContext, useContext, useEffect, useState } from "react";
import useTopicWork from "common/topic/provider/TopicWork";
import { TopicAnswerPlayCharacterParams } from "common/api/asocijator/topic/asocijator_topic_answer_play";
import { useTopicAnswerPlayCharacter } from "hooks/asocijator/topic/answer_play/useTopicAnswerPlayCharacter";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { AnswerPlayCharContextValue } from "./AnswerPlayChar.type";
import {
  answerPlayCharContextValueDefault,
  nextPlayCharParamsGet,
  playCharIdGet,
} from "./AnswerPlayChar.logic";
import { useAnswerAdd } from "hooks/asocijator/answer/useAnswerAdd";

const AnswerPlayChar = createContext<AnswerPlayCharContextValue>(
  answerPlayCharContextValueDefault()
);

type AnswerPlayCharProviderProps = { children: React.ReactNode };

export function AnswerPlayCharProvider({
  children,
}: AnswerPlayCharProviderProps) {
  const { topicWork } = useTopicWork();
  const [playCharParams, setPlayCharParams] =
    useState<TopicAnswerPlayCharacterParams>({
      topicId: topicWork?.id as string,
    });
  const playChar = useTopicAnswerPlayCharacter(playCharParams);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([
      QUERY_KEYS.ASOCIJATOR_TOPIC_ANSWER_PLAY_CHARACTER,
    ]);
    invalidateQuery.invalidateQueriesByKeys([
      QUERY_KEYS.ASOCIJATOR_TOPIC_STATISTIC,
    ]);
  }, [playCharParams]);
  const { answerAddMutate } = useAnswerAdd({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_CHARACTER_GET],
    successHandle: (id: any) => {
      console.log("add success", id);
    },
  });

  const charAnswerAdd = (correct: string) => {
    answerAddMutate({ characterId: playChar.id, correct });
  };

  const charAnswerYesAdd = () => {
    charAnswerAdd("y");
  };

  const charAnswerNoAdd = () => {
    charAnswerAdd("n");
  };

  const nextChar = () => {
    const newPlayCharParams = nextPlayCharParamsGet(
      playChar,
      playCharParams,
      topicWork?.id as string
    );
    setPlayCharParams(newPlayCharParams);
  };

  const nextAnswerNoAdd = () => {
    charAnswerNoAdd();
    nextChar();
  };

  return (
    <AnswerPlayChar.Provider
      value={{
        playChar,
        playCharId: playCharIdGet(playChar),
        charAnswerAdd,
        charAnswerYesAdd,
        charAnswerNoAdd,
        nextChar,
        nextAnswerNoAdd,
      }}
    >
      {children}
    </AnswerPlayChar.Provider>
  );
}

export default function useAnswerPlayChar() {
  return useContext(AnswerPlayChar);
}
