import { useEffect, useState } from "react";
import styled from "styled-components";
import { useErrorBoundary } from "react-error-boundary";
import { axiosAsocijator } from "common/api/axios/asocijator_axios";

const AsocijatorStart = () => {
  const { showBoundary } = useErrorBoundary();
  const [msgAuthRequiredAsocijator, setMsgAuthRequiredAsocijator] =
    useState<any>("asocijator not accessed");

  useEffect(() => {
    axiosAsocijator
      .get("auth/authrequired")
      .then((res) => {
        console.log("asocijator res authrequired", res);
        setMsgAuthRequiredAsocijator(res.statusText);
      })
      .catch((err) => {
        console.log("asocijator res authrequired err", err.response.status);
      });
  }, []);

  return (
    <div>
      <div>START</div>
      <div>Asocijator: {msgAuthRequiredAsocijator}</div>
    </div>
  );
};

export default AsocijatorStart;
