import styled from "styled-components";
import { CharacterResult } from "common/character/dto/CharacterResult";
import StringList from "components/common/list/StringList";
import { STRING_LIST_TYPE } from "components/common/list/StringList/StringList.const";
import CharacterFileList from "../file/CharacterFileList";

type CharacterDescriptionProps = {
  character: CharacterResult;
};

const CharacterDescription = ({ character }: CharacterDescriptionProps) => {
  return (
    <>
      <Div>{character.pronounce}</Div>
      <Div>{character.meaning}</Div>
      <Div>
        <StringList
          results={character.pronounceAdd}
          label="Pronounce add"
          listType={STRING_LIST_TYPE.SAME_LINE}
          delimiter=","
        />
        <DivLi>
          <StringList
            results={character.examples}
            label=""
            listType={STRING_LIST_TYPE.NEW_LINE}
          />
        </DivLi>
        {character.relate && (
          <>
            <DivLi>
              <StringList
                results={character.relate.meaning ?? []}
                label="Relate meaning"
                listType={STRING_LIST_TYPE.SAME_LINE}
                delimiter=","
              />
            </DivLi>
            <DivLi>
              <StringList
                results={character.relate.pronounce}
                label="Relate pronounce"
                listType={STRING_LIST_TYPE.SAME_LINE}
                delimiter=","
              />
            </DivLi>
            <DivLi>
              <StringList
                results={character.relate.shape}
                label="Relate shape"
                listType={STRING_LIST_TYPE.SAME_LINE}
                delimiter=","
              />
            </DivLi>
            {character.file && (
              <DivLi>
                <CharacterFileList characterFiles={character.file} />
              </DivLi>
            )}
          </>
        )}
      </Div>
    </>
  );
};

const Div = styled.div`
  margin-top: 1rem;
  font-size: 1.5rem;
`;

const DivLi = styled.div`
  margin-top: 1rem;
`;

export default CharacterDescription;
