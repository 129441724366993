import { NavElement } from "type/navigation/main_navigation.type";

export const filterNavElementsByAccess = (
  navElements: NavElement[],
  isAdmin: boolean
) =>
  navElements.filter(
    (navElement: NavElement) => !(navElement.adminAccess && !isAdmin)
  );

export const navArraysGet = (
  navArrays: NavElement[][],
  isAdmin: boolean
): NavElement[][] => {
  const result: NavElement[][] = navArrays.map((navArray: NavElement[]) =>
    filterNavElementsByAccess(navArray, isAdmin)
  );
  return result;
};
