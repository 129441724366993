import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { TopicResult } from "common/topic/dto/TopicResult";
import { NavLink } from "react-router-dom";
import { useTopicDelete } from "hooks/asocijator/topic/useTopicDelete";
import { QUERY_KEYS } from "constant/react_query";
import { topicLinkSingle } from "common/topic/topic_link_common";
import useConfirm from "common/modal/ConfirmDialog";
import { Button } from "@mui/material";
import TopicChooseNav from "../TopicChooseNav";
import { DivMarginLeft } from "components/common/style/margin/DivMarginLeft";

type TopicSingleNavProps = {
  topic: TopicResult;
  hideEditNav?: boolean;
  hideReadNav?: boolean;
};

const TopicSingleNav = ({
  topic,
  hideEditNav,
  hideReadNav,
}: TopicSingleNavProps) => {
  const { topicDeleteMutate } = useTopicDelete({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_TOPIC_SEARCH],
  });

  const actionConfirm = useConfirm();

  const deleteHandle = async () => {
    const check = await actionConfirm({
      title: "Topic",
      message: "Are you sure you want to delete topic",
    });
    if (check) {
      topicDeleteMutate(topic.id);
    }
  };

  return (
    <DivNav>
      <DeleteIcon onClick={deleteHandle} />
      {!hideEditNav && (
        <NavLink to={topicLinkSingle(topic.id, true)}>
          <EditIcon />
        </NavLink>
      )}
      {!hideReadNav && (
        <NavLink to={topicLinkSingle(topic.id)}>
          <ReadMoreIcon />
        </NavLink>
      )}
      <DivMarginLeft ml={1}>
        <TopicChooseNav topic={topic} navigateTo="/character/list" />
      </DivMarginLeft>
    </DivNav>
  );
};

const DivNav = styled.div`
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  svg {
    margin-left: 1rem;
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
  &&& > svg:first-of-type {
    margin-left: 0;
  }
`;

export default TopicSingleNav;
