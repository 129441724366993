import DivPosOpacity from "components/common/position/DivPosOpacity";
import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import AnswerPlayNav from "./AnswerPlayNav";
import { ReactRefI } from "type/common/ref.type";
import CharacterPlayAnswer from "./CharacterPlayAnswer";
import { playCharPositionCalc, posOpacityInitial } from "./PlayCharacter.logic";
import { PLAY_CHAR_ID } from "./PlayCharacter.const";
import useAnswerPlayChar from "common/answer/provider/AnswerPlayChar";
import { PosOpacity } from "../TopicAnswerPlay.type";

export type PlayCharacterProps = {
  yesMoveCheckRef: ReactRefI<boolean>;
  answerShow: boolean;
  setAnswerShow: Function;
};

const PlayCharacter = ({
  yesMoveCheckRef,
  answerShow,
  setAnswerShow,
}: PlayCharacterProps) => {
  const { playChar } = useAnswerPlayChar();

  const [playCharPosOpacity, setPlayCharPosOpacity] = useState<PosOpacity>(
    posOpacityInitial(0)
  );
  useEffect(() => {
    playCharPositionCalc().then((PO: PosOpacity) => setPlayCharPosOpacity(PO));
  }, []);

  const showAnswerNavClick = () => {
    setAnswerShow(!answerShow);
  };

  return (
    <PlayCharacterDiv>
      <PlayLetterDiv letter={playChar?.letter}>
        {playChar?.letter}
      </PlayLetterDiv>
      {playChar !== null &&
      typeof playChar.answer !== "undefined" &&
      playChar.answer.correct === "n" ? (
        <PreviousIncorrectDiv></PreviousIncorrectDiv>
      ) : null}

      <AnswerPlayNav yesMoveCheckRef={yesMoveCheckRef} />
      <span>
        <ShowAnswerDiv onClick={showAnswerNavClick}>show answer</ShowAnswerDiv>
        {answerShow && playChar && <CharacterPlayAnswer />}
      </span>
    </PlayCharacterDiv>
  );
};

const PlayCharacterDiv = styled.div`
  margin-top: 0rem;
  display: flex;
  flex-direction: column;
`;

const ShowAnswerDiv = styled.div`
  margin-top: 0rem;
  cursor: pointer;
`;

const PlayLetterDiv: any = styled.div`
  font-size: ${(props: any) =>
    !props.letter || props.letter.length <= 3 ? "10rem" : "2rem"};
`;

const PreviousIncorrectDiv = styled.div`
  border-top: 0.5rem solid blue;
  margin-bottom: 1rem;
`;

export default PlayCharacter;
