import React from "react";
import styled from "styled-components";

export type UpdateStringReadProps = {
  initialVal: string;
};

function UpdateStringRead({ initialVal }: UpdateStringReadProps) {
  return <div>{initialVal}</div>;
}

const Div = styled.div`
  margin-top: 1rem;
  div:first-of-type {
    // margin-top: 1rem;
  }
`;

export default UpdateStringRead;
