import useTopicWork from "common/topic/provider/TopicWork/TopicWork";
import { useState } from "react";
import styled from "styled-components";
import { characterImportSetsResultDefault } from "./CharacterImport.logic";
import { CharacterImportSetsResult } from "common/character/dto/character_import.type";
import CharParseResult from "./CharParseResult";
import toast from "react-hot-toast";
import CharacterImportTypeSelect from "components/common/character/import/CharacterImportTypeSelect";
import { CHARACTER_IMPORT_TYPE } from "constant/character/character_import.constant";
import CharImportFileUpload from "./CharImportFileUpload";
import CharImportNav from "./CharImportNav";
import CharExportNav from "./CharExportNav";
import { DivMarginTop } from "components/common/style/margin/DivMarginTop";
import CharCopy from "./CharCopy";

const CharacterImportExport = () => {
  const { topicWork } = useTopicWork();
  const [importParseCheckResult, setImportParseCheckResult] =
    useState<CharacterImportSetsResult>(characterImportSetsResultDefault());
  const [importType, setImportType] = useState<string>(
    CHARACTER_IMPORT_TYPE.UPSERT_SET_ADD.value as string
  );

  const importResultHandle = (importResult: any) => {
    toast.success("Characters imported");
    setImportParseCheckResult(characterImportSetsResultDefault());
  };

  const fileUploadHandle = (data: any) => {
    setImportParseCheckResult(data);
  };

  const importTypeSelectHandle = (importType: any) => {
    setImportType(importType);
  };

  return (
    <Div>
      <div>Character Import</div>
      <DivMarginTop mt={1}>
        <CharacterImportTypeSelect
          label="Import type"
          onSelect={importTypeSelectHandle}
        />
      </DivMarginTop>
      <DivMarginTop mt={1}>
        <CharImportFileUpload
          topicId={topicWork?.id ?? ""}
          importType={importType}
          fileUploadHandle={fileUploadHandle}
        />
      </DivMarginTop>
      <DivMarginTop mt={1}>
        <CharImportNav
          topicId={topicWork?.id ?? ""}
          importType={importType}
          charImportSetsResult={importParseCheckResult}
          importResultHandle={importResultHandle}
        />
      </DivMarginTop>
      <DivMarginTop mt={1}>
        <CharParseResult importParseCheckResult={importParseCheckResult} />
      </DivMarginTop>
      <DivMarginTop mt={2}>Character Export</DivMarginTop>
      <DivMarginTop mt={1}>
        <CharExportNav topicId={topicWork?.id ?? ""} />
      </DivMarginTop>
      <DivMarginTop mt={2}>
        <CharCopy />
      </DivMarginTop>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 2rem;
  line-height: 1.25rem;
`;

export default CharacterImportExport;
