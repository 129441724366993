import { SelectFieldOption } from "components/UI/SelectField/SelectField.type";

export const CHARACTER_IMPORT_TYPE: { [name: string]: SelectFieldOption } = {
  INSERT: { value: "insert", label: "insert" },
  UPSERT_SET_ADD: { value: "upsert_set_add", label: "upsert set add" },
};

export const CHARACTER_IMPORT_TYPES = Object.values(CHARACTER_IMPORT_TYPE).map(
  (option: SelectFieldOption) => option.value
);

export const CHARACTER_IMPORT_TYPE_OPTIONS: SelectFieldOption[] = [
  CHARACTER_IMPORT_TYPE.INSERT,
  CHARACTER_IMPORT_TYPE.UPSERT_SET_ADD,
];
