import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useUserGet } from "hooks/asocijator/user/useUserGet";
import UserEdit from "./UserEdit";
import UserRead from "./UserRead";

const UserComponent = () => {
  const { userId } = useParams();
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState<string>("");
  const user = useUserGet(userId as string);
  useEffect(() => {
    const paramsMode = searchParams.get("mode") ?? "";
    if (paramsMode !== mode) {
      setMode(paramsMode);
    }
  }, [searchParams, user, mode]);

  if (!user) {
    return null;
  }

  return (
    <>
      {mode !== "edit" && <UserRead user={user} />}
      {mode === "edit" && <UserEdit user={user} />}
    </>
  );
};

export default UserComponent;
