import { CharacterResult } from "common/character/dto/CharacterResult";
import SearchResultList, {
  PageNavigation,
} from "components/common/SearchResultList";
import CharacterItem from "./CharacterItem";
import { useEffect, useState } from "react";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { CHARACTER_PER_PAGE_MAX } from "constant/character";
import { useCharacterSearch } from "hooks/asocijator/character/useCharacterSearch";
import { characterSearchFieldsGet } from "common/character/character_search";
import CharacterSearchFilters from "components/common/character/CharacterSearchFilters";
import useTopicWork from "common/topic/provider/TopicWork/TopicWork";
import { CharacterSearchParams } from "common/api/asocijator/character/type/character_api.type";
import { useTopicWorkSetPath } from "common/topic/provider/TopicWork/UseTopicWorkSetPath";

const CharacterList = () => {
  const { topicWork } = useTopicWork();
  useTopicWorkSetPath();
  const [searchParams, setSearchParams] = useState<CharacterSearchParams>({
    limit: CHARACTER_PER_PAGE_MAX,
    skip: 0,
    topicId: topicWork ? topicWork.id : "",
  });
  const characterListResult = useCharacterSearch(searchParams);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([
      QUERY_KEYS.ASOCIJATOR_CHARACTER_SEARCH,
    ]);
  }, [searchParams]);
  useEffect(() => {
    if (topicWork) {
      setSearchParams((prevCharacterSearchParams: CharacterSearchParams) => {
        return {
          ...prevCharacterSearchParams,
          topicId: topicWork.id,
        };
      });
    }
  }, [topicWork]);

  let results: CharacterResult[] = characterListResult
    ? characterListResult["items"]
    : [];
  const resTotal: number = characterListResult
    ? characterListResult["resTotal"]
    : 0;
  const pageNavigationHandle = (pageNum: number) => {
    const skip = pageNum * CHARACTER_PER_PAGE_MAX;
    setSearchParams({ ...searchParams, skip });
  };
  const pageNavigation: PageNavigation = {
    resTotal,
    limit: CHARACTER_PER_PAGE_MAX,
    skip: searchParams.skip ?? 0,
    pageNavigationHandle,
  };
  const searchHandle = (formData: any) => {
    const searchVals = characterSearchFieldsGet(formData);

    setSearchParams({
      ...searchParams,
      ...searchVals,
      skip: 0,
    });
  };

  return (
    <div>
      <CharacterSearchFilters searchHandle={searchHandle} />
      <SearchResultList<CharacterResult>
        renderSingle={(character: CharacterResult) => (
          <CharacterItem
            character={character}
            key={`character_${character.id}`}
          />
        )}
        results={results}
        pageNavigation={pageNavigation}
      />
    </div>
  );
};

export default CharacterList;
