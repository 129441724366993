import { Button } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { authLogout } from "common/auth/auth_api_common";
import { QUERY_KEYS } from "constant/react_query";
import React from "react";
import styled from "styled-components";

const LogoutNav = () => {
  const queryClient = useQueryClient();

  const { mutate: logoutMutate } = useMutation({
    mutationFn: async () => await authLogout(),
    onSuccess: () => {
      console.log("logout success");
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.AUTH_CURRENT_GET],
      });
    },
  });

  const logoutHandle = () => {
    console.log("logout");
    logoutMutate();
  };

  return (
    <Div>
      <Button onClick={logoutHandle} variant={"outlined"}>
        Logout
      </Button>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 3rem;
`;

export default LogoutNav;
