import { CHARACTER_COPY_DESTINATION_TYPE } from "constant/character/character_copy.constant";
import { CharCopySubmit, CharCopySubmitPayload } from "./CharCopy.type";
import toast from "react-hot-toast";
import { charCopyPayloadCheck } from "./CharCopyCheck.logic";
import { CopyDestinationT } from "../CharCopyDestination/CharCopyDestination.type";

export function charCopyFormDefaultValuesGet(): any {
  return {
    topicNewName: "",
  };
}

function charCopyDestinationIsNew(charCopyDestinationType: string): boolean {
  return (
    charCopyDestinationType === CHARACTER_COPY_DESTINATION_TYPE.TOPIC_NEW.value
  );
}

function charCopyDestinationIsUpdate(charCopyDestinationType: string): boolean {
  return (
    charCopyDestinationType ===
    CHARACTER_COPY_DESTINATION_TYPE.TOPIC_UPDATE.value
  );
}

export function charCopyFormPayloadGet(
  submitHandle: CharCopySubmit
): CharCopySubmitPayload {
  const result: CharCopySubmitPayload = {
    topicSourceId: submitHandle.topicSourceId,
    copyType: submitHandle.copyType,
    copyDestinationType: submitHandle.copyDestinationType,
    importType: submitHandle.importType,
    topicNewName: "",
    topicDestinationId: "",
  };

  if (charCopyDestinationIsNew(result.copyDestinationType)) {
    result.topicNewName = submitHandle.topicNewName;
  } else if (charCopyDestinationIsUpdate(result.copyDestinationType)) {
    result.topicDestinationId = submitHandle.topicDestination?.id ?? "";
  }

  return result;
}

export function charCopySubmit(submitHandle: CharCopySubmit) {
  const {
    topicNewName,
    copyDestinationType,
    topicDestination,
    characterCopyMutate,
    setCopyDestination,
  } = submitHandle;

  const payloadData: CharCopySubmitPayload =
    charCopyFormPayloadGet(submitHandle);

  const checkMessage = charCopyPayloadCheck(payloadData);
  if (checkMessage.check) {
    characterCopyMutate(payloadData);
    if (
      copyDestinationType === CHARACTER_COPY_DESTINATION_TYPE.TOPIC_NEW.value
    ) {
      setCopyDestination((currentVal: CopyDestinationT) => ({
        ...currentVal,
        topicName: topicNewName,
      }));
    } else if (
      copyDestinationType === CHARACTER_COPY_DESTINATION_TYPE.TOPIC_UPDATE.value
    ) {
      setCopyDestination((currentVal: CopyDestinationT) => ({
        ...currentVal,
        topicId: topicDestination.id,
        topicName: topicDestination.name,
      }));
    }
    toast.success("Characters copied");
  } else {
    toast.error("Copy data not correct." + checkMessage.message, {
      duration: 10 * 1000,
    });
  }
}
