import { ListLinkSimple } from "type/common/array/ListLinkSimple.type";

export function arrStrToListLinkSimple(arr: string[]): ListLinkSimple<string> {
  const res: ListLinkSimple<string> = {};
  const n: number = arr.length;

  if (n >= 2) {
    for (let i = 0; i <= n - 2; i++) {
      const val1 = arr[i];
      const val2 = arr[i + 1];
      res[val1] = val2;
    }
    res[arr[n - 1]] = arr[0];
  } else {
    const val = arr[0];
    res[val] = val;
  }

  return res;
}

export function arrSimpleEqual(arr1: any[], arr2: any[]): boolean {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}
