import { TopicResult } from "common/topic/dto/TopicResult";
import styled from "styled-components";
import TopicSingleNav from "components/common/topic/TopicSingleNav";
import { Divider } from "@mui/material";
import { topicLinkSingle } from "common/topic/topic_link_common";
import NavLinkUndecorated from "components/common/style/link/NavLinkUndecorated";

type TopicItemProps = {
  topic: TopicResult;
};

const TopicItem = ({ topic }: TopicItemProps) => {
  return (
    <Div>
      <Divider />
      <Div>
        <NavLinkUndecorated to={topicLinkSingle(topic.id)}>
          <div>{topic.name}</div>
          <div>{topic.description} </div>
        </NavLinkUndecorated>
        <TopicSingleNav topic={topic} />
      </Div>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 2rem;
  line-height: 1.25rem;
`;

export default TopicItem;
