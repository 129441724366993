import React, { useEffect, useState } from "react";
import InputFileUpload from "./InputFileUpload";
import styled from "styled-components";
import { AxiosInstance } from "axios";
import { DEFAULT_UP_LABEL } from "./FileUpload.const";

type FileUploadProps = {
  axiosInstance: AxiosInstance;
  uploadUrl: string;
  fileFieldName: string;
  fileUploadHandle: Function;
  showUploadedFile?: boolean;
  multiple?: boolean;
  fileNameDefault?: string;
  upLabel?: string;
  addFormData?: any;
};

export default function FileUpload({
  axiosInstance,
  uploadUrl,
  fileFieldName,
  fileUploadHandle,
  showUploadedFile,
  multiple,
  fileNameDefault,
  upLabel,
  addFormData,
}: FileUploadProps) {
  const [selectedFileName, setSelectedFileName] = useState<string>(
    fileNameDefault as string
  );
  const [upProgressInfo, setUpProgressInfo] = useState<string>("");
  useEffect(() => {
    setSelectedFileName(fileNameDefault as string);
  }, [fileNameDefault]);

  let rfFUp = React.createRef<HTMLInputElement>();

  const fUpload = (formData: any) => {
    const contentType = "multipart/form-data";
    const setResponse = (msg: any) => console.log(msg);
    setUpProgressInfo("uploading");
    axiosInstance({
      url: uploadUrl,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": contentType,
      },
    })
      .then((response) => {
        if (response.data.files) {
          setSelectedFileName(response.data.files[0].filename);
        }

        if (fileUploadHandle) {
          fileUploadHandle(response.data);
        }
        // setTimeout(() => setUpProgressInfo(""), 5 * 1000);
        setUpProgressInfo("");
      })
      .catch((error) => {
        setResponse("error");
        setResponse(error);
        setUpProgressInfo("");
      });
  };

  const fUpFormDataGet = () => {
    let formData = new FormData();
    if (rfFUp.current && rfFUp.current.files) {
      const n = rfFUp.current.files.length;
      for (let i = 0; i < n; i++) {
        formData.append(fileFieldName, rfFUp.current.files[i]);
      }
      setSelectedFileName(rfFUp.current.files[0].name);
    }
    if (addFormData) {
      Object.keys(addFormData).forEach((key: any) => {
        formData.append(key, addFormData[key]);
        // formData.append("topicId", "66160d0f84f8d7a06f2a432b");
      });
    }
    return formData;
  };

  const uploadAct = () => {
    let formData = fUpFormDataGet();
    fUpload(formData);
  };

  const fileChng = () => {
    uploadAct();
  };

  const fileNavAct = true;
  const fileNav = fileNavAct ? (
    <input type="file" ref={rfFUp} onChange={() => fileChng()} />
  ) : null;

  const actNavT = !fileNav ? (
    <div style={{ cursor: "pointer", color: "#630920" }}></div>
  ) : null;

  return (
    <div>
      {actNavT}
      {upProgressInfo !== "" && <InfoDiv>{upProgressInfo}</InfoDiv>}
      <InputFileUpload
        rfFUp={rfFUp}
        onChange={fileChng}
        multiple={multiple}
        upLabel={upLabel ?? DEFAULT_UP_LABEL}
      />
      {showUploadedFile && <FileNameDiv>{selectedFileName}</FileNameDiv>}
    </div>
  );
}

const FileNameDiv = styled.div`
  margin-top: 0.5rem;
`;

const InfoDiv = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  animation-name: uploading;
  animation-duration: 1.7s;
  animation-iteration-count: infinite;
`;
