import React, {
  KeyboardEventHandler,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { ReactRefI } from "type/common/ref.type";
import TextInputField from "components/UI/TextInputField";
import { Button } from "@mui/material";

export type UpdateStringFieldProps = {
  fieldRef: ReactRefI<string>;
  initialVal?: string;
  label?: string;
  fieldConfirmHandle?: (val: string) => any;
  emptyAfterConfirm?: boolean;
  confirmOnEnter?: boolean;
  id?: string;
};

function UpdateStringField({
  initialVal,
  fieldRef,
  label,
  fieldConfirmHandle,
  emptyAfterConfirm,
  confirmOnEnter,
  id,
}: UpdateStringFieldProps) {
  const [showField, setShowField] = useState<boolean>(true);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    if (!showField) {
      setShowField(true);
    }
  }, [showField]);

  const onKeyDownHandle: KeyboardEventHandler<Element> = (
    ev: KeyboardEvent<Element>
  ) => {
    if (ev.keyCode === 13) {
      ev.preventDefault();
      if (confirmOnEnter) {
        fieldConfirm();
        setFocused(true);
      }
    }
  };

  const fieldConfirm = () => {
    if (fieldConfirmHandle) {
      fieldConfirmHandle(fieldRef.current);
    }
    if (emptyAfterConfirm) {
      fieldRef.current = "";
      setShowField(false);
      // setFocused(true);
    }
  };

  return showField ? (
    <Div>
      <TextInputField
        // onChange={onChange}
        label={label ?? ""}
        defaultValue={initialVal ?? ""}
        fieldRef={fieldRef}
        onKeyDown={onKeyDownHandle}
        focused={focused}
        id={id ?? undefined}
        // error={typeof errors[name]?.message !== "undefined"}
        // type={}
      />
      <ButtonOk onClick={fieldConfirm}>OK</ButtonOk>
    </Div>
  ) : null;
}

const Div = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const ButtonOk = styled(Button)`
  margin-left: 0.5rem;
`;

export default UpdateStringField;
