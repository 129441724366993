import { TextField } from "@mui/material";
import styled from "styled-components";
import {
  ChangeEvent,
  ChangeEventHandler,
  KeyboardEventHandler,
  KeyboardEvent,
  useEffect,
} from "react";
import { ReactRefI } from "type/common/ref.type";

type Props = {
  label: string;
  defaultValue?: string;
  type?: string;
  onEnterHandle?: Function;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<Element>;
  error?: boolean;
  fieldRef?: ReactRefI<string>;
  focused?: boolean;
  id?: string;
};

const TextInputField = ({
  label,
  defaultValue,
  type,
  onChange,
  onKeyDown,
  error,
  fieldRef,
  focused,
  id,
}: Props) => {
  useEffect(() => {
    if (fieldRef) {
      fieldRef.current = defaultValue ?? "";
    }
  }, [defaultValue]);

  const onChangeHandle = (
    ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onChange) {
      onChange(ev);
    }
    if (fieldRef) {
      fieldRef.current = ev.target.value;
    }
  };

  const onKeyDownHandle = (ev: KeyboardEvent<Element>) => {
    if (onKeyDown) {
      onKeyDown(ev);
    }
  };

  return (
    <TextStyleField
      onChange={onChangeHandle}
      onKeyDown={onKeyDownHandle}
      // onKeyDown={(event: any) => {
      //   if (event.keyCode === 13) {
      //     console.log("aa 2", event.keyCode);
      //   }
      // }}
      label={label}
      defaultValue={defaultValue}
      error={error}
      type={type}
      name="textField"
      id={id ?? undefined}
      focused={focused}
      autoFocus={true}
    />
  );
};

const TextStyleField = styled(TextField)`
  &&& .MuiFormLabel-root {
    color: currentColor;
  }

  &&& .MuiInputBase-root {
    border: ${(props) => (props?.error ? "red" : "auto")};
  }
`;

export default TextInputField;
