import TopicSearchSelect from "components/common/topic/util/TopicSearchSelect";
import InputField from "components/UI/InputField";
import { Button } from "@mui/material";
import { DivMarginTop } from "components/common/style/margin/DivMarginTop";
import CharacterCopyTypeSelect from "../CharacterCopyTypeSelect";
import CharacterCopyDestinationTypeSelect from "../CharacterCopyDestinationTypeSelect";
import { ReactRefI } from "type/common/ref.type";
import { useState } from "react";
import { newTopicCheck } from "./CharCopyForm.logic";
import styled from "styled-components";
import { TopicResult } from "common/topic/dto/TopicResult";
import CharacterImportTypeSelect from "../../import/CharacterImportTypeSelect";
import { CHARACTER_COPY_TYPE } from "constant/character/character_copy.constant";

type CharCopyFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  characterCopyTypeRef: ReactRefI<string>;
  characterCopyDestinationTypeRef: ReactRefI<string>;
  characterImportTypeRef: ReactRefI<string>;
  topicDestinationRef: ReactRefI<any>;
};

const CharCopyForm = ({
  onSubmit,
  handleSubmit,
  control,
  characterCopyTypeRef,
  characterCopyDestinationTypeRef,
  characterImportTypeRef,
  topicDestinationRef,
}: CharCopyFormProps) => {
  const [isNewTopic, setIsNewTopic] = useState<boolean>(true);
  const [topicDestination, setTopicDestination] = useState<TopicResult | null>(
    null
  );

  const onDestinationTypeSelect = (destinationType: string) => {
    const newTopicCh: boolean = newTopicCheck(destinationType);
    setIsNewTopic(newTopicCh);
  };

  const topicSelectHandle = (topic: any) => {
    setTopicDestination(topic);
    topicDestinationRef.current = topic;
  };

  return (
    <div>
      <form>
        <DivMarginTop mt={1}>
          <CharacterCopyTypeSelect
            selectRef={characterCopyTypeRef}
            defaultValue={CHARACTER_COPY_TYPE.FIRST_NOT_CORRECT.value as string}
          />
        </DivMarginTop>

        <DivMarginTop mt={1}>
          <CharacterCopyDestinationTypeSelect
            selectRef={characterCopyDestinationTypeRef}
            onSelect={onDestinationTypeSelect}
          />
        </DivMarginTop>

        {isNewTopic && (
          <DivMarginTop mt={1}>
            <InputField name="topicNewName" control={control} label="name" />
          </DivMarginTop>
        )}

        {!isNewTopic && (
          <>
            <DivMarginTop mt={1}>
              <div>
                <TopicSearchSelect onValueSelect={topicSelectHandle} />
              </div>
              {topicDestination !== null && (
                <DivTopicSelName>{topicDestination.name}</DivTopicSelName>
              )}
            </DivMarginTop>
            <DivMarginTop mt={1}>
              <CharacterImportTypeSelect />
            </DivMarginTop>
          </>
        )}

        <DivMarginTop mt={1.5}>
          <Button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            variant="outlined"
          >
            OK
          </Button>
        </DivMarginTop>
      </form>
    </div>
  );
};

const DivTopicSelName = styled.div`
  margin-top: 0.5rem;
`;

export default CharCopyForm;
