import { Button } from "@mui/material";
import { defaultValueGet } from "common/parameters_common";
import InputField from "components/UI/InputField";
import styled from "styled-components";
import PronounceInput from "../answer_util/PronounceInput";
import { UseFormSetValue } from "react-hook-form";

export type CharAnswerFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  setValue: UseFormSetValue<any>;
  defaultValues?: any;
};

const CharAnswerForm = ({
  onSubmit,
  handleSubmit,
  defaultValues,
  control,
  setValue,
}: CharAnswerFormProps) => {
  const pronounceDefault = defaultValueGet(defaultValues, "pronounce", "");
  const meaningDefault = defaultValueGet(defaultValues, "meaning", "");

  return (
    <FormAnswer>
      <DivField>
        <PronounceInput
          defaultValue={pronounceDefault}
          name="pronounce"
          setValue={setValue}
          autoFocus={false}
          placeholder="pronounce"
        />
      </DivField>
      <DivField>
        <InputField
          name="meaning"
          control={control}
          label="meaning"
          defaultValue={meaningDefault}
        />
      </DivField>

      <DivOk>
        <Button onClick={handleSubmit(onSubmit)} type="submit">
          OK
        </Button>
      </DivOk>
    </FormAnswer>
  );
};

const FormAnswer = styled.form`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
`;

const DivField = styled.div`
  margin-top: 1rem;

  div {
    width: 100%;
  }
`;

const DivOk = styled.div`
  margin-top: 0.3rem;
  text-align: right;
`;

export default CharAnswerForm;
