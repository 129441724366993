export const QUERY_KEYS = {
  AUTH_CURRENT_GET: "AUTH_CURRENT_GET",
  AUTH_LOGIN_POST: "AUTH_LOGIN_POST",
  ASOCIJATOR_TOPIC_GET: "ASOCIJATOR_TOPIC_GET",
  ASOCIJATOR_TOPIC_LIST_GET: "ASOCIJATOR_TOPIC_LIST_GET",
  ASOCIJATOR_TOPIC_SEARCH: "ASOCIJATOR_TOPIC_SEARCH",
  ASOCIJATOR_TOPIC_STATISTIC: "ASOCIJATOR_TOPIC_STATISTIC",
  ASOCIJATOR_TOPIC_ANSWER_PLAY_CHARACTER:
    "ASOCIJATOR_TOPIC_ANSWER_PLAY_CHARACTER",
  ASOCIJATOR_USER_GET: "ASOCIJATOR_USER_GET",
  ASOCIJATOR_USER_LIST_GET: "ASOCIJATOR_USER_LIST_GET",
  ASOCIJATOR_USER_SEARCH: "ASOCIJATOR_USER_SEARCH",
  ASOCIJATOR_CHARACTER_GET: "ASOCIJATOR_CHARACTER_GET",
  ASOCIJATOR_CHARACTER_LIST_GET: "ASOCIJATOR_CHARACTER_LIST_GET",
  ASOCIJATOR_CHARACTER_SEARCH: "ASOCIJATOR_CHARACTER_SEARCH",
};
