import { useQuery } from "@tanstack/react-query";
import { userGet } from "common/api/asocijator/asocijator_user";
import { QUERY_KEYS } from "constant/react_query";

export const useUserGet = (id: string) => {
  const { data: user } = useQuery(
    [QUERY_KEYS.ASOCIJATOR_USER_GET, id],
    async () => {
      const result = await userGet(id);
      return result;
    },
    { staleTime: Infinity }
  );
  const result = typeof user !== "undefined" && user !== "" ? user : null;
  return result;
};
