import { axiosAsocijator } from "../../axios/asocijator_axios";

export type TopicAnswerPlayCharacterParams = {
  topicId: string;
  characterId?: string;
};

export type CharacterRelateLettersParams = {
  characterId: string;
};

export const topicAnswerPlayCharacter = async ({
  topicId,
  characterId,
}: TopicAnswerPlayCharacterParams): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosAsocijator.post(
      `topic/answer_play/${topicId}/character`,
      { characterId }
    );
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
