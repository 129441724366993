import { USER_TYPES } from "constant/user";
import { AuthResponse } from "./auth_api_common";

export const authCurrentIsAdmin = (currentAuth?: AuthResponse): boolean => {
  let check: boolean = false;
  if (currentAuth?.uType === USER_TYPES.ADMIN.value) {
    check = true;
  }

  return check;
};
