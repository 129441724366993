import styled from "styled-components";
import Button from "@mui/material/Button";
import { defaultValueGet } from "common/parameters_common";
import InputField from "components/UI/InputField";
import { useEffect, useState } from "react";
import UserTypeSelect from "./UserTypeSelect";

type UserFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  reset: Function;
  defaultValues?: any;
};

const UserForm = ({
  onSubmit,
  handleSubmit,
  reset,
  defaultValues,
  control,
}: UserFormProps) => {
  const [pwUpdateShow, setPwUpdateShow] = useState<boolean>(true);

  const unDefault = defaultValueGet(defaultValues, "un", "");
  const emailDefault = defaultValueGet(defaultValues, "email", "");
  const uTypeDefault = defaultValueGet(defaultValues, "uType", "");
  const firstNameDefault = defaultValueGet(defaultValues, "firstName", "");
  const lastNameDefault = defaultValueGet(defaultValues, "lastName", "");

  useEffect(() => {
    if (unDefault !== "") {
      setPwUpdateShow(false);
    }
  }, [unDefault]);

  return (
    <DivForm>
      <form>
        <DivField>
          <InputField
            name="un"
            control={control}
            label="un"
            defaultValue={unDefault}
          />
        </DivField>
        {pwUpdateShow && (
          <DivField>
            <InputField
              name="pw"
              control={control}
              label="password"
              defaultValue=""
              type="password"
            />
          </DivField>
        )}

        <DivField>
          <UserTypeSelect
            filedName="uType"
            control={control}
            defaultValue={uTypeDefault}
            label="user type"
          />
        </DivField>
        <DivField>
          <InputField
            name="email"
            control={control}
            label="email"
            defaultValue={emailDefault}
          />
        </DivField>
        <DivField>
          <InputField
            name="firstName"
            control={control}
            label="first name"
            defaultValue={firstNameDefault}
          />
        </DivField>
        <DivField>
          <InputField
            name="lastName"
            control={control}
            label="last name"
            defaultValue={lastNameDefault}
          />
        </DivField>

        <DivNav>
          <Button onClick={handleSubmit(onSubmit)} type="submit">
            Submit
          </Button>
          <Button onClick={() => reset()} variant={"outlined"}>
            Reset
          </Button>
        </DivNav>
      </form>
    </DivForm>
  );
};

const DivForm = styled.div`
  margin-top: 0.75rem;
`;

const DivField = styled.div`
  margin-top: 1rem;
`;

const DivNav = styled.div`
  margin-top: 2rem;
`;

const ButtonPwUpdate = styled(Button)`
  margin-top: 1rem;
`;

export default UserForm;
