import React, { useState } from "react";
import styled from "styled-components";
import UpdateStringSingleNav from "./UpdateStringSingleNav";
import { NavMode } from "type/navigation/nav_mode.type";
import { NAV_MODE } from "constant/navigation";
import UpdateStringRead from "./UpdateStringRead";
import UpdateStringEdit from "./UpdateStringEdit";
import { isEditMode } from "common/nav/nav_mode";

export type UpdateStringProps = {
  initialVal: string;
  ind: number;
  removeTextHandle: (index: number) => any;
  updateTextHandle: ((index: number, text: string) => any) | null;
  id?: string;
};

function UpdateString({
  initialVal,
  ind,
  removeTextHandle,
  updateTextHandle,
  id,
}: UpdateStringProps) {
  const [mode, setMode] = useState<NavMode>(NAV_MODE.DEFAULT);

  const onEditHandle = () => {
    setMode(NAV_MODE.EDIT);
  };

  const onCancelHandle = () => {
    setMode(NAV_MODE.READ);
  };

  const onDeleteHandle = () => {
    removeTextHandle(ind);
  };

  const fieldEditConfirm = (text: string) => {
    console.log("fieldEditConfirm", ind, text);
    if (updateTextHandle) {
      updateTextHandle(ind, text);
      setMode(NAV_MODE.READ);
    }
  };

  return (
    <>
      {!isEditMode(mode) && <UpdateStringRead initialVal={initialVal} />}
      {isEditMode(mode) && (
        <UpdateStringEdit
          initialVal={initialVal}
          fieldEditConfirm={fieldEditConfirm}
          id={id ?? undefined}
        />
      )}
      <div>
        <UpdateStringSingleNav
          onDeleteHandle={onDeleteHandle}
          onEditHandle={
            !isEditMode(mode) && updateTextHandle ? onEditHandle : null
          }
          onCancelHandle={isEditMode(mode) ? onCancelHandle : null}
        />
      </div>
    </>
  );
}

export default UpdateString;
