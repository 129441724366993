import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import { QUERY_KEYS } from "constant/react_query";
import useConfirm from "common/modal/ConfirmDialog";
import { useUserDelete } from "hooks/asocijator/user/useUserDelete";
import { DELETE_USER_CONFIRM_MESSAGE } from "./UserDeleteNav.const";

type UserDeleteNavProps = {
  userId: string;
  deleteHandle?: Function;
  deleteConfirmMessage?: string;
};

const UserDeleteNav = ({
  userId,
  deleteHandle,
  deleteConfirmMessage,
}: UserDeleteNavProps) => {
  const { userDeleteMutate } = useUserDelete({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_USER_SEARCH],
  });

  const actionConfirm = useConfirm();

  const deleteClickHandle = async () => {
    const confirmMessage: string =
      deleteConfirmMessage ?? DELETE_USER_CONFIRM_MESSAGE;
    const check = await actionConfirm({
      title: "User",
      message: confirmMessage,
    });
    if (check) {
      if (!deleteHandle) {
        userDeleteMutate(userId);
      } else {
        deleteHandle(userId);
      }
    }
  };

  return <DeleteIconNav onClick={deleteClickHandle} />;
};

const DeleteIconNav = styled(DeleteIcon)`
  margin-left: 0rem;
`;

export default UserDeleteNav;
