export const characterLinkSingle = (
  characterId: string,
  isEditMode: boolean = false
): string => {
  let link = `/character/${characterId}`;
  if (isEditMode) {
    link += "?mode=edit";
  }
  return link;
};
