import React from "react";
import { Divider, List, Toolbar } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import { navArraysGet } from "common/nav/nav_common";
import {
  GENERAL_NAV_ELEMENTS,
  LIST_MAIN_NAV_ELEMENTS,
  NEW_ITEM_NAV_ELEMENTS,
} from "../MainNavigation.constant";
import NavigationGroup from "../NavigationGroup";
import LogoutNav from "components/navigation/LogoutNav";
import { useIsAdmin } from "hooks/auth/auth_current";
import { NavElement } from "type/navigation/main_navigation.type";
import TopicWorkNav from "../TopicWorkNav/TopicWorkNav";
import MainNavItem from "../MainNavItem";

const LogoutNavDiv = styled.div`
  margin-left: 1rem;
  margin-top: 1rem;
`;

type MainNavigationDrawerProps = {
  showMenu: boolean;
};

const MainNavigationDrawer = ({ showMenu }: MainNavigationDrawerProps) => {
  const isAdmin: boolean = useIsAdmin();

  const [
    newItemNavElements,
    listNavElements,
    generalNavElements,
  ]: NavElement[][] = navArraysGet(
    [NEW_ITEM_NAV_ELEMENTS, LIST_MAIN_NAV_ELEMENTS, GENERAL_NAV_ELEMENTS],
    isAdmin
  );

  return showMenu ? (
    <div>
      <Toolbar />
      <Divider />
      {newItemNavElements.length > 0 && (
        <>
          <List>
            <MainNavItem
              navElement={{ path: "/", title: "Start" }}
              navImage={<HomeIcon />}
            />
            <Divider />
            {newItemNavElements.map((navElement: NavElement, ind: number) => (
              <MainNavItem
                navElement={navElement}
                navImage={<AddIcon />}
                key={`main_nav_item_${ind}`}
              />
            ))}
          </List>
          <Divider />
        </>
      )}
      <NavigationGroup
        navElements={listNavElements}
        dividerAdd={true}
        key={`navigation_group_0`}
      />
      <NavigationGroup
        navElements={generalNavElements}
        dividerAdd={true}
        key={`navigation_group_1`}
      />
      <TopicWorkNav />
      <LogoutNavDiv>
        <LogoutNav />
      </LogoutNavDiv>
    </div>
  ) : null;
};

export default MainNavigationDrawer;
