import { TopicResult } from "common/topic/dto/TopicResult";
import { CopyDestinationT } from "./CharCopyDestination.type";

export function charCopyDestinationInitial(): CopyDestinationT {
  return {
    show: false,
    topicId: "",
    topicName: "",
  };
}

export function chooseNavTopicGet(id: string, name: string): TopicResult {
  const topic: TopicResult = { id, name, description: "" };
  return topic;
}
