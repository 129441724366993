import { useQuery } from "@tanstack/react-query";
import {
  TopicSearchParams,
  topicSearch,
} from "common/api/asocijator/topic/asocijator_topic";
import { QUERY_KEYS } from "constant/react_query";

export const useTopicSearch = (searchParams?: TopicSearchParams) => {
  const { data: topic } = useQuery(
    [QUERY_KEYS.ASOCIJATOR_TOPIC_SEARCH, searchParams],
    async () => {
      const result = await topicSearch(searchParams);
      return result;
    },
    { staleTime: Infinity }
  );
  const result = typeof topic !== "undefined" && topic !== "" ? topic : null;
  return result;
};
