import { fileExtensionGet } from "common/util/file/file_common";
import { CharacterFile } from "../dto/CharacterFile";
import { CHARACTER_IMAGE_SHOW_EXTENSION } from "constant/character/character.constant";

export function characterFilePathImageCheck(filePath: string): boolean {
  const ext: string = fileExtensionGet(filePath);
  const isImage: boolean = CHARACTER_IMAGE_SHOW_EXTENSION.includes(ext);

  return isImage;
}

export function characterFileIsImage(characterFile: CharacterFile): boolean {
  const isImage: boolean = characterFilePathImageCheck(characterFile.path);

  return isImage;
}
