import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTopicGet } from "hooks/asocijator/topic/useTopicGet";
import TopicEdit from "./TopicEdit";
import TopicRead from "./TopicRead";

const TopicComponent = () => {
  const { topicId } = useParams();
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState<string>("");
  const topic = useTopicGet(topicId as string);
  useEffect(() => {
    const paramsMode = searchParams.get("mode") ?? "";
    if (paramsMode !== mode) {
      setMode(paramsMode);
    }
  }, [searchParams, topic]);

  if (!topic) {
    return null;
  }

  return (
    <>
      {mode !== "edit" && <TopicRead topic={topic} />}
      {mode === "edit" && <TopicEdit topic={topic} />}
    </>
  );
};

export default TopicComponent;
