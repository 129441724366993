import { obDefaultValGet } from "common/common";
import { MessageCheck } from "type/common/common.type";
import { CharCopySubmit } from "./CharCopy.type";

export function charCopyPayloadCheck(
  payloadData: Partial<CharCopySubmit>
): MessageCheck {
  let result: MessageCheck = {
    check: true,
    message: "",
  };
  const topicSourceId = obDefaultValGet(payloadData, "topicSourceId", "");
  const copyType = obDefaultValGet(payloadData, "copyType", "");
  const copyDestinationType = obDefaultValGet(
    payloadData,
    "copyDestinationType",
    ""
  );
  const topicNewName = obDefaultValGet(payloadData, "topicNewName", "");
  const topicDestinationId = obDefaultValGet(
    payloadData,
    "topicDestinationId",
    ""
  );

  if (topicSourceId === "" || copyType === "" || copyDestinationType === "") {
    result.check = false;
    result.message +=
      "Topic source, copy type and destination type needed to be populated.";
  }

  if (topicNewName === "" && topicDestinationId === "") {
    result.check = false;
    result.message +=
      "One of topic new name and topic destination needed to be populated.";
  }

  return result;
}
