import { useMutation } from "@tanstack/react-query";
import { characterCopy } from "common/api/asocijator/character/character_edit_api";
import { CharacterCopyParams } from "common/api/asocijator/character/type/character_api.type";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseCharacterCopyParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useCharacterCopy = (hookParams?: UseCharacterCopyParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: characterCopyMutate } = useMutation({
    mutationFn: async (characterCopyParams: CharacterCopyParams) =>
      await characterCopy(characterCopyParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { characterCopyMutate };
};
