import styled from "styled-components";

export const DivMain = styled.div<{ overflowHidden?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0%;
  margin-top: 0px;
  height: 100%;
  border: 0px solid black;
  overflow: ${(props: any) => (props.overflowHidden ? "hidden" : "auto")};
`;
