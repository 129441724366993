import { CharacterFile } from "common/character/dto/CharacterFile";
import CharacterImage from "components/common/character/file/CharacterImage";
import { characterFileIsImage } from "common/character/file/character_image";
import CharacterFileClickActLink from "../../CharacterFileClickActLink";
import CharacterFileReadNav from "./CharacterFileReadNav";

type CharacterFileReadItemProps = {
  characterFile: CharacterFile;
  disableLinkDownload?: boolean;
};

const CharacterFileReadItem = ({
  characterFile,
  disableLinkDownload,
}: CharacterFileReadItemProps) => {
  return (
    <div>
      <CharacterFileReadNav
        characterFile={characterFile}
        disableLinkDownload={disableLinkDownload}
      />
      {characterFileIsImage(characterFile) &&
        ((!disableLinkDownload && (
          <CharacterFileClickActLink characterFilePath={characterFile.path}>
            <CharacterImage characterFile={characterFile} />
          </CharacterFileClickActLink>
        )) ||
          (disableLinkDownload && (
            <CharacterImage characterFile={characterFile} />
          )))}
    </div>
  );
};

export default CharacterFileReadItem;
