import { axiosAuth } from "common/api/axios/auth_axios";

export type AuthResponse = {
  uId: string;
  uType: string;
};

export type AuthLoginParams = {
  un: string;
  pw: string;
};

export const authCurrentGet = async (): Promise<AuthResponse> => {
  let authResponse: AuthResponse = { uId: "", uType: "" };
  const { data } = await axiosAuth.get("auth/current", {
    withCredentials: true,
  });
  authResponse = { ...data };

  return authResponse;
};

export const authLogin = async (
  loginParams: AuthLoginParams
): Promise<boolean> => {
  let success: boolean = false;
  let response: any;
  try {
    response = await axiosAuth.post("auth/local/login", loginParams, {
      withCredentials: true,
    });
  } catch (error) {}
  if (response && response.status === 200) {
    success = true;
  }

  return success;
};

export const authLogout = async (): Promise<boolean> => {
  let success: boolean = false;
  let response: any;
  try {
    response = await axiosAuth.post(
      "auth/logout",
      {},
      { withCredentials: true }
    );
  } catch (error) {}
  if (response && response.status === 200) {
    success = true;
  }

  return success;
};
