import { CharacterResult } from "./dto/CharacterResult";

export const characterFormDefaultValuesGet = (
  character?: CharacterResult
): CharacterResult => {
  if (character) {
    return {
      id: character.id,
      letter: character.letter,
      meaning: character.meaning,
      pronounce: character.pronounce,
      pronounceAdd: character.pronounceAdd,
      examples: character.examples,
      file: character.file,
      relate: character.relate,
      answer: character.answer,
      topicId: character.topicId,
    };
  } else {
    return {
      id: "",
      letter: "",
      meaning: "",
      pronounce: "",
      pronounceAdd: [],
      examples: [],
      file: [],
      relate: {},
      answer: {},
      topicId: "",
    };
  }
};
