export const userLinkSingle = (
  userId: string,
  isEditMode: boolean = false
): string => {
  let link = `/user/${userId}`;
  if (isEditMode) {
    link += "?mode=edit";
  }
  return link;
};
