import { TopicAnswerPlayCharacterParams } from "common/api/asocijator/topic/asocijator_topic_answer_play";
import { AnswerPlayCharContextValue } from "./AnswerPlayChar.type";

export const answerPlayCharContextValueDefault =
  (): AnswerPlayCharContextValue => {
    return {
      playChar: null,
      playCharId: "",
      charAnswerAdd: () => null,
      charAnswerYesAdd: () => null,
      charAnswerNoAdd: () => null,
      nextChar: () => null,
      nextAnswerNoAdd: () => null,
    };
  };

export function nextPlayCharParamsGet(
  playChar: any,
  playCharParams: TopicAnswerPlayCharacterParams,
  topicId: string
): TopicAnswerPlayCharacterParams {
  const nextPlayCharParams: TopicAnswerPlayCharacterParams = playChar
    ? {
        ...playCharParams,
        characterId: playChar.id,
      }
    : {
        topicId,
      };

  return nextPlayCharParams;
}

export function playCharIdGet(playChar: any): string {
  const id: string = playChar !== null ? playChar.id : "";
  return id;
}

export function answerCheck(
  playChar: any,
  pronounce: string,
  meaning: string
): boolean {
  let res: boolean = false;
  if (playChar.pronounce === pronounce && playChar.meaning === meaning) {
    res = true;
  }

  return res;
}
