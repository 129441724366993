import { axiosAsocijator } from "common/api/axios/asocijator_axios";
import { CharacterSearchParams } from "./type/character_api.type";

const characterResponseFormat = (character: any): any => {
  const result = { ...character };

  return result;
};

export const characterGet = async (id: string): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosAsocijator.get(`character/${id}`);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const characterSearch = async (
  searchParams?: CharacterSearchParams
): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosAsocijator.post(
      `character/search`,
      searchParams ?? {}
    );
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
    result.items = result.items.map((item: any) =>
      characterResponseFormat(item)
    );
  }

  return result;
};
