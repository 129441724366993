import { TopicResult } from "common/topic/dto/TopicResult";
import TopicLiteItem from "../TopicLiteList/TopicLiteItem";

type TopicSelectValueProps = {
  topicSelection: TopicResult;
  topicSelectionDeleteHandle: Function;
};

const TopicSelection = ({
  topicSelection,
  topicSelectionDeleteHandle,
}: TopicSelectValueProps) => {
  return (
    <TopicLiteItem
      topic={topicSelection}
      deleteClickHandle={topicSelectionDeleteHandle}
    />
  );
};

export default TopicSelection;
