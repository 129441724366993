import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { UserResult } from "common/user/dto/UserResult";
import { NavLink } from "react-router-dom";
import { userLinkSingle } from "common/user/user_link_common";
import { Button } from "@mui/material";
import UserDeleteNav from "./UserDeleteNav";

type UserSingleNavProps = {
  user: UserResult;
  hideEditNav?: boolean;
  hideReadNav?: boolean;
  hideDeleteNav?: boolean;
  addHandle?: Function;
  deleteHandle?: Function;
  deleteConfirmMessage?: string;
};

const UserSingleNav = ({
  user,
  hideEditNav,
  hideReadNav,
  hideDeleteNav,
  addHandle,
  deleteHandle,
  deleteConfirmMessage,
}: UserSingleNavProps) => {
  return (
    <DivNav>
      {!hideDeleteNav && (
        <UserDeleteNav
          userId={user.id}
          deleteHandle={deleteHandle}
          deleteConfirmMessage={deleteConfirmMessage}
        />
      )}
      {!hideEditNav && (
        <NavLink to={userLinkSingle(user.id, true)}>
          <EditIcon />
        </NavLink>
      )}
      {!hideReadNav && (
        <NavLink to={userLinkSingle(user.id)}>
          <ReadMoreIcon />
        </NavLink>
      )}
      {addHandle && (
        <Button onClick={() => addHandle(user.id)} variant={"outlined"}>
          Add
        </Button>
      )}
    </DivNav>
  );
};

const DivNav = styled.div`
  margin-top: 0.75rem;
  svg {
    margin-left: 1rem;
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
  &&& > svg:first-of-type {
    margin-left: 0;
  }
`;

export default UserSingleNav;
