import { UserResult } from "common/user/dto/UserResult";
import styled from "styled-components";
import UserSingleNav from "components/common/user/UserSingleNav";
import { Divider } from "@mui/material";
import { userLinkSingle } from "common/user/user_link_common";
import NavLinkUndecorated from "components/common/style/link/NavLinkUndecorated";

type UserItemProps = {
  user: UserResult;
  addHandle?: Function;
};

const UserItem = ({ user, addHandle }: UserItemProps) => {
  return (
    <Div>
      <Divider />
      <Div>
        <NavLinkUndecorated to={userLinkSingle(user.id)}>
          <div>{user.un}</div>
          <div>{user.email} </div>
        </NavLinkUndecorated>
        <UserSingleNav user={user} addHandle={addHandle} />
      </Div>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 2rem;
  line-height: 1.25rem;
`;

export default UserItem;
