import styled from "styled-components";
import Button from "@mui/material/Button";
import { defaultValueGet } from "common/parameters_common";
import InputField from "components/UI/InputField";

type TopicFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  reset: Function;
  defaultValues?: any;
};

const TopicForm = ({
  onSubmit,
  handleSubmit,
  reset,
  defaultValues,
  control,
}: TopicFormProps) => {
  const nameDefault = defaultValueGet(defaultValues, "name", "");
  const descriptionDefault = defaultValueGet(defaultValues, "description", "");

  return (
    <DivForm>
      <form>
        <DivField>
          <InputField
            name="name"
            control={control}
            label="name"
            defaultValue={nameDefault}
          />
        </DivField>
        <DivField>
          <InputField
            name="description"
            control={control}
            label="description"
            defaultValue={descriptionDefault}
          />
        </DivField>

        <DivNav>
          <Button onClick={handleSubmit(onSubmit)} type="submit">
            Submit
          </Button>
          <Button onClick={() => reset()} variant={"outlined"}>
            Reset
          </Button>
        </DivNav>
      </form>
    </DivForm>
  );
};

const DivForm = styled.div`
  margin-top: 0.75rem;
`;

const DivField = styled.div`
  margin-top: 1rem;
`;

const DivNav = styled.div`
  margin-top: 2rem;
`;

export default TopicForm;
