export const PRONOUNCE_CONVERT: { [key: string]: string } = {
  â: "ǎ",
  ä: "ā",
  ê: "ě",
  ë: "ē",
  î: "ǐ",
  ï: "ī",
  ô: "ǒ",
  ö: "ō",
  û: "ǔ",
  ü: "ū",
};
