import styled from "styled-components";
import { CharacterFile } from "common/character/dto/CharacterFile";
import { useEffect, useState } from "react";
import { imageTitleGet, characterImageSrc } from "./CharacterImage.logic";

type CharacterImageProps = {
  characterFile: CharacterFile;
};

const CharacterImage = ({ characterFile }: CharacterImageProps) => {
  const [imageSrc, setImageSrc] = useState<any>(null);
  useEffect(() => {
    const imagePath: string = characterFile.small?.path ?? characterFile.path;
    characterImageSrc(imagePath).then((imSrc) => {
      setImageSrc(imSrc);
    });
  }, []);

  const title: string = imageTitleGet(characterFile);

  return <div>{imageSrc && <Img src={imageSrc} title={title} />}</div>;
};

const Img = styled.img`
  margin-top: 0.5rem;
  max-height: 200px;
`;

export default CharacterImage;
