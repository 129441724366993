export default class Point {
  left = 0;
  top = 0;

  constructor(left: number = 0, top: number = 0) {
    this.left = left;
    this.top = top;
  }

  plusNums(left: number, top: number): Point {
    return new Point(this.left + left, this.top + top);
  }

  minusNums(left: number, top: number): Point {
    return new Point(this.left - left, this.top - top);
  }

  plus(P: Point): Point {
    return this.plusNums(P.left, P.top);
  }

  minus(P: Point): Point {
    return this.minusNums(P.left, P.top);
  }
}
