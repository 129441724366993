import React, { createContext, useContext, useEffect, useState } from "react";
import { TopicResult } from "common/topic/dto/TopicResult";

type TopicWorkValue = {
  topicWork: TopicResult | null;
  topicWorkSet: Function;
  topicWorkSetById: Function;
  topicWorkEmpty: Function;
};

function topicWorkInitialGet(): TopicWorkValue {
  return {
    topicWork: null,
    topicWorkSet: () => null,
    topicWorkSetById: () => null,
    topicWorkEmpty: () => null,
  };
}

const TopicWork = createContext<TopicWorkValue>(topicWorkInitialGet());

type TopicWorkProviderProps = { children: React.ReactNode };

export function TopicWorkProvider({ children }: TopicWorkProviderProps) {
  const [topicWork, topicWorkSet] = useState<TopicResult | null>(null);

  useEffect(() => {
    if (topicWork === null) {
      const asocijatorTopicWork = localStorage.getItem("asocijatorTopicWork");
      if (asocijatorTopicWork) {
        topicWorkSet(JSON.parse(asocijatorTopicWork));
      }
    }
    if (topicWork !== null) {
      const asocijatorTopicWork = localStorage.getItem("asocijatorTopicWork");
      if (
        !asocijatorTopicWork === null ||
        asocijatorTopicWork !== JSON.stringify(topicWork)
      ) {
        localStorage.setItem("asocijatorTopicWork", JSON.stringify(topicWork));
      }
    }
  }, [topicWork]);

  const topicWorkEmpty = () => {
    topicWorkSet(null);
  };

  const topicWorkSetById = (topicId: string) => {
    topicWorkSet(null);
  };

  return (
    <TopicWork.Provider
      value={{ topicWork, topicWorkSet, topicWorkEmpty, topicWorkSetById }}
    >
      {children}
    </TopicWork.Provider>
  );
}

export default function useTopicWork() {
  return useContext(TopicWork);
}
