import { ConfirmDialogProvider } from "common/modal/ConfirmDialog";
import { KeyboardEventsProvider } from "common/nav/provider/KeyboardEvents/KeyboardEvents";
import { TopicWorkProvider } from "common/topic/provider/TopicWork";
import React from "react";

type AsocijatorCommonProps = { children: React.ReactNode };

export default function AsocijatorCommon({ children }: AsocijatorCommonProps) {
  return (
    <ConfirmDialogProvider>
      <TopicWorkProvider>
        <KeyboardEventsProvider>{children}</KeyboardEventsProvider>
      </TopicWorkProvider>
    </ConfirmDialogProvider>
  );
}
