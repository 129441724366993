import React, { useEffect } from "react";
import styled from "styled-components";
import UserSingleNav from "components/common/user/UserSingleNav/UserSingleNav";
import { UserResult } from "common/user/dto/UserResult";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";

type UserReadProps = {
  user: UserResult;
};

const UserRead = ({ user }: UserReadProps) => {
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([QUERY_KEYS.ASOCIJATOR_USER_GET]);
  }, [invalidateQuery]);

  return (
    <div>
      <div>{user.un}</div>
      <div>{user.email}</div>
      <DivNav>
        <UserSingleNav user={user} hideReadNav={true} />
      </DivNav>
    </div>
  );
};

const DivNav = styled.div`
  margin-top: 1.5rem;
`;

export default UserRead;
