import { CharacterResult } from "common/character/dto/CharacterResult";
import styled from "styled-components";
import CharacterSingleNav from "components/common/character/CharacterSingleNav";
import { Divider } from "@mui/material";
import { characterLinkSingle } from "common/character/character_link_common";
import NavLinkUndecorated from "components/common/style/link/NavLinkUndecorated";
import CharacterDescription from "components/common/character/CharacterDescription";
import { DivMarginTop } from "components/common/style/margin/DivMarginTop";

type CharacterItemProps = {
  character: CharacterResult;
};

const CharacterItem = ({ character }: CharacterItemProps) => {
  return (
    <CharacterDiv>
      <Divider />
      <Div>
        <DivMarginTop mt={2}>
          <NavLinkUndecorated to={characterLinkSingle(character.id)}>
            <LetterDiv>{character.letter}</LetterDiv>
          </NavLinkUndecorated>
        </DivMarginTop>
        <DivMarginTop mt={1}>
          <CharacterDescription character={character} />
        </DivMarginTop>
        <CharacterSingleNavDiv>
          <CharacterSingleNav character={character} />
        </CharacterSingleNavDiv>
      </Div>
    </CharacterDiv>
  );
};

const CharacterDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  line-height: 1.25rem;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  line-height: 1.25rem;
`;

const LetterDiv = styled.div`
  font-size: 4rem;
  line-height: 4rem;
`;

const CharacterSingleNavDiv = styled.div`
  margin-top: 1rem;
`;

export default CharacterItem;
