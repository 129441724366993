import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import useConfirm from "common/modal/ConfirmDialog";
import { fileNameGet } from "common/util/file/file_common";
import {
  CHARACTER_FILE_DELETE_CONFIRM_TEXT,
  CHARACTER_FILE_DELETE_CONFIRM_TITLE,
} from "components/common/character/CharacterSingleNav/CharacterSingleNav.const";
import { CharacterFile } from "common/character/dto/CharacterFile";

type CharacterFileItemProps = {
  characterFile: CharacterFile;
  deleteHandle: Function;
  ind: number;
};

const CharacterFileItem = ({
  characterFile,
  deleteHandle,
  ind,
}: CharacterFileItemProps) => {
  const actionConfirm = useConfirm();

  const deleteClickHandle = async () => {
    let check = false;
    try {
      check = await actionConfirm({
        title: CHARACTER_FILE_DELETE_CONFIRM_TITLE,
        character: CHARACTER_FILE_DELETE_CONFIRM_TEXT,
      });
    } catch (err) {
      console.log("confirm err");
    }
    if (check) {
      deleteHandle(ind);
    }
  };

  return (
    <Div>
      <div>{fileNameGet(characterFile.path)}</div>
      <DivDelete>
        <DeleteIconNav onClick={deleteClickHandle} />
      </DivDelete>
    </Div>
  );
};

const DeleteIconNav = styled(DeleteIcon)`
  cursor: pointer;
`;

const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const DivDelete = styled.div`
  margin-left: 1rem;
`;

export default CharacterFileItem;
