import { boolean } from "yup";

export function obDefaultValGet(ob: any, key: string, defaultVal: any = "") {
  let val = defaultVal;
  if (ob !== null) {
    val = ob.hasOwnProperty(key) ? ob[key] : defaultVal;
  }
  return val;
}

export const envTypeDomainGet = (name: string) => {
  let envType = "";
  if (
    typeof process !== "undefined" &&
    typeof process.env !== "undefined" &&
    typeof process.env.NODE_ENV !== "undefined" &&
    process.env.NODE_ENV === "test"
  ) {
    envType = "test";
  } else {
    const loc = window.location.href + "";
    if (loc.includes("localhost")) {
      envType = "dev";
    } else if (loc.includes("t." + name)) {
      envType = "local_address";
    } else if (loc.includes(name)) {
      envType = "production";
    }
  }
  return envType;
};

export const envTypeGet = () => {
  return envTypeDomainGet("asocijator");
};

export type IntervalConditionFunction = () => boolean;

export async function intervalCheck(
  condF: IntervalConditionFunction,
  waitMsMax: number = 2 * 1000
): Promise<boolean> {
  const result: Promise<boolean> = new Promise((resolve) => {
    const start = new Date();
    const intervalId: any = setInterval(() => {
      const elsCh: boolean = condF();

      const timeCurrent = new Date();
      if (elsCh) {
        resolve(true);
        clearInterval(intervalId);
      } else if (timeCurrent.getTime() > start.getTime() + waitMsMax) {
        clearInterval(intervalId);
        resolve(false);
      }
    }, 100);
  });

  return result;
}

export const arrAddUnique = (arr1: any[], val: any): any[] => {
  // add val - element or arr to arr1 without duplicates
  // change arr1 in place
  if (Array.isArray(val)) {
    for (let i = 0; i < val.length; i++) {
      const el = val[i];
      if (!arr1.includes(el)) {
        arr1.push(el);
      }
    }
  } else {
    if (!arr1.includes(val)) {
      arr1.push(val);
    }
  }
  return arr1;
};

export function arrUnique<T>(arr: Array<T>): Array<T> {
  const result = arr.filter(function (v, i, self) {
    // It returns the index of the first
    // instance of each value
    return i == self.indexOf(v);
  });

  return result;
}
