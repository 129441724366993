import {
  CharacterBaseResult,
  CharacterResult,
} from "common/character/dto/CharacterResult";
import StringList from "components/common/list/StringList";
import styled from "styled-components";
import { charsToLetters } from "./CharParseImportList.logic";

export type CharacterRes = CharacterBaseResult | CharacterResult;

type CharParseImportListProps = {
  label: string;
  characters: CharacterRes[];
};

const CharParseImportList = ({
  label,
  characters,
}: CharParseImportListProps) => {
  return characters.length > 0 ? (
    <Div>
      <div>{label}</div>
      <div>
        <StringList results={charsToLetters(characters)} />
      </div>
    </Div>
  ) : null;
};

const Div = styled.div`
  margin-top: 1rem;
`;

export default CharParseImportList;
