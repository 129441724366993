import styled from "styled-components";
import { Control, FieldValues } from "react-hook-form";
import SelectField from "components/UI/SelectField";
import { USER_TYPE_OPTIONS } from "constant/user";

type UserTypeSelectProps = {
  filedName: string;
  defaultValue: string;
  control: Control<FieldValues, any>;
  label: string;
};

const UserTypeSelect = ({
  filedName,
  defaultValue,
  control,
  label,
}: UserTypeSelectProps) => {
  return (
    <SelectField
      label={label}
      name={filedName}
      control={control as unknown as Control<FieldValues, any>}
      options={USER_TYPE_OPTIONS}
      defaultValue={defaultValue}
      onSelect={(val: any) => {}}
    />
  );
};

export default UserTypeSelect;
