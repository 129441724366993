import { useMutation } from "@tanstack/react-query";
import {
  AnswerAddParams,
  answerAdd,
} from "common/api/asocijator/asocijator_answer";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type useAnswerAddParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useAnswerAdd = (hookParams?: useAnswerAddParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: answerAddMutate } = useMutation({
    mutationFn: async (answerAddParams: AnswerAddParams) =>
      await answerAdd(answerAddParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { answerAddMutate };
};
