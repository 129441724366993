export const topicLinkSingle = (
  topicId: string,
  isEditMode: boolean = false
): string => {
  let link = `/topic/${topicId}`;
  if (isEditMode) {
    link += "?mode=edit";
  }
  return link;
};
