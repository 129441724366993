import useAnswerPlayChar from "common/answer/provider/AnswerPlayChar";
import CharacterDescription from "components/common/character/CharacterDescription";

const CharacterPlayAnswer = () => {
  const { playChar } = useAnswerPlayChar();

  return (
    <>{playChar !== null && <CharacterDescription character={playChar} />}</>
  );
};

export default CharacterPlayAnswer;
