import React, { MouseEventHandler } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import NavItemSmallScreen from "./NavItemSmallScreen";
import { NAV_ELEMENTS } from "constant/navigation";
import { NavElement } from "type/navigation/main_navigation.type";
import TopicWorkNav from "../TopicWorkNav";

type NavItemProps = {
  navItem: NavElement;
};

type NavMenuSmallScreenProps = {
  showMenu: boolean;
  menuShowClickHandle: MouseEventHandler<HTMLButtonElement>;
  navItemClickHandle: Function;
};

const NavMenuSmallScreen = ({
  showMenu,
  menuShowClickHandle,
  navItemClickHandle,
}: NavMenuSmallScreenProps) => {
  const NavItem = ({ navItem }: NavItemProps) => {
    return (
      <NavItemSmallScreen navItem={navItem} clickHandle={navItemClickHandle} />
    );
  };

  return (
    <DivButtonMenu>
      <DivButton>
        <IconButton aria-label="Example" onClick={menuShowClickHandle}>
          <FontAwesomeIcon icon={faBars as any} />
        </IconButton>
      </DivButton>
      {showMenu && (
        <>
          <DivMenu>
            <NavItem navItem={NAV_ELEMENTS.START} />
            <NavItem navItem={NAV_ELEMENTS.UPUTSTVO} />
            <NavItem
              navItem={{
                ...NAV_ELEMENTS.TOPIC_NEW,
                title: NAV_ELEMENTS.TOPIC_NEW.title + " new",
              }}
            />
            <NavItem navItem={NAV_ELEMENTS.TOPIC_LIST} />
          </DivMenu>
          <TopicWorkNav />
        </>
      )}
    </DivButtonMenu>
  );
};

const DivButtonMenu = styled.div.attrs({ className: "screen-small" })`
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

const DivMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
`;

const DivButton = styled.div`
  text-align: right;
`;

export default NavMenuSmallScreen;
