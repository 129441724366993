export function answerCheck(
  character: any,
  pronounce: string,
  meaning: string
): boolean {
  let res: boolean = false;
  if (character.pronounce === pronounce && character.meaning === meaning) {
    res = true;
  }

  return res;
}

export function answerFormInitialShowNavText(
  answerFormInitialShow: boolean
): string {
  let res: string = "";
  if (!answerFormInitialShow) {
    res = "show answer form";
  } else {
    res = "hide answer form";
  }

  return res;
}
