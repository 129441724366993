import { CharacterResult } from "common/character/dto/CharacterResult";
import {
  CharExportFieldsTypes,
  EXPORT_EXAMPLE_HEADER,
  EXPORT_EXCEL_HEADER,
} from "./CharExportNav.const";
import { arrAddUnique } from "common/common";

export const topicCharacterExportSearchParams = (topicId: string): any => {
  return {
    limit: 0,
    skip: 0,
    topicId,
  };
};

export const excelDataGet = (
  characterListResult: any,
  charExportFieldsType: CharExportFieldsTypes
): any => {
  let dataRows: any[] = [];
  let data: any = [];
  if (charExportFieldsType === CharExportFieldsTypes.all) {
    dataRows = characterListResult.items.map((character: CharacterResult) => {
      return [
        character.letter,
        character.pronounce,
        character.meaning,
        character.pronounceAdd.join(","),
        character.examples.join(";"),
        character.relate.meaning.join(","),
        character.relate.pronounce.join(","),
        character.relate.shape.join(","),
      ];
    });
    data = [EXPORT_EXCEL_HEADER, ...dataRows];
  } else if (charExportFieldsType === CharExportFieldsTypes.examples) {
    const totalExamples: any[] = [];
    for (let i = 0; i < characterListResult.items.length; i++) {
      const examples = characterListResult.items[i].examples;
      arrAddUnique(totalExamples, examples);
    }
    dataRows = totalExamples.map((example: string) => {
      return [example];
    });
    data = [EXPORT_EXAMPLE_HEADER, ...dataRows];
  }

  return data;
};
