import { useQuery } from "@tanstack/react-query";
import {
  UserSearchParams,
  userSearch,
} from "common/api/asocijator/asocijator_user";
import { QUERY_KEYS } from "constant/react_query";

export const useUserSearch = (searchParams?: UserSearchParams) => {
  const { data: user } = useQuery(
    [QUERY_KEYS.ASOCIJATOR_USER_SEARCH, searchParams],
    async () => {
      const result = await userSearch(searchParams);
      return result;
    },
    { staleTime: Infinity }
  );
  const result = typeof user !== "undefined" && user !== "" ? user : null;
  return result;
};
