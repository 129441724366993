import styled from "styled-components";
import SelectRefField from "components/UI/SelectField/SelectRefField";
import {
  CHARACTER_IMPORT_TYPE,
  CHARACTER_IMPORT_TYPE_OPTIONS,
} from "constant/character/character_import.constant";
import { ReactRefI } from "type/common/ref.type";
import { SelectFieldOptionValue } from "components/UI/SelectField/SelectField.type";

type CharacterImportTypeSelectProps = {
  defaultValue?: string;
  label?: string;
  selectRef?: ReactRefI<SelectFieldOptionValue>;
  onSelect?: Function;
};

const CharacterImportTypeSelect = ({
  defaultValue,
  label,
  selectRef,
  onSelect,
}: CharacterImportTypeSelectProps) => {
  return (
    <SelectRefField
      label={label}
      options={CHARACTER_IMPORT_TYPE_OPTIONS}
      defaultValue={defaultValue ?? CHARACTER_IMPORT_TYPE.UPSERT_SET_ADD.value}
      {...(onSelect ? { onSelect } : {})}
      {...(selectRef ? { selectRef } : {})}
    />
  );
};

export default CharacterImportTypeSelect;
