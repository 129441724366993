import { CharacterFile } from "common/character/dto/CharacterFile";
import { characterFileIsImage } from "common/character/file/character_image";

export function showCharacterFileName(characterFile: CharacterFile): boolean {
  let show: boolean = true;

  if (characterFileIsImage(characterFile)) {
    show = false;
  }

  return show;
}
