import { CharacterResult } from "common/character/dto/CharacterResult";
import CharacterLiteItem from "components/common/character/CharacterLiteList/CharacterLiteItem";

type CharacterSelectValueProps = {
  characterSelection: CharacterResult;
  characterSelectionDeleteHandle: Function;
};

const CharacterSelection = ({
  characterSelection,
  characterSelectionDeleteHandle,
}: CharacterSelectValueProps) => {
  return (
    <CharacterLiteItem
      character={characterSelection}
      deleteClickHandle={characterSelectionDeleteHandle}
    />
  );
};

export default CharacterSelection;
