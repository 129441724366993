import { Button } from "@mui/material";
import useAddNavigation, { AddNavOptions } from "common/modal/AddNavigation";
import styled from "styled-components";
import { charNavOptionsGet } from "./PlayCharNav.logic";
import useAnswerPlayChar from "common/answer/provider/AnswerPlayChar";

const PlayCharNav = () => {
  const { playCharId } = useAnswerPlayChar();

  const addNavActivate = useAddNavigation();

  const editClickHandle = () => {
    console.log("editClickHandle");

    const addNavOptions: AddNavOptions = charNavOptionsGet(
      playCharId,
      addNavActivate.navClose
    );

    addNavActivate.navOpen(addNavOptions);
  };

  return (
    <Div>
      <Button onClick={editClickHandle} variant="outlined">
        Edit
      </Button>
    </Div>
  );
};

const Div = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50px;
`;

export default PlayCharNav;
