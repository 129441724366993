import { useMutation } from "@tanstack/react-query";
import { AuthLoginParams, authLogin } from "common/auth/auth_api_common";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseAuthLoginParams = {
  invalidateKeys?: string[];
};

export const useAuthLogin = (hookParams?: UseAuthLoginParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: loginMutate, data: loginResponseData } = useMutation({
    mutationFn: async (loginParams: AuthLoginParams) =>
      await authLogin(loginParams),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
    },
  });

  return { loginMutate, loginResponseData };
};
