import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "hooks/hook_util/debounce/useDebounce";
import { useCharacterSearch } from "hooks/asocijator/character/useCharacterSearch";
import { SEARCH_NAMES } from "constant/search/search";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { CharacterResult } from "common/character/dto/CharacterResult";
import {
  searchSelectInitial,
  searchSelectResults,
  showSearchSelect,
} from "./CharacterSearchSelect.logic";
import CharacterSelection from "./CharacterSelection";
import { ReactRefI } from "type/common/ref.type";
import { CharacterSearchParams } from "common/api/asocijator/character/type/character_api.type";

type CharacterSearchSelectProps = {
  excludeIds?: string[];
  onValueSelect?: Function;
  onLetterSelect?: Function;
  label?: string;
  characterSelection?: CharacterResult;
  characterSelectionDeleteHandle?: Function;
  topicId?: string;
};

const getOptionLabel = (option: CharacterResult) =>
  `${option.letter} ${option.pronounce} ${option.meaning}`;

const CharacterSearchSelect = ({
  excludeIds,
  onValueSelect,
  onLetterSelect,
  label,
  characterSelection,
  characterSelectionDeleteHandle,
  topicId,
}: CharacterSearchSelectProps) => {
  const [value, setValue] = useState<CharacterResult | null>(null);
  const searchOptionsTimeRef: ReactRefI<null | number> = useRef<null | number>(
    null
  );
  const [searchParams, setSearchParams] = useState<CharacterSearchParams>(
    searchSelectInitial(topicId)
  );
  const characterListResult = useCharacterSearch(searchParams);
  const handleCharacterSearch = useDebounce((searchText: string) => {
    setSearchParams({
      ...searchParams,
      [SEARCH_NAMES.SEARCH_TEXT]: searchText,
      skip: 0,
    });
  }, 500);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    const currentTime = new Date().getTime();

    if (
      searchOptionsTimeRef.current === null ||
      searchOptionsTimeRef.current < currentTime
    ) {
      invalidateQuery.invalidateQueriesByKeys([
        QUERY_KEYS.ASOCIJATOR_CHARACTER_SEARCH,
      ]);
      searchOptionsTimeRef.current = currentTime;
    }
  }, [searchParams, invalidateQuery]);

  const results: CharacterResult[] = characterListResult
    ? searchSelectResults(characterListResult["items"], excludeIds)
    : [];

  const changeHandle = (event: any, newValue: CharacterResult | null) => {
    if (onValueSelect) {
      onValueSelect(newValue);
      setValue(null);
    }
    if (onLetterSelect && newValue) {
      onLetterSelect(newValue.letter);
      setValue(null);
    }
  };

  const textChangeHandle = (event: any) => {
    handleCharacterSearch(event.target.value);
  };

  return (
    <DivNav>
      {characterSelection &&
        characterSelectionDeleteHandle &&
        showSearchSelect(characterSelection) && (
          <DivSelection>
            <CharacterSelection
              characterSelection={characterSelection}
              characterSelectionDeleteHandle={characterSelectionDeleteHandle}
            />
          </DivSelection>
        )}
      <Autocomplete
        getOptionLabel={getOptionLabel}
        value={value}
        id="combo-box-demo"
        options={results}
        blurOnSelect
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? "Character"}
            onChange={textChangeHandle}
          />
        )}
        onChange={changeHandle}
      />
    </DivNav>
  );
};

const DivNav = styled.div`
  display: flex;
  margin-top: 0rem;
`;

const DivSelection = styled.div`
  margin-right: 1rem;
`;

export default CharacterSearchSelect;
