import { useMutation } from "@tanstack/react-query";
import { characterAdd } from "common/api/asocijator/character/character_edit_api";
import { CharacterAddParams } from "common/api/asocijator/character/type/character_api.type";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseCharacterAddParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useCharacterAdd = (hookParams?: UseCharacterAddParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: characterAddMutate } = useMutation({
    mutationFn: async (characterAddParams: CharacterAddParams) =>
      await characterAdd(characterAddParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { characterAddMutate };
};
