import { FormControlLabel, Checkbox } from "@mui/material";
import styled from "styled-components";
import { ReactRefI } from "type/common/ref.type";
import { ChangeEvent, useEffect, useState } from "react";

type CheckboxFieldProps = {
  label: string;
  valueRef?: ReactRefI<boolean>;
  onChange?: Function;
};

const CheckboxField = ({ label, valueRef, onChange }: CheckboxFieldProps) => {
  const [fieldValue, setFieldValue] = useState<boolean>(
    valueRef?.current as boolean
  );
  useEffect(() => {
    if (valueRef) {
      valueRef.current = fieldValue;
    }
    if (onChange) {
      onChange(fieldValue);
    }
  }, [fieldValue]);

  const changeHandle = (ev: ChangeEvent<HTMLInputElement>, value: boolean) => {
    setFieldValue(value);
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={fieldValue} onChange={changeHandle} />}
      label={label}
    />
  );
};

export default CheckboxField;
