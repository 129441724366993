import { elIdPosDimGet, windowDimGet } from "common/html/element_pos_dim";
import Point from "model/Point";
import { elIdsCheck } from "common/html/element_common";
import { PLAY_CHAR_ID } from "./PlayCharacter.const";
import { PosOpacity } from "../TopicAnswerPlay.type";

export function posOpacityInitial(opacity: number = 1): PosOpacity {
  return {
    start: new Point(),
    opacity,
  };
}

export async function playCharPositionCalc(): Promise<PosOpacity> {
  const res: PosOpacity = posOpacityInitial();
  const ch = await elIdsCheck([PLAY_CHAR_ID]);
  if (ch) {
    const posDim = elIdPosDimGet(PLAY_CHAR_ID);
    if (posDim) {
      const windowDim = windowDimGet();
      res.start.left = (windowDim.width - posDim.width) / 2;
      res.start.top = (windowDim.height - posDim.height) / 2;
    }
  }

  return res;
}
