import styled from "styled-components";
import React from "react";
import { CharacterFile } from "common/character/dto/CharacterFile";
import CharacterFileReadItem from "./CharacterFileReadItem";
import SearchResultLiteList from "components/common/SearchResultLiteList";
import useListMode from "common/provider/ListMode";

type CharacterFileListProps = {
  characterFiles: CharacterFile[];
};

const CharacterFileList = ({ characterFiles }: CharacterFileListProps) => {
  const { listModeIsSelect } = useListMode();

  return (
    <DivList>
      <SearchResultLiteList<CharacterFile>
        renderSingle={(characterFile: CharacterFile, ind: number) => (
          <DivCharacterFileItem key={`character_file_${ind}`}>
            <CharacterFileReadItem
              characterFile={characterFile}
              disableLinkDownload={listModeIsSelect()}
            />
          </DivCharacterFileItem>
        )}
        results={characterFiles}
      />
    </DivList>
  );
};

const DivCharacterFileItem = styled.div`
  margin-top: 0.75rem;
`;

const DivList = styled.div`
  margin-bottom: 0rem;
`;

export default CharacterFileList;
