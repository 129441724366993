import styled from "styled-components";
import { CharacterResult } from "common/character/dto/CharacterResult";
import DeleteIcon from "@mui/icons-material/Close";

type CharacterLiteItemProps = {
  character: CharacterResult;
  clickHandle?: Function;
  deleteClickHandle?: Function;
  selectedClass?: string;
};

const CharacterLiteItem = ({
  character,
  clickHandle,
  deleteClickHandle,
  selectedClass,
}: CharacterLiteItemProps) => {
  return (
    <Div>
      {!clickHandle && <div>{character.letter}</div>}
      {clickHandle && (
        <DivCharacter
          onClick={() => clickHandle(character)}
          className={
            typeof selectedClass === "undefined" || selectedClass === ""
              ? ""
              : selectedClass
          }
        >
          {character.letter}
        </DivCharacter>
      )}
      {deleteClickHandle && (
        <DivDelete>
          <DeleteIcon
            onClick={() => deleteClickHandle(character.id)}
            fontSize="small"
          />
        </DivDelete>
      )}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-top: 1rem;
  line-height: 1.25rem;
`;

const DivCharacter = styled.div`
  cursor: pointer;
`;

const DivDelete = styled.div`
  margin-left: 1rem;
  cursor: pointer;
`;

export default CharacterLiteItem;
