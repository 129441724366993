import { defaultValueGet } from "common/parameters_common";
import UpdateStringList from "components/common/list/UpdateStringList";
import { ReactRefI } from "type/common/ref.type";
import { useEffect } from "react";
import CharacterRelateEdit from "../CharacterRelateEdit";
import { CharacterRelateRefs } from "hooks/asocijator/character/useCharacterRelateRefs";
import { DivField } from "../CharacterForm.style";
import CharacterFileEdit from "../CharacterFileEdit/CharacterFileEdit";

type CharacterFormAddProps = {
  defaultValues?: any;
  pronounceAddRef: ReactRefI<string[]>;
  examplesRef: ReactRefI<string[]>;
  fileEditRef: ReactRefI<any[]>;
  fileRemoveRef: ReactRefI<any[]>;
  characterRelateRefs: CharacterRelateRefs;
  onExamplesUpdate?: Function;
  onCharacterFileUpdate?: Function;
};

const CharacterFormAdd = ({
  defaultValues,
  pronounceAddRef,
  examplesRef,
  fileEditRef,
  fileRemoveRef,
  characterRelateRefs,
  onExamplesUpdate,
  onCharacterFileUpdate,
}: CharacterFormAddProps) => {
  useEffect(() => {
    // console.log("defaultValues", defaultValues);
  }, [defaultValues]);

  return (
    <>
      <DivField>
        <UpdateStringList
          label="examples"
          initialValue={defaultValueGet(defaultValues, "examples", [])}
          resultRef={examplesRef}
          onUpdate={onExamplesUpdate}
          // appendToStart
        />
      </DivField>
      <DivField>
        <CharacterFileEdit
          fileEditRef={fileEditRef}
          fileRemoveRef={fileRemoveRef}
          onCharacterFileUpdate={onCharacterFileUpdate}
        />
      </DivField>
      {/* <DivField>
        <UpdateStringList
          label="pronounce add"
          initialValue={defaultValueGet(defaultValues, "pronounceAdd", [])}
          resultRef={pronounceAddRef}
          appendToStart
        />
      </DivField> */}
      <DivField>
        <CharacterRelateEdit characterRelateRefs={characterRelateRefs} />
      </DivField>
    </>
  );
};

export default CharacterFormAdd;
