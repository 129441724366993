import { useMutation } from "@tanstack/react-query";
import { characterDelete } from "common/api/asocijator/character/character_edit_api";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseCharacterDeleteParams = {
  invalidateKeys?: string[];
};

export const useCharacterDelete = (hookParams?: UseCharacterDeleteParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: characterDeleteMutate } = useMutation({
    mutationFn: async (id: string) => await characterDelete(id),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
    },
  });

  return { characterDeleteMutate };
};
