import { CharacterImportSetsResult } from "common/character/dto/character_import.type";

export function characterImportSetsResultDefault(): CharacterImportSetsResult {
  return {
    topicId: "",
    forImport: [],
    notForImport: [],
    correct: false,
    messages: [],
  };
}
