import React from "react";
import { NavLink } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import styled from "styled-components";
import { NavElement } from "type/navigation/main_navigation.type";

type MainNavItemProps = {
  navImage?: React.ReactNode;
  navElement: NavElement;
};

export default function MainNavItem({
  navElement,
  navImage,
}: MainNavItemProps) {
  return (
    <ListItem key={navElement.title} disablePadding>
      <NavLink to={navElement.path}>
        <ListItemButton>
          {navImage && <ListItemIcon>{navImage}</ListItemIcon>}
          <ListItemText primary={navElement.title} />
        </ListItemButton>
      </NavLink>
    </ListItem>
  );
}
