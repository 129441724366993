import CharacterSearchSelect from "components/common/character/util/CharacterSearchSelect";
import UpdateStringList from "components/common/list/UpdateStringList";
import { ReactRefI } from "type/common/ref.type";

type CharacterRelateGroupEditProps = {
  label: string;
  topicId: string;
  initialValue: string[];
  resultRef: ReactRefI<string[]>;
};

const CharacterRelateGroupEdit = ({
  label,
  topicId,
  initialValue,
  resultRef,
}: CharacterRelateGroupEditProps) => {
  return (
    <UpdateStringList
      label={label}
      initialValue={initialValue}
      resultRef={resultRef}
      appendToStart
      disableSingleEdit
      stringField={(selHandle: Function) => (
        <CharacterSearchSelect onLetterSelect={selHandle} topicId={topicId} />
      )}
    />
  );
};

export default CharacterRelateGroupEdit;
