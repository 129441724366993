import styled from "styled-components";
import CharParseMessage from "./CharParseMessage";
import { CharacterImportSetsResult } from "common/character/dto/character_import.type";
import CharParseImportList from "./CharParseImportList";
import {
  CHARACTER_IMPORT_LIST_LABEL,
  CHARACTER_NOT_IMPORT_LIST_LABEL,
} from "./CharParseResult.const";

type CharParseResultProps = {
  importParseCheckResult: CharacterImportSetsResult;
};

const CharParseResult = ({ importParseCheckResult }: CharParseResultProps) => {
  return (
    <Div>
      <CharParseMessage messages={importParseCheckResult.messages} />
      <CharParseImportList
        label={CHARACTER_IMPORT_LIST_LABEL}
        characters={importParseCheckResult.forImport}
      />
      <CharParseImportList
        label={CHARACTER_NOT_IMPORT_LIST_LABEL}
        characters={importParseCheckResult.notForImport}
      />
    </Div>
  );
};

const Div = styled.div`
  margin-top: 1rem;
`;

export default CharParseResult;
