import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useConfirm from "common/modal/ConfirmDialog";
import { Button } from "@mui/material";

type UpdateStringSingleNavProps = {
  onDeleteHandle?: Function | null;
  onEditHandle?: Function | null;
  onCancelHandle?: Function | null;
};

const UpdateStringSingleNav = ({
  onDeleteHandle,
  onEditHandle,
  onCancelHandle,
}: UpdateStringSingleNavProps) => {
  const actionConfirm = useConfirm();

  const deleteHandle = async () => {
    if (onDeleteHandle) {
      const check = await actionConfirm({
        message: "Are you sure you want to delete item?",
      });
      if (check) {
        onDeleteHandle();
      }
    }
  };

  return (
    <DivNav>
      {onDeleteHandle && <DeleteIcon onClick={deleteHandle} />}
      {onEditHandle && (
        <EditIcon onClick={() => (onEditHandle ? onEditHandle() : null)} />
      )}
      {onCancelHandle && (
        <ButtonCancel
          onClick={() => (onCancelHandle ? onCancelHandle() : null)}
        >
          Cancel
        </ButtonCancel>
      )}
    </DivNav>
  );
};

const DivNav = styled.div`
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  svg {
    margin-left: 1rem;
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
  &&& > svg:first-of-type {
    margin-left: 0;
  }
`;

const ButtonCancel = styled(Button)`
  margin-left: 1rem;
`;

export default UpdateStringSingleNav;
