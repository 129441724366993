import styled from "styled-components";
import SelectRefField from "components/UI/SelectField/SelectRefField";
import {
  CHARACTER_COPY_TYPE,
  CHARACTER_COPY_TYPE_OPTIONS,
} from "constant/character/character_copy.constant";
import { ReactRefI } from "type/common/ref.type";
import { SelectFieldOptionValue } from "components/UI/SelectField/SelectField.type";

type CharacterCopyTypeSelectProps = {
  defaultValue?: string;
  label?: string;
  selectRef?: ReactRefI<SelectFieldOptionValue>;
  onSelect?: Function;
};

const CharacterCopyTypeSelect = ({
  defaultValue,
  label,
  selectRef,
  onSelect,
}: CharacterCopyTypeSelectProps) => {
  return (
    <SelectRefField
      label={label}
      options={CHARACTER_COPY_TYPE_OPTIONS}
      defaultValue={defaultValue ?? CHARACTER_COPY_TYPE.FIRST_NOT_CORRECT.value}
      {...(onSelect ? { onSelect } : {})}
      {...(selectRef ? { selectRef } : {})}
    />
  );
};

export default CharacterCopyTypeSelect;
