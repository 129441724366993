import { useMutation } from "@tanstack/react-query";
import { topicUpdate } from "common/api/asocijator/topic/asocijator_topic";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseTopicUpdateParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
};

export const useTopicUpdate = (hookParams?: UseTopicUpdateParams) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: topicUpdateMutate } = useMutation({
    mutationFn: async (params: any) =>
      await topicUpdate(params.id, params.updatePayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }
    },
  });

  return { topicUpdateMutate };
};
