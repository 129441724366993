import { Control, Controller, FieldValues } from "react-hook-form";
import { ErrorText } from "./common.style";
import TextInputField from "./TextInputField";

type Props = {
  name: string;
  control: Control<FieldValues, any>;
  label: string;
  defaultValue?: string;
  type?: string;
  onEnterHandle?: Function;
  id?: string;
};

const InputField = ({
  name,
  control,
  label,
  defaultValue,
  type,
  id,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, formState: { errors } }) => {
        const errorMessage = errors[name]?.message ?? null;
        return (
          <>
            {errorMessage && typeof errorMessage === "string" && (
              <ErrorText>{errorMessage}</ErrorText>
            )}
            <TextInputField
              onChange={onChange}
              label={label}
              defaultValue={defaultValue}
              error={typeof errors[name]?.message !== "undefined"}
              type={type}
              id={id ?? undefined}
            />
          </>
        );
      }}
    />
  );
};

export default InputField;
