import { PRONOUNCE_CONVERT } from "constant/convert/pronounce_convert.const";

export function pronounceConvert(text: string): string {
  let res: string = text;
  Object.entries(PRONOUNCE_CONVERT).forEach(([key, val]) => {
    if (res.includes(key)) {
      res = res.replaceAll(key, val);
    }
  });

  return res;
}

export function pronounceConvertArr(texts: string[]): string[] {
  const res: string[] = [...texts];
  for (let i = 0; i < res.length; i++) {
    res[i] = pronounceConvert(res[i]);
  }

  return res;
}
