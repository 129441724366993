import useTopicWork from "common/topic/provider/TopicWork";
import TopicUnselectedInfo from "./TopicUnselectedInfo";
import TopicAnswerPlay from "./TopicAnswerPlay";
import { AnswerPlayCharProvider } from "common/answer/provider/AnswerPlayChar";
import { useTopicWorkSetPath } from "common/topic/provider/TopicWork/UseTopicWorkSetPath";

const AnswerPlay = () => {
  const { topicWork } = useTopicWork();
  useTopicWorkSetPath();

  return (
    <>
      {!topicWork && <TopicUnselectedInfo />}
      {topicWork && (
        <AnswerPlayCharProvider>
          <TopicAnswerPlay topicId={topicWork.id} />
        </AnswerPlayCharProvider>
      )}
    </>
  );
};

export default AnswerPlay;
