export function characterKeyPressAct(ev: any, navigate: Function) {
  console.log("characterKeyPressAct");
  if (ev.shiftKey) {
    if (ev.code === "Digit1") {
      // console.log("shift + 1");

      navigate("/character/new");
    }
  }
}
