import styled from "styled-components";
import { CharacterRelateRefs } from "hooks/asocijator/character/useCharacterRelateRefs";
import useTopicWork from "common/topic/provider/TopicWork/TopicWork";
import CharacterRelateGroupEdit from "./CharacterRelateGroupEdit";

type CharacterRelateEditProps = {
  characterRelateRefs: CharacterRelateRefs;
};

const CharacterRelateEdit = ({
  characterRelateRefs,
}: CharacterRelateEditProps) => {
  const { topicWork } = useTopicWork();

  return (
    <Div>
      <DivGroup>
        <CharacterRelateGroupEdit
          label="relate meaning"
          topicId={topicWork?.id as string}
          initialValue={characterRelateRefs.relateMeaningRef.current ?? []}
          resultRef={characterRelateRefs.relateMeaningRef}
        />
      </DivGroup>

      <DivGroup>
        <CharacterRelateGroupEdit
          label="relate pronounce"
          topicId={topicWork?.id as string}
          initialValue={characterRelateRefs.relatePronounceRef.current ?? []}
          resultRef={characterRelateRefs.relatePronounceRef}
        />
      </DivGroup>

      <DivGroup>
        <CharacterRelateGroupEdit
          label="relate shape"
          topicId={topicWork?.id as string}
          initialValue={characterRelateRefs.relateShapeRef.current ?? []}
          resultRef={characterRelateRefs.relateShapeRef}
        />
      </DivGroup>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 0.75rem;
`;

const DivGroup = styled.div`
  margin-top: 1.5rem;
`;

export default CharacterRelateEdit;
