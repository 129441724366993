import { Control, Controller, FieldValues } from "react-hook-form";
import { Select, InputLabel, MenuItem } from "@mui/material";
import styled from "styled-components";
import { SelectFieldOption, SelectFieldOptionValue } from "./SelectField.type";

type Props = {
  name: string;
  control: Control<FieldValues, any>;
  label: string;
  defaultValue?: SelectFieldOptionValue | null;
  options: SelectFieldOption[];
  onSelect?: Function;
};

const SelectField = ({
  name,
  control,
  label,
  defaultValue,
  options,
  onSelect,
}: Props) => {
  const menuItems = options.map((option: SelectFieldOption, index: number) => (
    <MenuItem value={option.value} key={`option_${index}`}>
      {option.label}
    </MenuItem>
  ));
  const labelId = `${name}-label`;

  return (
    <Div>
      <SelectLabel id={labelId}>{label}</SelectLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange } }) => (
          <SelectElement
            labelId={labelId}
            onChange={(arg) => {
              onChange(arg);
              if (onSelect) {
                onSelect(arg.target.value);
              }
            }}
            label={label}
            defaultValue={defaultValue}
          >
            {menuItems}
          </SelectElement>
        )}
      />
    </Div>
  );
};

const SelectLabel = styled(InputLabel)`
  &&& {
    margin-right: 0.75rem;
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
`;

const SelectElement = styled(Select)`
  margin-left: 0rem;
`;

export default SelectField;
