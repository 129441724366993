import React from "react";
import styled from "styled-components";
import CharacterSingleNav from "components/common/character/CharacterSingleNav/CharacterSingleNav";
import { CharacterResult } from "common/character/dto/CharacterResult";

type CharacterReadProps = {
  character: CharacterResult;
};

const CharacterRead = ({ character }: CharacterReadProps) => {
  return (
    <div>
      <div>{character.letter}</div>
      <DivNav>
        <CharacterSingleNav character={character} hideReadNav={true} />
      </DivNav>
    </div>
  );
};

const DivNav = styled.div`
  margin-top: 1.5rem;
`;

export default CharacterRead;
