import React, { useState } from "react";
import { Box, Drawer } from "@mui/material";
import styled from "styled-components";
import MainNavigationSmallScreen from "./MainNavigationSmallScreen";
import MainNavigationDrawer from "./MainNavigationDrawer";
import { MAIN_NAV_DRAWER_WIDTH } from "./MainNavigation.constant";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";

const MainNavigation = () => {
  const [showMenu, setShowMenu] = useState<boolean>(true);

  const closeShowLabel: string = showMenu ? "x" : "menu";

  const closeShowClickHandle = () => {
    const nextShowMenu: boolean = !showMenu;
    setShowMenu(nextShowMenu);
  };

  return (
    <>
      <MainNavigationSmallScreen />
      {showMenu && (
        <BoxNav
          component="nav"
          sx={{ width: { sm: MAIN_NAV_DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: MAIN_NAV_DRAWER_WIDTH,
              },
            }}
            open
          >
            <MainNavigationDrawer showMenu={showMenu ?? false} />
          </Drawer>
        </BoxNav>
      )}
      <DivClose onClick={closeShowClickHandle}>{closeShowLabel}</DivClose>
    </>
  );
};

const BoxNav = styled(Box).attrs({ className: "screen-large" })``;

const DivClose = styled.div`
  display: none;
  position: fixed;
  z-index: 100000;
  margin-left: 0.75rem;
  margin-top: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    &&& {
      display: block;
    }
  }
`;

export default MainNavigation;
