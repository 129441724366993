import { envTypeGet } from "../common";
import { API_TYPES } from "./api_constants";

export type UrlBases = {
  auth: string;
  asocijator: string;
};

export type ApiType = "auth" | "asocijator";

export const apiUrlBasesGet = (): UrlBases => {
  const envType = envTypeGet();
  let urlBases = {
    auth: "http://localhost:3002/api/v1",
    asocijator: "http://localhost:3001/api/v1",
  };
  if (envType === "production") {
    urlBases = {
      auth: "https://asocijator.emlearn.info/api/auth-asocijator/v1",
      asocijator: "https://asocijator.emlearn.info/api/asocijator/v1",
    };
  }

  return urlBases;
};

export const apiTypeUrlBaseGet = (apiType: string): string => {
  const urlBases: UrlBases = apiUrlBasesGet();
  const url = urlBases[apiType as ApiType];
  return url;
};

export const apiTypeUrlGet = (apiType: string, path: string): string => {
  const urlBase: string = apiTypeUrlBaseGet(apiType);
  const url = urlBase + "/" + path;
  return url;
};

export const apiAuthUrlGet = (path: string = ""): string => {
  const url: string = apiTypeUrlGet(API_TYPES.AUTH, path);
  return url;
};

export const apiAsocijatorUrlGet = (path: string = ""): string => {
  const url: string = apiTypeUrlGet(API_TYPES.ASOCIJATOR, path);
  return url;
};
