import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { useForm } from "react-hook-form";
import { IconButton } from "@mui/material";
import { topicSearchFieldsGet } from "common/topic/topic_search";
import SearchText from "components/common/search_sort/SearchText";
import SearchSort from "components/common/search_sort/SearchSort";
import { SEARCH_NAMES } from "constant/search/search";

type TopicSearchFiltersProps = {
  searchHandle: Function;
};

const defaultVals: any = topicSearchFieldsGet();

const TopicSearchFilters = ({ searchHandle }: TopicSearchFiltersProps) => {
  const { handleSubmit, control } = useForm({
    defaultValues: defaultVals,
  });

  const onSubmit = async (data: any) => {
    console.log(data);
    searchHandle(data);
  };

  return (
    <div>
      <DivSearchOrder>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <SearchText
            fieldName={SEARCH_NAMES.SEARCH_TEXT}
            defaultValue={defaultVals[SEARCH_NAMES.SEARCH_TEXT]}
            label="Tekst pretrazivanja"
            control={control}
          />
          <SearchSort
            control={control}
            sortByName={SEARCH_NAMES.SORT_BY}
            sortOrderName={SEARCH_NAMES.SORT_ORDER}
            sortByDefaultValue={""}
            sortOrderDefaultValue={""}
          />
          <DivSubmit>
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          </DivSubmit>
        </Form>
      </DivSearchOrder>
    </div>
  );
};

const DivSearchOrder = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;

  svg {
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
`;

const Form = styled.form`
  &&& {
    display: flex;
  }
`;

const DivSubmit = styled.div`
  margin-left: 1.5rem;
  display: flex;
`;

export default TopicSearchFilters;
