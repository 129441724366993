import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { QUERY_KEYS } from "constant/react_query";
import UserForm from "components/common/user/UserForm/UserForm";
import { UserResult } from "common/user/dto/UserResult";
import UserSingleNav from "components/common/user/UserSingleNav";
import { useUserUpdate } from "hooks/asocijator/user/useUserUpdate";
import { userFormDefaultValuesGet } from "common/user/user_form_common";
import UserPasswordUpdate from "components/common/user/UserPasswordUpdate";

type UserEditProps = {
  user: UserResult;
  hideSingleNav?: boolean;
};

const UserEdit = ({ user, hideSingleNav }: UserEditProps) => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: userFormDefaultValuesGet(user),
  });
  const { userUpdateMutate } = useUserUpdate({
    invalidateKeysArray: [
      [QUERY_KEYS.ASOCIJATOR_USER_SEARCH],
      [QUERY_KEYS.ASOCIJATOR_USER_GET],
    ],
  });

  const onSubmit = async (data: any) => {
    console.log(data);

    const { un, pw, uType, email, firstName, lastName } = data;
    userUpdateMutate({
      id: user.id,
      updatePayload: {
        un,
        pw,
        uType,
        email,
        firstName,
        lastName,
      },
    });
    toast.success("User fields updated");
  };

  return (
    <DivUser>
      <UserForm
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        reset={reset}
        defaultValues={userFormDefaultValuesGet(user)}
      />
      <UserPasswordUpdate uId={user.id as string} />

      {!hideSingleNav && (
        <DivNav>
          <UserSingleNav user={user} hideEditNav={true} />
        </DivNav>
      )}
    </DivUser>
  );
};

const DivUser = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivNav = styled.div`
  margin-top: 1rem;
`;

export default UserEdit;
