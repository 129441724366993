import { useMutation } from "@tanstack/react-query";
import {
  TopicAddParams,
  topicAdd,
} from "common/api/asocijator/topic/asocijator_topic";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseTopicAddParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useTopicAdd = (hookParams?: UseTopicAddParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: topicAddMutate } = useMutation({
    mutationFn: async (topicAddParams: TopicAddParams) =>
      await topicAdd(topicAddParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { topicAddMutate };
};
