import CharacterEdit from "components/pages/character/Character/CharacterEdit";
import { useCharacterGet } from "hooks/asocijator/character/useCharacterGet";

type PlayCharEditProps = {
  characterId: string;
  navClose: Function;
};

const PlayCharEdit = ({ characterId, navClose }: PlayCharEditProps) => {
  const character = useCharacterGet(characterId as string);

  return (
    <>
      {character && <CharacterEdit character={character} onUpdate={navClose} />}
    </>
  );
};

export default PlayCharEdit;
