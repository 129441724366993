import { Select, InputLabel, MenuItem } from "@mui/material";
import styled from "styled-components";
import { SelectFieldOption, SelectFieldOptionValue } from "./SelectField.type";
import { ReactRefI } from "type/common/ref.type";

type Props = {
  label?: string;
  defaultValue?: SelectFieldOptionValue | null;
  options: SelectFieldOption[];
  onSelect?: Function;
  selectRef?: ReactRefI<SelectFieldOptionValue>;
};

const SelectField = ({
  label,
  defaultValue,
  options,
  onSelect,
  selectRef,
}: Props) => {
  const menuItems = options.map((option: SelectFieldOption, index: number) => (
    <MenuItem value={option.value} key={`option_${index}`}>
      {option.label}
    </MenuItem>
  ));

  return (
    <Div>
      {label && <SelectLabel>{label}</SelectLabel>}
      <SelectElement
        onChange={(arg) => {
          const val: SelectFieldOptionValue = arg.target.value as any;
          if (onSelect) {
            onSelect(val);
          }
          if (selectRef) {
            selectRef.current = val;
          }
        }}
        label={label ?? ""}
        defaultValue={defaultValue}
      >
        {menuItems}
      </SelectElement>
    </Div>
  );
};

const SelectLabel = styled(InputLabel)`
  &&& {
    margin-right: 0.75rem;
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
`;

const SelectElement = styled(Select)`
  margin-left: 0rem;
`;

export default SelectField;
