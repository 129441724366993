import { AddNavOptions } from "common/modal/AddNavigation";
import PlayCharEdit from "./PlayCharEdit";

export const charNavOptionsGet = (
  characterId: string,
  navClose: Function
): AddNavOptions => {
  return {
    title: "Char edit",
    navContent: <PlayCharEdit characterId={characterId} navClose={navClose} />,
  };
};
