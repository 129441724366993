import SelectRefField from "components/UI/SelectField/SelectRefField";
import {
  CHARACTER_COPY_DESTINATION_TYPE,
  CHARACTER_COPY_DESTINATION_TYPE_OPTIONS,
} from "constant/character/character_copy.constant";
import { ReactRefI } from "type/common/ref.type";
import { SelectFieldOptionValue } from "components/UI/SelectField/SelectField.type";

type CharacterCopyDestinationTypeSelectProps = {
  defaultValue?: string;
  label?: string;
  selectRef?: ReactRefI<SelectFieldOptionValue>;
  onSelect?: Function;
};

const CharacterCopyDestinationTypeSelect = ({
  defaultValue,
  label,
  selectRef,
  onSelect,
}: CharacterCopyDestinationTypeSelectProps) => {
  return (
    <SelectRefField
      label={label}
      options={CHARACTER_COPY_DESTINATION_TYPE_OPTIONS}
      defaultValue={
        defaultValue ?? CHARACTER_COPY_DESTINATION_TYPE.TOPIC_NEW.value
      }
      {...(onSelect ? { onSelect } : {})}
      {...(selectRef ? { selectRef } : {})}
    />
  );
};

export default CharacterCopyDestinationTypeSelect;
