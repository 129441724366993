import React from "react";
import styled from "styled-components";
import { StringListType } from "./StringList.type";
import { resultsTotal, showLabel, stringListTypeGet } from "./StringList.logic";
import { STRING_LIST_TYPE } from "./StringList.const";
import StringListItem from "./StringListItem";

export type StringListProps = {
  results: string[];
  listType?: StringListType;
  delimiter?: string;
  label?: string;
  showLabelForEmptyList?: boolean;
};

function StringList({
  results,
  listType,
  delimiter,
  label,
  showLabelForEmptyList,
}: StringListProps) {
  const stringListType: StringListType = stringListTypeGet(listType);

  return (
    <>
      {showLabel(resultsTotal(results), label, showLabelForEmptyList) && (
        <DivLabel>{label}</DivLabel>
      )}
      {resultsTotal(results) > 0 && (
        <DivList listType={stringListType}>
          {results.map((result: string, ind: number) => (
            <StringListItem
              result={result}
              delimiter={delimiter}
              ind={ind}
              key={`string_list_item_${ind}`}
            />
          ))}
        </DivList>
      )}
    </>
  );
}

const DivList = styled.div<{ listType: StringListType }>`
  display: flex;
  flex-direction: ${(props) =>
    props.listType === STRING_LIST_TYPE.SAME_LINE ? "row" : "column"};
  div {
    margin-left: ${(props) =>
      props.listType === STRING_LIST_TYPE.SAME_LINE ? "1rem" : "0rem"};
  }
`;

const DivLabel = styled.div`
  margin-bottom: 0.5rem;
`;

export default StringList;
