import { CharacterFile } from "common/character/dto/CharacterFile";

export function uploadCharacterFileListGet(
  ev: any,
  fileList: CharacterFile[]
): CharacterFile[] {
  console.log("fileUploadHandle", ev);
  const fileListNext: CharacterFile[] = [...fileList];
  ev.files.forEach((file: any) => {
    const characterFile: CharacterFile = {
      path: file.path,
    };
    fileListNext.push(characterFile);
  });

  return fileListNext;
}

export function removeCharacterFileListGet(
  fileRemoveList: CharacterFile[],
  characterFile: CharacterFile
): CharacterFile[] {
  const fileRemoveListNext: CharacterFile[] = [
    ...fileRemoveList,
    characterFile,
  ];

  return fileRemoveListNext;
}
