import React from "react";
import { Divider, List } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import MainNavItem from "./MainNavItem";
import { NavElement } from "type/navigation/main_navigation.type";

export type NavigationGroupProps = {
  navElements: Array<any>;
  dividerAdd?: boolean;
};

const NavigationGroup = ({ navElements, dividerAdd }: NavigationGroupProps) => (
  <>
    <List>
      {navElements.map((navElement: NavElement, ind: number) => (
        <MainNavItem
          navElement={navElement}
          navImage={<ArticleIcon />}
          key={`main_nav_item_${ind}`}
        />
      ))}
    </List>
    {dividerAdd && <Divider />}
  </>
);

export default NavigationGroup;
