import InputField from "components/UI/InputField";
import {
  EventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ReactRefI } from "type/common/ref.type";
import { CHAR_CHECK_KEYS } from "./PronounceInput.const";
import { Tooltip } from "@mui/material";
import React from "react";
import { charFromGroupNext } from "./PronounceInput.logic";
import { FieldValues, UseFormRegister, UseFormSetValue } from "react-hook-form";

export type PronounceInputProps = {
  textRef?: ReactRefI<string>;
  defaultValue?: string;
  name?: string;
  setValue?: UseFormSetValue<any>;
  placeholder?: string;
  autoFocus?: boolean;
};

const PronounceInput = ({
  textRef,
  defaultValue,
  name,
  setValue,
  placeholder,
  autoFocus,
}: PronounceInputProps) => {
  const [textValue, setTextValue] = useState<string>(defaultValue ?? "");
  const [charCheck, setCharCheck] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>("");
  const [tooltipTextBaseChar, setTooltipTextBaseChar] = useState<string>("");
  const tooltipTextInterval = useRef<any>(null);

  useEffect(() => {
    if (tooltipText !== "") {
      if (tooltipTextInterval.current !== null) {
        clearInterval(tooltipTextInterval as unknown as number);
      }
      tooltipTextInterval.current = setTimeout(() => {
        const tooltipNext: string = charFromGroupNext(
          tooltipTextBaseChar,
          tooltipText
        );
        // console.log("interval", tooltipNext);
        if (tooltipNext !== "") {
          setTooltipText(tooltipNext);
        }
      }, 1 * 750);
    }
  }, [tooltipText]);

  useEffect(() => {
    if (textRef) {
      textRef.current = textValue;
    }
    if (name && setValue) {
      setValue(name, textValue);
    }
  }, [textValue]);

  const keyDownHandle: KeyboardEventHandler<HTMLInputElement> = (ev: any) => {
    const charValue: string = ev.key;
    if (!charCheck && CHAR_CHECK_KEYS.includes(charValue) && !ev.ctrlKey) {
      setCharCheck(true);
      setTooltipText(charValue);
      setTooltipTextBaseChar(charValue);
    }
  };

  const keyUpHandle: KeyboardEventHandler<HTMLInputElement> = (ev: any) => {
    const charValue: string = ev.key;
    if (CHAR_CHECK_KEYS.includes(charValue)) {
      // console.log("keyUpHandle", charValue);
      setCharCheck(false);
      if (!ev.ctrlKey) {
        setTextValue((currentText: string) => currentText + tooltipText);
      }
      if (tooltipTextInterval.current !== null) {
        clearInterval(tooltipTextInterval.current);
        tooltipTextInterval.current = null;
      }
      if (tooltipText !== "") {
        setTooltipText("");
      }
      if (tooltipTextBaseChar !== "") {
        setTooltipTextBaseChar("");
      }
    }
  };

  const onChangeHandle: EventHandler<any> = (ev: any) => {
    // console.log("onChangeHandle", ev.target.value);
    if (!charCheck) {
      setTextValue(ev.target.value);
    }
  };

  return (
    <Div>
      <Tooltip
        open={tooltipText !== ""}
        title={<CharTooltip>{tooltipText}</CharTooltip>}
        placement="left"
      >
        <input
          type="text"
          onKeyDown={keyDownHandle}
          onKeyUp={keyUpHandle}
          onChange={onChangeHandle}
          value={textValue}
          autoFocus={autoFocus ?? false}
          placeholder={placeholder ?? ""}
        />
      </Tooltip>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 0rem;
  input {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
`;

const CharTooltip = styled.div`
  font-size: 1.5rem;
`;

export default PronounceInput;
