import { useMutation } from "@tanstack/react-query";
import {
  TopicAnswersResetParams,
  topicAnswersReset,
} from "common/api/asocijator/asocijator_answer";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type useTopicAnswersResetParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useTopicAnswersReset = (
  hookParams?: useTopicAnswersResetParams
) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: topicAnswersResetMutate } = useMutation({
    mutationFn: async (topicAnswersResetParams: TopicAnswersResetParams) =>
      await topicAnswersReset(topicAnswersResetParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { topicAnswersResetMutate };
};
