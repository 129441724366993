export type PosDimResult = {
  top: number;
  left: number;
  height: number;
  width: number;
};

export type WindowDimResult = {
  height: number;
  width: number;
};

export function elIdPosDimGet(elId: string): PosDimResult | null {
  let result: any = null;
  const el = document.getElementById(elId);
  if (el !== null) {
    var viewportOffset = el.getBoundingClientRect();
    // console.log("viewportOffset", viewportOffset);
    const top = viewportOffset.top;
    const left = viewportOffset.left;
    const height = viewportOffset.height;
    const width = viewportOffset.width;
    result = { top, left, height, width };
  }

  return result;
}

export function windowDimGet(): WindowDimResult {
  let result: WindowDimResult = { height: 0, width: 0 };
  result.height = window.innerHeight;
  result.width = window.innerWidth;

  return result;
}
