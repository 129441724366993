import React, { useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useCharacterAdd } from "hooks/asocijator/character/useCharacterAdd";
import { QUERY_KEYS } from "constant/react_query";
import CharacterForm from "components/common/character/CharacterForm/CharacterForm";
import { useNavigate } from "react-router-dom";
import { characterFormDefaultValuesGet } from "common/character/character_form_common";
import useTopicWork from "common/topic/provider/TopicWork/TopicWork";
import { CharacterRelate } from "type/common/character/character.type";
import { characterRelateDefaultGet } from "common/character/character_common";
import { ReactRefI } from "type/common/ref.type";
import {
  CharacterRelateRefs,
  useCharacterRelateRefs,
} from "hooks/asocijator/character/useCharacterRelateRefs";
import { pronounceConvert } from "common/util/convert/pronounce_convert";

const CharacterNew = () => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: characterFormDefaultValuesGet(),
  });
  const navigate = useNavigate();
  const { characterAddMutate } = useCharacterAdd({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_CHARACTER_SEARCH],
    successHandle: (id: any) => {
      if (id !== "") {
        console.log("add success", id);
        toast.success("New character added");
        navigate(`/character/${id}?mode=edit`);
      } else {
        toast.error(
          "Unable to add new character. It might be that character already exists in this topic."
        );
      }
    },
  });
  const { topicWork } = useTopicWork();
  const pronounceAddRef: ReactRefI<string[]> = useRef<string[]>([]);
  const examplesRef: ReactRefI<string[]> = useRef<string[]>([]);
  const fileEditRef: ReactRefI<string[]> = useRef<string[]>([]);
  const fileRemoveRef: ReactRefI<string[]> = useRef<string[]>([]);
  const characterRelateRefs: CharacterRelateRefs = useCharacterRelateRefs();

  const onSubmit = async (data: any) => {
    const { letter, meaning, pronounce } = data;
    const topicId: string = topicWork?.id as string;
    const relate: CharacterRelate = characterRelateDefaultGet();

    if (topicId !== "") {
      characterAddMutate({
        letter,
        meaning,
        pronounce: pronounceConvert(pronounce),
        topicId,
        relate,
      });
    } else {
      toast.error("Unable to add new character. Topic not selected.");
    }
  };

  return (
    <>
      <div>Character Add</div>
      <DivCharacter>
        <CharacterForm
          control={control}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          reset={reset}
          pronounceAddRef={pronounceAddRef}
          examplesRef={examplesRef}
          fileEditRef={fileEditRef}
          fileRemoveRef={fileRemoveRef}
          characterRelateRefs={characterRelateRefs}
        />
      </DivCharacter>
    </>
  );
};

const DivCharacter = styled.div`
  display: flex;
  flex-direction: column;
`;

export default CharacterNew;
