import { AxiosInstance } from "axios";
import { apiTypeUrlBaseGet } from "../api_common";
import { API_APP_NAMES, API_TYPES } from "../api_constants";
import { authorizationObDefault, axiosInstanceCreate } from "./axios_common";
import { axiosInstance401Handle } from "./axios_intercepter_common";

const asocijatorUrlBase: string = apiTypeUrlBaseGet(API_TYPES.ASOCIJATOR);

export const asocijatorAuth = authorizationObDefault();

const API_ASOCIJATOR_RETRY_LIMIT: number = 3;

export const axiosAsocijator: AxiosInstance =
  axiosInstanceCreate(asocijatorUrlBase);

axiosAsocijator.interceptors.response.use(null, async (err) => {
  if (typeof err.config.retryLimit === "undefined") {
    err.config.retryLimit = API_ASOCIJATOR_RETRY_LIMIT;
  }
  if (err.config.retryLimit === 0) {
    throw err;
  }
  err.config.retryLimit--;
  if (err.response.status === 401) {
    try {
      await axiosInstance401Handle(
        axiosAsocijator,
        API_APP_NAMES.ASOCIJATOR,
        asocijatorAuth
      );
    } catch (err) {
      throw err;
    }
  }
  const response = await axiosAsocijator(err.config);
  return response;
});
