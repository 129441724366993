export const defaultValueGet = (
  defaultValues: any,
  name: string,
  predefinedDefaultValue: any
) => {
  const valueDefault =
    defaultValues && defaultValues[name]
      ? defaultValues[name]
      : predefinedDefaultValue;

  return valueDefault;
};
