import styled from "styled-components";
import { TopicResult } from "common/topic/dto/TopicResult";
import { Button } from "@mui/material";
import useTopicWork from "common/topic/provider/TopicWork/TopicWork";
import { useNavigate } from "react-router-dom";

type TopicChooseNavProps = {
  topic: TopicResult;
  navigateTo?: string;
};

const TopicChooseNav = ({ topic, navigateTo }: TopicChooseNavProps) => {
  const { topicWork, topicWorkSet } = useTopicWork();
  const navigate = useNavigate();

  const chooseHandle = () => {
    if (topicWork === null || topic.id !== topicWork.id) {
      topicWorkSet(topic);
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };

  return (
    <ChooseButton variant="outlined" onClick={chooseHandle}>
      CHOOSE
    </ChooseButton>
  );
};

const ChooseButton = styled(Button)`
  margin-left: 1rem;
`;

export default TopicChooseNav;
