import { NAV_ELEMENTS } from "constant/navigation";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const TopicUnselectedInfo = () => {
  return (
    <div>
      Topic not selected. Please
      <NavLinkAnswerPlay to={NAV_ELEMENTS.TOPIC_LIST.path}>
        choose
      </NavLinkAnswerPlay>
      one.
    </div>
  );
};

const NavLinkAnswerPlay = styled(NavLink)`
  margin-left: 0.3rem;
  margin-right: 0.3rem;
`;

export default TopicUnselectedInfo;
