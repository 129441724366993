import { useMutation } from "@tanstack/react-query";
import { UserAddParams, userAdd } from "common/api/asocijator/asocijator_user";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseUserAddParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useUserAdd = (hookParams?: UseUserAddParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: userAddMutate } = useMutation({
    mutationFn: async (userAddParams: UserAddParams) =>
      await userAdd(userAddParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { userAddMutate };
};
