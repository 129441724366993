import useTopicWork from "./TopicWork";
import { useTopicGet } from "hooks/asocijator/topic/useTopicGet";
import { useEffect, useState } from "react";

export const useTopicWorkSetById = () => {
  const { topicWork, topicWorkSet } = useTopicWork();
  const [topicId, setTopicId] = useState<string>("");
  const topic = useTopicGet(topicId);
  useEffect(() => {
    if (topicId && topic && topic?.id !== topicWork?.id) {
      topicWorkSet(topic);
    }
  }, [topicWork, topic]);

  const topicWorkSetById = (id: string) => {
    if (id && id !== "") {
      setTopicId(id);
    }
  };

  return { topicWorkSetById };
};
