import useTopicWork from "./TopicWork";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTopicWorkSetById } from "./UseTopicWorkSetById";

export const useTopicWorkSetPath = () => {
  const { topicWork } = useTopicWork();
  const { topicId } = useParams();
  const { topicWorkSetById } = useTopicWorkSetById();

  useEffect(() => {
    if (topicId && topicId !== "" && topicWork?.id !== topicId) {
      topicWorkSetById(topicId);
      // window.location.reload();
    }
  }, [topicId]);
};
