export const EXPORT_EXCEL_HEADER: string[] = [
  "letter",
  "pronounce",
  "meaning",
  "pronounceAdd",
  "examples",
  "relate meaning",
  "relate pronounce",
  "relate shape",
];

export const EXPORT_EXAMPLE_HEADER: string[] = ["EXAMPLE"];

export const EXPORT_EXCEL_FILE_TYPE: string =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export const EXPORT_EXCEL_FILE_NAME: string = "character_export.xlsx";

export enum CharExportFieldsTypes {
  all = "all",
  examples = "examples",
}
