import { ObKeyValString } from "type/common/common.type";

export const ANSWER_PLAY_KEY_DOWN_ACTION: ObKeyValString = {
  NONE: "",
  NEXT: "next",
  ANSWER_SHOW: "answer_show",
  ANSWER_HIDE: "answer_hide",
  ANSWER_YES: "answer_yes",
};

export const ANSWER_PLAY_KEY_DOWN_ACTIONS: string[] = Object.values(
  ANSWER_PLAY_KEY_DOWN_ACTION
);
